import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { observer } from 'mobx-react';
import { IEditorTrackLists, MultiSelectStore, PlaylistSummary } from '../../../Util/PlaylistUtils';
import { TrackEntity } from '../../../Models/Entities';
import TrackTile from './TrackTile';

interface IEditorTrackListProps {
	isExpanded: boolean;
	playlist: PlaylistSummary;
	multiSelect: (event: React.MouseEvent<HTMLInputElement>, index: number, start: boolean, track: string, trackList: string[], collectionId: string) => void;
	multiSelectStore: MultiSelectStore
	editorTrackLists: {editor: IEditorTrackLists};
}

const EditorTrackList = observer((props: IEditorTrackListProps) => {
	const {
		isExpanded, playlist, multiSelect, multiSelectStore, editorTrackLists,
	} = props;

	const getClassName = (track: TrackEntity) => {
		if (multiSelectStore.selected.includes(track.id)) {
			return ' selected';
		}
		return '';
	};

	if (editorTrackLists.editor[playlist.id]?.length) {
		return (
			<>
				{
					editorTrackLists.editor[playlist.id].map((t: TrackEntity, index: number) => (
						<Draggable
							draggableId={`editor-${t.id}${isExpanded ? `-playlist-${playlist.id}` : ''}`}
							index={index}
							key={t.id}
							isDragDisabled={!multiSelectStore.selected.includes(t.id)}
						>
							{provided => (
								<div
									className={`result-item track${getClassName(t)}`}
									ref={provided.innerRef}
									{...provided.draggableProps}
									{...provided.dragHandleProps}
									onClick={(e: React.MouseEvent<HTMLInputElement>) => {
										e.preventDefault();
										const isStart = (e.ctrlKey || e.metaKey) || !e.shiftKey;
										multiSelect(e, index, isStart, t.id, editorTrackLists.editor[playlist.id].map(t => t.id) || [], playlist.id);
									}}
								>
									<TrackTile track={t} />
								</div>
							)}
						</Draggable>
					))
				}
			</>
		);
	}

	return <></>;
});

export default EditorTrackList;
