/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { SERVER_URL } from 'Constants';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AgencyPlaylisterArtistEntity from 'Models/Security/Acl/AgencyPlaylisterArtistEntity';
import MustardAdminArtistEntity from 'Models/Security/Acl/MustardAdminArtistEntity';
import VisitorsArtistEntity from 'Models/Security/Acl/VisitorsArtistEntity';
import AgencyAdminArtistEntity from 'Models/Security/Acl/AgencyAdminArtistEntity';
import ApplicationUserArtistEntity from 'Models/Security/Acl/ApplicationUserArtistEntity';
import MustardManagementArtistEntity from 'Models/Security/Acl/MustardManagementArtistEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IArtistEntityAttributes extends IModelAttributes {
	name: string;
	displayPictureId: string;
	displayPicture: Blob;

	albumss: Array<
		| Models.ArtistsAlbums
		| Models.IArtistsAlbumsAttributes
	>;
	trackss: Array<
		| Models.ArtistsTracks
		| Models.IArtistsTracksAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ArtistEntity', 'Artist')
// % protected region % [Customise your entity metadata here] end
export default class ArtistEntity extends Model
	implements IArtistEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AgencyPlaylisterArtistEntity(),
		new MustardAdminArtistEntity(),
		new VisitorsArtistEntity(),
		new AgencyAdminArtistEntity(),
		new ApplicationUserArtistEntity(),
		new MustardManagementArtistEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@observable
	@attribute<ArtistEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Display Picture'] off begin
	@observable
	@attribute<ArtistEntity, string>({ file: 'displayPicture' })
	@CRUD({
		name: 'Display Picture',
		displayType: 'file',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'displayPicture',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Artist" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public displayPictureId: string;

	@observable
	public displayPicture: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Display Picture'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Albums'] off begin
		name: 'Albums',
		displayType: 'reference-multicombobox',
		order: 30,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.ArtistsAlbums,
		optionEqualFunc: makeJoinEqualsFunc('albumsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'artistEntity',
			oppositeEntityName: 'albumEntity',
			relationName: 'artists',
			relationOppositeName: 'albums',
			entity: () => Models.ArtistEntity,
			joinEntity: () => Models.ArtistsAlbums,
			oppositeEntity: () => Models.AlbumEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Albums'] end
	})
	public albumss: Models.ArtistsAlbums[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Tracks'] off begin
		name: 'Tracks',
		displayType: 'reference-multicombobox',
		order: 40,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.ArtistsTracks,
		optionEqualFunc: makeJoinEqualsFunc('tracksId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'artistEntity',
			oppositeEntityName: 'trackEntity',
			relationName: 'artists',
			relationOppositeName: 'tracks',
			entity: () => Models.ArtistEntity,
			joinEntity: () => Models.ArtistsTracks,
			oppositeEntity: () => Models.TrackEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Tracks'] end
	})
	public trackss: Models.ArtistsTracks[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IArtistEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IArtistEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.displayPicture !== undefined) {
				this.displayPicture = attributes.displayPicture;
			}
			if (attributes.displayPictureId !== undefined) {
				this.displayPictureId = attributes.displayPictureId;
			}
			if (attributes.albumss !== undefined && Array.isArray(attributes.albumss)) {
				for (const model of attributes.albumss) {
					if (model instanceof Models.ArtistsAlbums) {
						this.albumss.push(model);
					} else {
						this.albumss.push(new Models.ArtistsAlbums(model));
					}
				}
			}
			if (attributes.trackss !== undefined && Array.isArray(attributes.trackss)) {
				for (const model of attributes.trackss) {
					if (model instanceof Models.ArtistsTracks) {
						this.trackss.push(model);
					} else {
						this.trackss.push(new Models.ArtistsTracks(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		albumss {
			${Models.ArtistsAlbums.getAttributes().join('\n')}
			albums {
				${Models.AlbumEntity.getAttributes().join('\n')}
				${Models.AlbumEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
		trackss {
			${Models.ArtistsTracks.getAttributes().join('\n')}
			tracks {
				${Models.TrackEntity.getAttributes().join('\n')}
				${Models.TrackEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			albumss: {},
			trackss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'albumss',
							'trackss',
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ArtistEntity.prototype, 'created');
CRUD(modifiedAttr)(ArtistEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
