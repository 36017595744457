// @ts-nocheck
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, Fragment } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { action, runInAction, toJS } from 'mobx';
import { useQueryClient } from 'react-query';
import * as _ from 'lodash';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import {
	MoodEntity, ProfileEntity, ScheduleEntity, TimeslotEntity,
} from '../../../../Models/Entities';
import { Combobox } from '../../../Components/Combobox/Combobox';
import {
	Button, Colors, Display, Sizes,
} from '../../../Components/Button/Button';
import { store } from '../../../../Models/Store';
import CreateScheduleModal from '../Schedules/CreateSchdeuleModal';
import { useLeavePageConfirmation } from '../../../../Util/LeavePageConfirmation';
import DeleteScheduleModal from '../Schedules/DeleteScheduleModal';
import alertToast from '../../../../Util/ToastifyUtils';
import MoodTile from '../Moods/MoodTile';

interface IScheduleTabProps {
	profile: ProfileEntity;
	scheduleId?: string;
}

interface IDefaultScheduleState {
	scheduleCopy?: ScheduleEntity | null;
	activeSchedule?: ScheduleEntity | null;
	schedule: MoodEntity[][];
	injectedSchedule: MoodEntity[][];
	scheduleId?: string | null;
	activeMood?: MoodEntity | null;
	day: number;
	start: number;
	end: number;
	selectedDay: number;
	selectedTime: number;
	scheduleOptions: { display: string; value: string }[];
}

const defaultScheduleState = {
	scheduleCopy: null,
	activeSchedule: null,
	schedule: new Array(7).fill(new Array(48).fill(null)),
	injectedSchedule: new Array(7).fill(new Array(48).fill(null)),
	scheduleId: null,
	activeMood: null,
	day: -1,
	start: -1,
	end: -1,
	selectedDay: -1,
	selectedTime: -1,
	scheduleOptions: [],
} as IDefaultScheduleState;

const silence = new MoodEntity({ name: 'Silence' });

const ScheduleTab = (props: IScheduleTabProps): JSX.Element => {
	const { profile, scheduleId } = props;

	const queryClient = useQueryClient();

	useLeavePageConfirmation(
		store.editingSchedule,
		'Unsaved Changes Warning',
		<div>You have unsaved changes. Are you sure you want to leave?</div>,
		{
			onConfirmCustom: () => { resetSchedule(); },
		},
	);

	// helper to convert a date to timeIndex
	const convertTimeToIndex = (timeSlot: TimeslotEntity, start: boolean) => {
		return start
			? timeSlot.start.getHours() * 2 + timeSlot.start.getMinutes() / 30
			: timeSlot.end.getHours() * 2 + timeSlot.end.getMinutes() / 30;
	};
	// helper to convert a timeIndex to date
	const convertIndexToTime = (dayIndex: number, timeIndex: number) => {
		return new Date(0, 0, dayIndex + 1, 0, timeIndex * 30, 0);
	};

	const scheduleState = useLocalStore(() => (defaultScheduleState));

	const dayMap = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

	const timeMap = [
		'12am',
		'1am',
		'2am',
		'3am',
		'4am',
		'5am',
		'6am',
		'7am',
		'8am',
		'9am',
		'10am',
		'11am',
		'12pm',
		'1pm',
		'2pm',
		'3pm',
		'4pm',
		'5pm',
		'6pm',
		'7pm',
		'8pm',
		'9pm',
		'10pm',
		'11pm',
	];

	// Create a new ScheduleEntity and refetch "profile"
	const addSchedule = action((schedule: ScheduleEntity) => {
		schedule.profileId = profile.id;
		schedule.agencyOwnerId = profile.agencyOwnerId;
		scheduleState.scheduleOptions.push({ display: schedule.name, value: schedule.id });
		scheduleState.scheduleId = schedule.id;
		scheduleState.activeSchedule = schedule;
		scheduleState.scheduleCopy = _.cloneDeep(schedule);
		scheduleState.activeSchedule.saveWithId(
			{ timeslotss: {} },
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: 'timeslotss',
					},
				],
			},
		).then(() => {
			alertToast('Saved schedule.', 'success');
			runInAction(() => { store.editingSchedule = false; });
			queryClient.refetchQueries(['profile', profile.id]);
		}).catch(() => {
			alertToast('Error saving schedule.', 'error');
		});
	});

	// set the scheduleOptions to the scheduless option within profile
	const setScheduleOptions = action(() => {
		if (profile.scheduless !== undefined) {
			scheduleState.scheduleOptions = profile.scheduless.map((schedule: ScheduleEntity) => {
				return { display: schedule.name, value: schedule.id };
			});
		}
	});

	// update state upon profile.id change
	useEffect(() => {
		// use the given scheduleId from the url else default as the first schedule in the profile
		if (scheduleId !== undefined && profile.scheduless?.some(s => s.id === scheduleId)) {
			runInAction(() => {
				[scheduleState.activeSchedule] = profile.scheduless.filter(s => s.id === scheduleId);
				scheduleState.scheduleCopy = _.cloneDeep(profile.scheduless.filter(s => s.id === scheduleId)[0]);
				scheduleState.scheduleId = scheduleId;
			});
		} else {
			const firstScheduleOption = profile.scheduless.length > 0 && profile.scheduless[0];
			if (!firstScheduleOption) {
				return;
			}
			runInAction(() => {
				scheduleState.scheduleId = firstScheduleOption.id;
				scheduleState.activeSchedule = firstScheduleOption;
				scheduleState.scheduleCopy = firstScheduleOption;
			});
		}

		setScheduleOptions();
	}, [profile.id]);

	// loop through the activeSchedule.timeslotss and insert the mood and injectableMood to the schedule
	const setScheduleState = () => {
		if (scheduleState.activeSchedule) {
			if (scheduleState.activeSchedule?.timeslotss.length > 0) {
				runInAction(() => {
					runInAction(() => {
						scheduleState.schedule = new Array(7).fill(new Array(48).fill(null));
						scheduleState.injectedSchedule = new Array(7).fill(new Array(48).fill(null));
					});

					scheduleState.activeSchedule?.timeslotss.forEach(timeSlot => {
						const timeSlotDayIndex = timeSlot.start.getDay() === 0 ? 6 : timeSlot.start.getDay() - 1;
						const timeSlotStartIndex = convertTimeToIndex(timeSlot, true);
						const timeSlotEndIndex = convertTimeToIndex(timeSlot, false);

						for (let i = timeSlotStartIndex; i <= timeSlotEndIndex; i++) {
							if (timeSlot.mood.injectable) {
								scheduleState.injectedSchedule[timeSlotDayIndex][i] = timeSlot.mood;
							} else {
								scheduleState.schedule[timeSlotDayIndex][i] = timeSlot.mood;
							}
						}
					});

					scheduleState.schedule.forEach((day: MoodEntity[], dayIndex: number) => {
						day.forEach((timeSlot: MoodEntity, timeIndex: number) => {
							if (!timeSlot) {
								scheduleState.schedule[dayIndex][timeIndex] = silence;
							}
						});
					});
				});
			} else {
				runInAction(() => {
					scheduleState.schedule = new Array(7).fill(new Array(48).fill(silence));
				});
			}
		}
	};

	// update the schedule upon activeSchedule change
	useEffect(() => {
		setScheduleState();
	}, [scheduleState.activeSchedule]);

	if (profile.scheduless === undefined || profile.moodss === undefined) {
		return <>Loading...</>;
	}

	// making sure each timeslot has a mood entity
	const validateSchedule = () => {
		let valid = true;
		scheduleState.schedule.forEach(day => {
			day.forEach(timeSlot => {
				if (!timeSlot) {
					valid = false;
				}
			});
		});

		return valid;
	};

	const addTimeslot = action((
		timeSlotModel: {
			currentMood: MoodEntity | null,
			start: number,
			end: number,
			timeSlot: MoodEntity,
			timeIndex: number,
			dayIndex: number,
		},
	) => {
		if (timeSlotModel.timeIndex === 0) {
			timeSlotModel.currentMood = timeSlotModel.timeSlot;
			timeSlotModel.start = timeSlotModel.timeIndex;
		} else if (timeSlotModel.timeIndex === 47 || timeSlotModel.timeSlot?.name !== timeSlotModel.currentMood?.name) {
			timeSlotModel.end = timeSlotModel.timeIndex === 47
				? timeSlotModel.timeIndex
				: timeSlotModel.timeIndex - 1;

			const newTimeSlot = new TimeslotEntity();
			newTimeSlot.agencyOwnerId = profile.agencyOwnerId;
			newTimeSlot.start = convertIndexToTime(timeSlotModel.dayIndex, timeSlotModel.start);
			newTimeSlot.end = convertIndexToTime(timeSlotModel.dayIndex, timeSlotModel.end);

			if (timeSlotModel.currentMood) {
				newTimeSlot.mood = timeSlotModel.currentMood;
				newTimeSlot.moodId = timeSlotModel.currentMood.id;
			}

			if (scheduleState.activeSchedule) {
				newTimeSlot.schedule = scheduleState.activeSchedule;
				newTimeSlot.scheduleId = scheduleState.activeSchedule.id;

				if (newTimeSlot.mood && newTimeSlot.mood?.name !== 'Silence') {
					if (newTimeSlot.mood?.injectable) {
						newTimeSlot.injectable = true;
					}
					scheduleState.activeSchedule.timeslotss = [...scheduleState.activeSchedule.timeslotss, newTimeSlot];
				}
			}

			timeSlotModel.currentMood = timeSlotModel.timeSlot;
			timeSlotModel.start = timeSlotModel.timeIndex;
		}
	});

	const createTimeSlots = action(() => {
		if (scheduleState.activeSchedule) {
			scheduleState.activeSchedule.timeslotss = [];
		}

		let timeSlotModel: {
			currentMood: MoodEntity | null,
			start: number,
			end: number,
			timeSlot: MoodEntity,
			timeIndex: number,
			dayIndex: number,
		} = {
			currentMood: null,
			start: -1,
			end: -1,
			timeSlot: new MoodEntity(),
			timeIndex: 0,
			dayIndex: 0,
		};

		// Create timeslots for standard moods
		scheduleState.schedule.forEach((day, dayIndex) => {
			timeSlotModel.currentMood = null;
			timeSlotModel.start = -1;
			timeSlotModel.end = -1;
			timeSlotModel.dayIndex = dayIndex;

			day.forEach((timeSlot: MoodEntity, timeIndex: number) => {
				timeSlotModel.timeSlot = timeSlot;
				timeSlotModel.timeIndex = timeIndex;

				addTimeslot(timeSlotModel);
			});
		});

		timeSlotModel = {
			currentMood: null,
			start: -1,
			end: -1,
			timeSlot: new MoodEntity(),
			timeIndex: 0,
			dayIndex: 0,
		};

		// Create timeslots for injected moods
		scheduleState.injectedSchedule.forEach((day, dayIndex) => {
			timeSlotModel.currentMood = null;
			timeSlotModel.start = -1;
			timeSlotModel.end = -1;
			timeSlotModel.dayIndex = dayIndex;

			day.forEach((timeSlot: MoodEntity, timeIndex: number) => {
				timeSlotModel.timeSlot = timeSlot;
				timeSlotModel.timeIndex = timeIndex;

				addTimeslot(timeSlotModel);
			});
		});
	});

	const resetScheduleStateSelection = action(() => {
		scheduleState.selectedDay = -1;
		scheduleState.selectedTime = -1;
	});

	const fillSlot = action((skipReset: boolean) => {
		for (let i = scheduleState.start; i <= scheduleState.end; i++) {
			if (scheduleState.activeMood?.name === 'Silence') {
				// Clear both injectable and standard schedules
				scheduleState.schedule[scheduleState.day][i] = scheduleState.activeMood;
				scheduleState.injectedSchedule[scheduleState.day][i] = scheduleState.activeMood;
			} else if (scheduleState.activeMood?.injectable) {
				if (scheduleState.schedule[scheduleState.day][i].name !== 'Silence') {
					// Only add injectable mood when schedule is not silence
					scheduleState.injectedSchedule[scheduleState.day][i] = scheduleState.activeMood;
				}
			} else {
				scheduleState.schedule[scheduleState.day][i] = scheduleState.activeMood!;
			}
		}

		createTimeSlots();

		if (skipReset) {
			scheduleState.day += 1;
		} else {
			scheduleState.day = -1;
			scheduleState.start = -1;
			scheduleState.end = -1;
			resetScheduleStateSelection();
		}
	});

	const handleClick = action((dayIndex: number, timeIndex: number) => {
		store.editingSchedule = true;
		if (!scheduleState.activeSchedule) {
			alertToast('No Schedule Selected', 'error');
		}
		if (!scheduleState.activeMood) {
			alertToast('No Mood Selected', 'error');
			return;
		}

		// First click on schedule, day will be -1
		if (scheduleState.day === -1) {
			scheduleState.day = dayIndex;
			scheduleState.start = timeIndex;
			scheduleState.selectedDay = dayIndex;
			scheduleState.selectedTime = timeIndex;
			return;
		}

		// Second click on schedule is on the same day as first click
		if (scheduleState.day === dayIndex) {
			if (scheduleState.start <= timeIndex) {
				// Same day - later
				scheduleState.end = timeIndex;
			} else {
				// Same day - earlier
				scheduleState.end = scheduleState.start;
				scheduleState.start = timeIndex;
			}

			fillSlot(false);

			resetScheduleStateSelection();
			return;
		}

		// Second click on schedule is on a later day than first click
		if (scheduleState.day < dayIndex) {
			let realEndTime = -1;
			// Iterate each day in between selected clicks
			for (let d = scheduleState.day; d <= dayIndex; d++) {
				if (scheduleState.start <= timeIndex && realEndTime === -1) {
					// Must fill slot from first to second time
					// for each day moving through each day
					scheduleState.end = timeIndex;
				} else if (scheduleState.start <= realEndTime) {
					// Will run for each subsequent day if
					// earlier time on later day was selected
					// Do nothing. It's already been set
				} else {
					// Second click was for earlier time in
					// later day. Condition runs once for each day.
					realEndTime = scheduleState.start;
					scheduleState.end = realEndTime;
					scheduleState.start = timeIndex;
				}

				fillSlot(d !== dayIndex);
			}

			resetScheduleStateSelection();
			return;
		}

		// Second click on schedule is on earlier day than first click
		const realEndDay = scheduleState.day;
		scheduleState.day = dayIndex;
		let realEndTime = -1;

		// Iterate each day in between selected clicks
		for (let d = scheduleState.day; d <= realEndDay; d++) {
			if (scheduleState.start <= timeIndex && realEndTime === -1) {
				scheduleState.end = timeIndex;
			} else if (scheduleState.start <= realEndTime) {
				// Do nothing. It's already been set
			} else {
				realEndTime = scheduleState.start;
				scheduleState.end = realEndTime;
				scheduleState.start = timeIndex;
			}
			fillSlot(d !== realEndDay);
		}
		resetScheduleStateSelection();
	});

	const saveSchedule = action(() => {
		if (!validateSchedule()) {
			alertToast('There are unfilled time slots in this schedule.', 'error');
		}
		if (scheduleState.activeSchedule) {
			scheduleState.activeSchedule.profile = profile;
			scheduleState.activeSchedule.profileId = profile.id;
			scheduleState.activeSchedule.agencyOwnerId = profile.agencyOwnerId;

			scheduleState.activeSchedule.save(
				{ timeslotss: {} },
				{
					options: [
						{
							key: 'mergeReferences',
							graphQlType: '[String]',
							value: 'timeslotss',
						},
					],
				},
			).then(() => {
				alertToast('Saved schedule.', 'success');
				runInAction(() => { store.editingSchedule = false; });
				queryClient.refetchQueries(['profile', profile.id]);
			}).catch(() => {
				alertToast('Error saving schedule.', 'error');
			});
		}
	});

	const resetSchedule = () => {
		queryClient.refetchQueries(['profile', profile.id]).then(() => {
			runInAction(() => {
				const originalCopy = _.cloneDeep(scheduleState.scheduleCopy);
				scheduleState.activeSchedule = scheduleState.scheduleCopy;
				store.editingSchedule = false;
				setScheduleState();
				scheduleState.scheduleCopy = originalCopy;
			});
		});
	};

	const getClassName = (timeSlot: MoodEntity) => `${timeSlot.colour ?? 'silence'}`;

	const getHoverClassName = () => `hover-${scheduleState.activeMood?.colour ?? 'hover'}`;

	const isFirstOrLast = (dayIndex: number, timeIndex: number) => {
		let result = '';

		const scheduleDay = scheduleState.schedule[dayIndex];

		if (scheduleDay[timeIndex]?.name !== scheduleDay[timeIndex - 1]?.name) {
			result += ' left';
		}

		if (timeIndex === 47 || scheduleDay[timeIndex]?.name !== scheduleDay[timeIndex + 1]?.name) {
			result += ' right';
		}

		return result;
	};

	const subsequentTimeslotCount = (timeSlot: MoodEntity, dayIndex: number, timeIndex: number) => {
		if (isFirstOrLast(dayIndex, timeIndex) === ' left') {
			let subsequentTimeSlots = 1;

			for (let i = timeIndex + 1; i <= 47; i++) {
				if (scheduleState.schedule[dayIndex][i]?.name === timeSlot.name) {
					subsequentTimeSlots += 1;
				}
			}

			if (subsequentTimeSlots >= 10) {
				return 'w-max';
			}

			return `w-${subsequentTimeSlots}`;
		}

		return 'w-0';
	};

	const getTimeslotClassname = (
		timeSlot: MoodEntity,
		dayIndex: number,
		timeIndex: number,
		injectedTimeSlot: MoodEntity,
	): string => {
		let classes = '';
		classes += getClassName(timeSlot);

		if (injectedTimeSlot) {
			const injectedClassName = getClassName(injectedTimeSlot);
			if (injectedClassName !== 'silence') {
				classes += `-${injectedClassName}`;
			}
		}

		classes += ` ${getHoverClassName()}`;
		classes += ` ${isFirstOrLast(dayIndex, timeIndex)}`;
		classes += ` ${subsequentTimeslotCount(timeSlot, dayIndex, timeIndex)}`;

		if (scheduleState.selectedTime === timeIndex && scheduleState.selectedDay === dayIndex) {
			classes += ' selected';
		}

		return classes;
	};

	const resetScheduleState = (schedule: ScheduleEntity) => {
		queryClient.refetchQueries(['profile', profile.id]).then(() => {
			runInAction(() => {
				Object.entries(defaultScheduleState).forEach(([key, value]) => {
					scheduleState[key] = value;
				});
				setScheduleOptions();
				scheduleState.scheduleOptions = [...scheduleState.scheduleOptions.filter(s => s.value !== schedule.id)];
			});
		});
	};

	const printDocument = () => {
		// adding custom styles
		const input = document.getElementById('schedule-builder-export');
		const moodsExport = document.getElementById('moods-export');

		const titleElements = document.getElementsByClassName('schedule');
		const slotElements = document.getElementsByClassName('timeSlot');
		const titleExportElements = document.getElementsByClassName('title-export');
		const moods = document.getElementsByClassName('moods');
		const moodTilesContainer = document.getElementsByClassName('schedule-mood-tiles-container');

		if (moods.length > 0) {
			moods[0].classList.add('no-overflow');
			moods[0].classList.add('display-none');
		}
		if (titleElements) {
			for (let i = 0; i < titleElements.length; i++) {
				titleElements[i].classList.add('font-black');
			}
		}
		if (titleExportElements) {
			for (let i = 0; i < titleExportElements.length; i++) {
				titleExportElements[i].classList.remove('display-none');
			}
		}
		if (slotElements) {
			for (let i = 0; i < slotElements.length; i++) {
				slotElements[i].classList.add('borders-transparent');
			}
		}
		if (moodTilesContainer) {
			moodTilesContainer[0].classList.add('display-none');
		}
		if (moodsExport) moodsExport.classList.remove('display-none');
		if (input) {
			alertToast('Exporting data', 'info');
			html2canvas(input!, {
				windowWidth: 1450,
				scale: 1.5,
			}).then(canvas => {
				const imgData = canvas.toDataURL('image/png');
				// eslint-disable-next-line new-cap
				const pdf = new jsPDF({
					orientation: 'landscape',
				});
				pdf.addImage(imgData, 'JPEG', 10, 23, 300, 163);
				pdf.save('download.pdf');
			});

			// reset custom css styles
			if (titleElements) {
				for (let i = 0; i < titleElements.length; i++) {
					titleElements[i].classList.remove('font-black');
				}
			}
			if (moods.length > 0) {
				moods[0].classList.remove('no-overflow');
				moods[0].classList.remove('display-none');
			}
			if (moodsExport) moodsExport.classList.add('display-none');
			if (titleExportElements) {
				for (let i = 0; i < titleExportElements.length; i++) {
					titleExportElements[i].classList.add('display-none');
				}
			}
			if (slotElements) {
				for (let i = 0; i < slotElements.length; i++) {
					slotElements[i].classList.remove('borders-transparent');
				}
			}
			if (moodTilesContainer) {
				moodTilesContainer[0].classList.remove('display-none');
			}
		}
	};

	/**
	 * Assign the mood to every available timeslot. You can specify
	 * the unassign bool which will remove the give mood from every timeslot.
	 *
	 * Injectable moods will only be added if a mood is already present in
	 * the regular schedule.
	 *
	 * @param moodId The id of the mood to remove
	 * @param schedule A schedule object (indexed [day][time])
	 * @param unassign true if you wish to unassign the mood
	 */
	const assignMood = action((mood: MoodEntity, schedule: MoodEntity[][], unassign: boolean = false): void => {
		store.editingSchedule = true;

		schedule.forEach((day: MoodEntity[], dayIndex: number) => {
			day.forEach((timeSlot: MoodEntity, timeIndex: number) => {
				if (unassign) {
					if (timeSlot && timeSlot.id === mood.id) {
						schedule[dayIndex][timeIndex] = silence;
					}
				} else if (mood.injectable) {
					// Only add injectable mood when schedule is not silence
					if (scheduleState.schedule[dayIndex][timeIndex].name !== 'Silence') {
						schedule[dayIndex][timeIndex] = mood;
					}
				} else {
					schedule[dayIndex][timeIndex] = mood;
				}
			});
		});

		createTimeSlots();
	});

	return (
		<div className="schedule-tab-container">
			<div className="schedule-menu">
				<div className="schedule-selection">
					<p className="mood-instruction">Select a mood to add to schedule</p>
					<Combobox
						model={scheduleState}
						modelProperty="scheduleId"
						label="Schedule"
						labelVisible={false}
						options={scheduleState.scheduleOptions}
						className="type"
						isRequired
						placeholder="Select Schedule"
						onAfterChange={() => {
							runInAction(() => {
								if (profile.scheduless !== undefined) {
									[scheduleState.activeSchedule] = profile.scheduless
										.filter(s => s.id === scheduleState.scheduleId);
									scheduleState.scheduleCopy = _.cloneDeep(profile.scheduless
										.filter(s => s.id === scheduleState.scheduleId)[0]);
								}
							});
						}}
						isDisabled={store.isEditingSchedule}
					/>
					{store.isEditingSchedule && (
						<>
							<Button
								colors={Colors.Primary}
								display={Display.Solid}
								sizes={Sizes.Medium}
								onClick={saveSchedule}
								className="save-schedule-button"
							>
								Save Schedule
							</Button>
							<Button
								colors={Colors.Secondary}
								display={Display.Outline}
								sizes={Sizes.Medium}
								onClick={resetSchedule}
								disabled={!store.isEditingSchedule}
								className="discard-button"
							>
								Discard Changes
							</Button>
						</>
					)}
				</div>

				<div className="schedule-actions">
					{scheduleState.activeSchedule && !store.isEditingSchedule && (
						<div className="buttons-container">
							<Button
								colors={Colors.Secondary}
								display={Display.Outline}
								sizes={Sizes.Medium}
								icon={{ icon: 'download', iconPos: 'icon-left' }}
								onClick={() => printDocument()}
								disabled={store.isEditingSchedule}
								className="export-schedule"
							>
								Export Schedule
							</Button>
							<Button
								colors={Colors.Secondary}
								display={Display.Outline}
								sizes={Sizes.Medium}
								icon={{ icon: 'bin-delete', iconPos: 'icon-left' }}
								onClick={() => {
									if (scheduleState.activeSchedule) {
										if (store.userGroups.some(ug => ug.name === 'AgencyPlaylister')
											&& scheduleState.activeSchedule?.zoness?.length > 0) {
											alertToast(
												'This schedule has been assigned to a zone/s. '
												+ 'Only an Agency Admin may delete this schedule.',
												'error',
											);
											return;
										}
										store.modal.show(
											'Create Schedule',
											<DeleteScheduleModal
												schedule={scheduleState.activeSchedule}
												resetScheduleState={resetScheduleState}
											/>,
										);
									}
								}}
								disabled={store.isEditingSchedule}
							>
								Delete Schedule
							</Button>
						</div>
					)}
					{scheduleState.activeSchedule && !store.isEditingSchedule && (
						<div className="buttons-container-md">
							<Button
								colors={Colors.Secondary}
								display={Display.Outline}
								sizes={Sizes.Medium}
								icon={{ icon: 'download', iconPos: 'icon-top' }}
								onClick={() => printDocument()}
								disabled={store.isEditingSchedule}
								className="export-schedule btn btn--icon icon-download icon-top btn--sm button-schedule"
							/>
							<Button
								colors={Colors.Secondary}
								display={Display.Outline}
								sizes={Sizes.Medium}
								icon={{ icon: 'bin-delete', iconPos: 'icon-top' }}
								className="btn btn--icon btn--sm button-schedule"
								onClick={() => {
									if (scheduleState.activeSchedule) {
										if (store.userGroups.some(ug => ug.name === 'AgencyPlaylister')
											&& scheduleState.activeSchedule?.zoness?.length > 0) {
											alertToast(
												'This schedule has been assigned to a zone/s. '
												+ 'Only an Agency Admin may delete this schedule.',
												'error',
											);
											return;
										}
										store.modal.show(
											'Create Schedule',
											<DeleteScheduleModal
												schedule={scheduleState.activeSchedule}
												resetScheduleState={resetScheduleState}
											/>,
										);
									}
								}}
								disabled={store.isEditingSchedule}
							/>
						</div>
					)}
					<Button
						className="new-schedule"
						colors={Colors.Primary}
						display={Display.Solid}
						sizes={Sizes.Medium}
						icon={{ icon: 'plus', iconPos: 'icon-left' }}
						onClick={() => {
							store.modal.show('Create Schedule', <CreateScheduleModal addSchedule={addSchedule} />);
						}}
						disabled={store.isEditingSchedule}
					>
						New Schedule
					</Button>
				</div>
			</div>

			{/* Defines the area which is used in Export Schedule */}
			<div id="schedule-builder-export" style={{ height: '100%' }}>
				<div className="display-flex flex-space-bt">
					<div>
						{scheduleState.activeSchedule && (
							<h1 className="font-black title-export display-none">
								{scheduleState.activeSchedule.name}
							</h1>
						)}
						{profile && <span className="font-black title-export display-none">{profile.name}</span>}
					</div>
					<div className="moods-list-export display-none" id="moods-export">
						{profile.moodss?.map((mood: MoodEntity, index: number) => (
							<div key={`mood-${mood.name}-${index}-1`} className="display-flex">
								<div
									className={`mood-tile ${mood.colour ?? 'a'} pillList `}
								/>
								<span className="font-black">{mood.name}</span>
							</div>
						))}
					</div>
				</div>

				<div className="schedule-builder">
					<div className="schedule-mood-tiles-container">
						<div className="moods">
							{profile?.moodss?.filter(mood => !mood.injectable).map((mood: MoodEntity) => (
								<MoodTile
									mood={mood}
									key={mood.id}
									onClick={() => runInAction(() => {
										scheduleState.activeMood = mood;
									})}
									isActive={mood.id === scheduleState.activeMood?.id}
								/>
							))}
							<div className={
								`mood-wrapper silence ${scheduleState.activeMood?.name === 'Silence' ? 'selected' : ''}`
							}
							>
								<div
									className="mood-tile silence"
									onClick={() => runInAction(() => {
										scheduleState.activeMood = silence;
									})}
								>
									<p>Silence</p>
								</div>
							</div>

							{
								profile.moodss.some(m => m.injectable)
										&& <h4 style={{ marginTop: '3rem' }}>Injectable</h4>
							}

							{profile.moodss?.filter(mood => mood.injectable).map((mood: MoodEntity) => (
								<MoodTile
									mood={mood}
									key={mood.id}
									onClick={() => runInAction(() => {
										scheduleState.activeMood = mood;
									})}
									contextMenuActions={[
										{
											label: 'Assign',
											onClick: () => assignMood(mood, scheduleState.injectedSchedule),
										},
										{
											label: 'Unassign',
											onClick: () => {
												assignMood(mood, scheduleState.injectedSchedule, true);
											},
										},
									]}
									isActive={mood.id === scheduleState.activeMood?.id}
								/>
							))}
						</div>
					</div>
					{scheduleState.activeSchedule ? (
						<div className="schedule">
							<div className="day">
								<div className="top-left-corner" />
								{scheduleState.schedule[0].map((_timeSlot: MoodEntity, index: number) => {
									if (index % 2 === 0) {
										return (
											<div className="time-label" key={`label-${timeMap[index / 2]}`}>
												{timeMap[index / 2]}
											</div>
										);
									}
									return <Fragment key={`fragment-${index}`} />;
								})}
							</div>
							{scheduleState.schedule.map((day: MoodEntity[], dayIndex: number) => (
								<div className="day" key={`day-${dayIndex}`}>
									<p className="day-label">{dayMap[dayIndex]}</p>
									{day.map((timeSlot: MoodEntity, timeIndex: number) => {
										if (timeSlot) {
											const injectedTs = scheduleState.injectedSchedule[dayIndex][timeIndex];
											return (
												<div
													className={
														`timeSlot ${getTimeslotClassname(
															timeSlot,
															dayIndex,
															timeIndex,
															injectedTs,
														)}`
													}
													onClick={() => handleClick(dayIndex, timeIndex)}
													key={`filled-time-${dayIndex}-${timeIndex}`}
												>
													<div className="filled-slot">
														{
															isFirstOrLast(dayIndex, timeIndex).includes('left')
																? <p>{timeSlot.name}</p>
																: <></>
														}
													</div>
												</div>
											);
										}
										return (
											<div
												className={`timeSlot ${scheduleState.activeMood?.colour ?? 'hover'}`}
												onClick={() => handleClick(dayIndex, timeIndex)}
												key={`empty-time-${dayIndex}-${timeIndex}`}
											/>
										);
									})}
								</div>
							))}
						</div>
					) : (
						<h4 className="schedule-instruction">Select a schedule</h4>
					)}
				</div>
			</div>
		</div>
	);
};

export default observer(ScheduleTab);
