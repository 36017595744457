import React from 'react';
import { DatePicker } from '../../../../Components/DatePicker/DatePicker';
import { TimePicker } from '../../../../Components/TimePicker/TimePicker';

interface ScheduleTimeTabProps {
  publishDateTime: {date: Date, time: Date};
  isPublishingZone?: boolean
}

const ScheduleTimeTab = ({ publishDateTime, isPublishingZone }: ScheduleTimeTabProps) => (
	<div>
		<p>
			{isPublishingZone
				? <i>This zone will receive update in local time</i>
				: <i>All zones linked to this profile will receive update in local time</i>}
		</p>
		<br />
		<div className="publish-datetime-input">
			Schedule update for
			<DatePicker
				className="publish-date"
				model={publishDateTime}
				modelProperty="date"
				humanFriendly
				dateFormat="d/m/Y"
				altFormat="d/m/Y"
			/>
			at
			<TimePicker
				className="publish-time"
				model={publishDateTime}
				modelProperty="time"
				humanFriendly
			/>
		</div>
	</div>
);

export default ScheduleTimeTab;
