/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
// % protected region % [Add extra imports here] off begin
// % protected region % [Add extra imports here] end

export function isNotNull<T>(value: T): value is Exclude<T, null> {
	return value !== null;
}

export function isNotUndefined<T>(value: T): value is Exclude<T, undefined> {
	return value !== undefined;
}

export function isNotNullOrUndefined<T>(value: T): value is Exclude<T, undefined | null> {
	return value !== undefined && value !== null;
}

// % protected region % [Add any extra methods here] off begin
// % protected region % [Add any extra methods here] end
