import React, { useEffect } from 'react';
import { runInAction} from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import axios, { AxiosResponse } from 'axios';
import {
	Button, Colors, Display, Sizes,
} from '../../Components/Button/Button';
import { store } from '../../../Models/Store';
import CustomSpinner from '../../Components/CustomSpinner/CustomSpinner';
import { TrackEntity } from '../../../Models/Entities';
import alert from '../../../Util/ToastifyUtils';
import { PlaylistSummary } from '../../../Util/PlaylistUtils';
import {SERVER_URL} from "../../../Constants";
import ImportTrackValidationPanel from '../Uploads/ImportTrackValidationPanel';
import { IImportTrackValidationModel, ISpotifyValidationImport } from 'Models/Custom/ImportTrackValidationModel';
import _ from 'lodash';
import alertToast from '../../../Util/ToastifyUtils';

interface ISpotifyImportModalProps {
	name: string;
	spotifyId: string;
	openPlaylist: (playlist: PlaylistSummary) => void;
	agencyOwnerId: string;
}

const SpotifyImportModal = (props: ISpotifyImportModalProps): JSX.Element => {
	const loadStates = useLocalStore(() => ({
		checkingForExisting: true,
		importingPlaylist: false,
	}));

	const trackStore = useLocalStore(() => ({
		// Full list of tracks imported from Spotify
		trackValidationList: new Array<IImportTrackValidationModel>(),
		// Tracks to be imported to new Playlist
		tracksToImport: new Array<TrackEntity>(),
		// Spotify Tracks with no matching Track
		missingTracks: new Array<IImportTrackValidationModel>(),
		noExistingTracks: false,
	}));
	
	const onSubmit = async (e: React.SyntheticEvent): Promise<void> => {
		e.preventDefault();

		runInAction(() => {
			loadStates.importingPlaylist = true;
		});
		
		axios.post('/api/entity/PlaylistEntity/ImportPlaylist', {
			name: props.name,
			trackIds: trackStore.tracksToImport.map(t => t.id), 
			agencyOwnerId: props.agencyOwnerId, 
		}).then((res: AxiosResponse<PlaylistSummary>) => {
			props.openPlaylist({
				id: res.data.id,
				name: res.data.name,
				summary: res.data.summary,
			});
			store.modal.hide();
			alert(`Successfully created playlist: ${props.name}`, 'success');
		}).catch(() => {
			alert('An error occurred', 'error');
		}).finally(() => {
			runInAction(() => {
				loadStates.importingPlaylist = false;
			});
		});
	};
	

	const downloadMissingTrackReport = () => {
		//alertToast('Generating Report. This may take a while...', 'info');
		//runInAction(() => reportStore.isGenerating = true);

		axios.post(
			`${SERVER_URL}/api/entity/PlaylistEntity/SpotifyImport/MissingTracks/CSVReport`,
			trackStore.missingTracks
		)
		.then(response => new Blob([response.data]))
		.then(blob => {
			// Create blob link to download
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `MissingTrackReport-${props.spotifyId}.csv`);

			// Append to html link element page
			document.body.appendChild(link);

			// Start download
			link.click();

			// Clean up and remove the link
			link.parentNode?.removeChild(link);
		})
		.catch(() => alertToast('Error generating report', 'error'))
	}

	const checkForExistingTracks = (): void => {
		runInAction(() => {
			trackStore.trackValidationList = [];
			trackStore.tracksToImport = [];
			trackStore.missingTracks = [];
			trackStore.noExistingTracks = false;
			loadStates.checkingForExisting = true;
		});

		axios.get(`/api/entity/PlaylistEntity/SpotifyImport/MissingTracks?spotifyId=${props.spotifyId}`)
			.then((res: AxiosResponse<ISpotifyValidationImport>) => {
				const response = res.data.validatedTracks;
				if (response.length > 0) {
					runInAction(() => {
						// Get all tracks for validation
						trackStore.trackValidationList = response;

						// Get tracks with at least one match
						const trackArray = response.filter(r => r.selectedTrack).map(t => t.selectedTrack) as TrackEntity[];
						trackStore.tracksToImport = ([] as TrackEntity[]).concat.apply([], trackArray) as TrackEntity[];
						trackStore.tracksToImport = _.uniqBy(trackStore.tracksToImport, "id");

						// Get list of tracks with no matches
						trackStore.missingTracks = response.filter(r => !r.matchedTracks || r.matchedTracks.length === 0);
					});
				} else {
					runInAction(() => {
						trackStore.noExistingTracks = true;
					});
				}
			}).catch(() => {
				alert('Could not retrieve playlist. Playlist might not be public.', 'error');
				store.modal.hide();
			}).finally(() => {
				runInAction(() => {
					loadStates.checkingForExisting = false;
				});
			});
	};

	useEffect(() => {
		checkForExistingTracks();
	}, []);

	return (
		<div className="bank-modal-container">
			<h4>Import From Spotify</h4>

			{loadStates.checkingForExisting && (
				<div>
					<p>Checking for missing tracks</p>
					<CustomSpinner />
				</div>
			)}

			{trackStore.trackValidationList.length > 0 && (
				<ImportTrackValidationPanel 
					validatedTracks={trackStore.trackValidationList}
					matchedTracks={trackStore.tracksToImport}
					missingTracks={trackStore.missingTracks}
				/>
			)}

			{trackStore.noExistingTracks && (
				<div className="no-existing">No Missing Tracks</div>
			)}

			<form onSubmit={onSubmit}>
				<div className="form-controls">
					{trackStore.trackValidationList.length > 0 && (
						<Button
							type="button"
							className="csv-download"
							colors={Colors.Secondary}
							display={Display.Outline}
							sizes={Sizes.Medium}
							icon={{ icon: 'download', iconPos: 'icon-left' }}
							onClick={downloadMissingTrackReport}
						>
							Download Missing Tracks
						</Button>
					)}
					<Button
						type="button"
						colors={Colors.Primary}
						display={Display.Outline}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'cancel' }}
						onClick={(): void => store.modal.hide()}
					>
						Cancel
					</Button>

					{loadStates.importingPlaylist
						? <CustomSpinner />
						: (
							<Button
								type="submit"
								disabled={loadStates.checkingForExisting}
								colors={Colors.Primary}
								display={Display.Solid}
								sizes={Sizes.Medium}
								buttonProps={{ id: 'submit' }}
							>
								Continue
							</Button>
						)}
				</div>
			</form>
		</div>
	);
};

export default observer(SpotifyImportModal);
