// @ts-nocheck
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { RefObject, useEffect } from 'react';
import { runInAction } from 'mobx';
import { InfiniteData } from 'react-query';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
} from 'react-beautiful-dnd';
import {
  Button,
  Colors,
  Display,
  Sizes,
} from '../../../Components/Button/Button';
import {
  AlbumEntity,
  ArtistEntity,
  GenreEntity,
  PlaylistEntity,
  TagEntity,
  TrackEntity,
} from '../../../../Models/Entities';
import {
  CollectionType,
  MultiSelectStore,
  SearchResultObject,
} from '../../../../Util/PlaylistUtils';
import TrackTile from '../TrackTile';
import AlbumArtPlaceholder from 'assets/empty-album-art.svg';

interface InfinitePlaylistsProps {
  updatePanel: (show: string) => void;
  searchStore: {
    term: string;
  };
  isLoading: boolean;
  data: InfiniteData<SearchResultObject> | undefined;
  openTab: (tab: CustomTabObject) => void;
  loadMoreButtonRef: RefObject<HTMLButtonElement>;
  fetchNextPage: () => void;
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
  panelEntity: CollectionType;
  multiSelect: (
    event: React.MouseEvent<HTMLInputElement>,
    index: number,
    start: boolean,
    track: string,
    trackList: string[],
    collectionId: string
  ) => void;
  multiSelectStore: MultiSelectStore;
  clearMultiSelect: () => void;
}

const InfiniteList = (props: InfinitePlaylistsProps) => {
  const {
    updatePanel,
    searchStore,
    isLoading,
    data,
    openTab,
    loadMoreButtonRef,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    panelEntity,
    multiSelect,
    multiSelectStore,
    clearMultiSelect,
  } = props;

  const onKeyDown = (
		e: React.MouseEvent | React.KeyboardEvent,
		snapshot: DraggableStateSnapshot,
	) => {
		if (e.defaultPrevented || snapshot.isDragging) return;
		e.preventDefault();
	};

  // useEffect is required to initialise multiSelectStore
  // It can only be removed when refactoring completed on multi select functionality
  useEffect(() => {}, [multiSelectStore.selected, multiSelectStore.numSelected]);

  const returnItemAsEntity = (item: PlaylistEntity
    | TagEntity
    | TrackEntity
    | ArtistEntity
    | AlbumEntity, entity: CollectionType) => {
    switch (entity) {
      case 'tracks':
        return new TrackEntity(item);
      case 'albums':
        return new AlbumEntity(item);
      case 'genres':
        return new TagEntity(item);
      case 'playlists':
        return new PlaylistEntity(item);
      case 'artists':
        return new ArtistEntity(item);
      case 'primaryGenres':
        return new TagEntity(item);
    }
  };

  const renderList = (
    fetchedData: InfiniteData<SearchResultObject> | undefined,
  ) => {
    const pages = fetchedData?.pages;
    const listOfLists = [].concat.apply(
      pages?.map(page => page[panelEntity]),
    ) as
      | PlaylistEntity[][]
      | TagEntity[][]
      | TrackEntity[][]
      | ArtistEntity[][]
      | AlbumEntity[][];
    const listOfTracks = [] as TrackEntity[];

    if (panelEntity === 'tracks') {
      // @ts-ignore
      listOfLists.forEach((list: TrackEntity[]) => {
        list.forEach((entity: TrackEntity) => {
          listOfTracks.push(new TrackEntity(entity as TrackEntity));
        });
      });
    }

    return (
      <>
        {
          // @ts-ignore
          listOfLists.map(
            (
              list:
                | PlaylistEntity[]
                | TagEntity[]
                | TrackEntity[]
                | ArtistEntity[]
                | AlbumEntity[],
            ) => list?.map(
                (
                  item:
                    | PlaylistEntity
                    | TagEntity
                    | TrackEntity
                    | ArtistEntity
                    | AlbumEntity,
                  index: number,
                ) => {
                  const target = returnItemAsEntity(item, panelEntity);

                  if (panelEntity === 'tracks') {
                    const track = new TrackEntity(item as TrackEntity);
                    return (
                      <Draggable
                        draggableId={`search-${track.id}`}
                        index={index}
                        key={`search-${track.id}`}
                        isDragDisabled={
                          !multiSelectStore.selected.includes(track.id)
                        }
                      >
                        {(
                          provided: DraggableProvided,
                          snapshot: DraggableStateSnapshot,
                        ) => (
                          <div
                            className={`result-item track ${
                              multiSelectStore.selected.includes(track.id)
                                ? 'selected'
                                : ''
                            }`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onKeyDown={(e: React.KeyboardEvent) => onKeyDown(e, snapshot)}
                            onClick={(
                              e: React.MouseEvent<HTMLInputElement>,
                            ) => {
                              e.preventDefault();
                              const isStart = e.ctrlKey || !e.shiftKey;
                              multiSelect(
                                e,
                                index,
                                isStart,
                                track.id,
                                listOfTracks.map(t => t.id),
                                'infiniteList',
                              );
                            }}
                          >
                            <TrackTile track={track} />
                          </div>
                        )}
                      </Draggable>
                    );
                  }
                  return (
                    <div
                      className={`result-item ${panelEntity}`}
                      key={`${panelEntity}-${target.id}`}
                      data-testid={`open-${panelEntity}-${target.name || target.title}`}
                      onClick={() => {
                        openTab({
                          name: target.name || target.title,
                          entityType: panelEntity,
                          id: target.id,
                        });
                      }}
                    >
                      <div className="track-label">
                        <p>{target.name || target.title}</p>
                      </div>
                    </div>
                  );
                },
              ),
          )
        }
      </>
    );
  };

  return (
    <div className="infinite-scroll-area">
      <div className="infinite-scroll-header">
        <Button
        className="see-all"
        sizes={Sizes.Small}
        display={Display.Text}
        colors={Colors.White}
        icon={{ icon: 'arrow-left', iconPos: 'icon-left' }}
        onClick={() => runInAction(() => {
            clearMultiSelect();
            updatePanel('all');
          })}
        >
        Back
        </Button>
        <p className="see-all-blurb">
          {`Showing ${panelEntity} results for `}
          <span className="see-all-blurb term">{` ${searchStore.term}`}</span>
        </p>
      </div>
      <div className="results">
        <div className="result-section">
            {isLoading ? <p>Loading</p> : (
             <>
              <Droppable
                droppableId="search-panel-tracks"
                isDropDisabled
                renderClone={(provided, snapshot, rubric) => (
                  <div
                    className="track-wrapper-clone"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div className="select-count">
                      {multiSelectStore?.numSelected}
                    </div>

                    <img
                      src={AlbumArtPlaceholder}
                      alt="album-art"
                      className="album-art"
                    />
                  </div>
                )}
              >
                {provided => (
                  <div
                    ref={provided.innerRef}
                    /* eslint-disable react/jsx-props-no-spreading */
                    {...provided.droppableProps}
                  >
                    {renderList(data)}
                    {provided.placeholder}
                    <div className="infinite-scroll-button-container">
                      <button
                          className="infiniteListEndButton"
                          ref={loadMoreButtonRef}
                          onClick={() => fetchNextPage()}
                          disabled={!hasNextPage || isFetchingNextPage}
                      >
                          {isLoading || isFetchingNextPage || hasNextPage ? 'Loading...' : 'Nothing more to load'}
                      </button>
                    </div>
                  </div>
                )}
              </Droppable>
             </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfiniteList;
