import React from 'react';
import { runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import axios, { AxiosResponse } from 'axios';
import { TextField } from '../../Components/TextBox/TextBox';
import {
	Button, Colors, Display, Sizes, 
} from '../../Components/Button/Button';
import { store } from '../../../Models/Store';
import FileUpload from '../../Components/FileUpload/FileUpload';
import CustomSpinner from '../../Components/CustomSpinner/CustomSpinner';
import { BankEntity, TrackEntity } from '../../../Models/Entities';
import TrackValidationPanel from './TrackValidationPanel';
import alert from '../../../Util/ToastifyUtils';

interface IBankForm {
	name: string;
	file: Blob | null;
	ownerId: string;
	ownerName: string;
	errors: {
		name?: string;
		file?: string;
	};
}

const defaultBankForm: IBankForm = {
	name: '',
	file: null,
	ownerId: '',
	ownerName: '',
	errors: {
		name: '',
		file: '',
	},
};

const errorMessages = {
	name: 'Bank must have a name.',
	file: 'Must upload a file.',
};

interface CreateBankModalProps {
	callback: (newBank: BankEntity) => void;
}

const CreateBankModal = (props: CreateBankModalProps): JSX.Element => {
	const bankForm = useLocalStore(() => (
		defaultBankForm
	));
	
	const loadStates = useLocalStore(() => ({
		checkingForExisting: false,
		uploadingBank: false,
	}));
	
	const trackStore = useLocalStore(() => ({
		existingTracks: new Array<TrackEntity>(),
		noExistingTracks: false,
	}));

	const validateField = (): void => {
		if (bankForm.name.length === 0) {
			runInAction(() => bankForm.errors.name = errorMessages.name);
		} else {
			runInAction(() => delete bankForm.errors.name);
		}
		
		if (bankForm.file === null) {
			runInAction(() => bankForm.errors.file = errorMessages.file);
		} else {
			runInAction(() => delete bankForm.errors.file);
		}
	};

	const validateCheckExisting = (): boolean => {
		if (bankForm.file === null) {
			runInAction(() => bankForm.errors.file = errorMessages.file);
		} else {
			runInAction(() => delete bankForm.errors.file);
		}
		
		return bankForm.file !== null;
	};

	const onSubmit = async (e: React.SyntheticEvent): Promise<void> => {
		e.preventDefault();

		validateField();

		if (Object.keys(bankForm.errors).length === 0) {
			const formData = new FormData();
			formData.append('Body', bankForm.file ? bankForm.file : '');
			formData.append('Name', bankForm.name);
			formData.append('OwnerId', store.userId ? store.userId : '');
			formData.append('OwnerName', store.email ? store.email : '');

			const config = {
				headers: {
					'content-type': 'multipart/form-data',
				},
			};

			runInAction(() => {
				loadStates.uploadingBank = true;
			});

			alert(`Uploading Track Data From CSV: ${bankForm.name}`, 'success');

			axios.post('/api/entity/DiscoTrackEntity/uploadBank', formData, config)
				.then(res => {
					runInAction(() => {
						alert(`Track Data Uploaded. Starting Background Import: ${bankForm.name}`, 'success');
						loadStates.uploadingBank = false;
						store.modal.hide();
						store.apolloClient.clearStore();
						props.callback(res.data.bank);
					});
				}).catch(err => {
					if (err.response.status == 504) {
						runInAction(() => {
							alert(`Track Data Uploaded: ${bankForm.name}`, 'success');
							alert('Warning: The server encountered an issue starting background import, it will automatically retry later', 'warning');
							console.error(err.response.data);
							loadStates.uploadingBank = false;
							store.modal.hide();
							store.apolloClient.clearStore();
						});
					} else {
						alert('Something went wrong creating tracks', 'error');
					}
					
				});
		}
	};
	
	const checkForExistingTracks = (e: React.SyntheticEvent): void => {
		e.preventDefault();

		if (validateCheckExisting()) {
			const formData = new FormData();
			formData.append('body', bankForm.file ? bankForm.file : '');

			const config = {
				headers: {
					'content-type': 'multipart/form-data',
				},
			};
			
			runInAction(() => {
				trackStore.existingTracks = [];
				loadStates.checkingForExisting = true;
				trackStore.noExistingTracks = false;
			});
			
			axios.post('/api/entity/DiscoTrackEntity/Existing', formData, config)
				.then((res: AxiosResponse<TrackEntity[]>) => {
					if (res.data.length > 0) {
						runInAction(() => {
							trackStore.existingTracks = res.data;
						});
					} else {
						runInAction(() => {
							trackStore.noExistingTracks = true;
						});
					}
				}).catch(() => {
					alert('Invalid CSV file', 'error');
				})
				.finally(() => {
					runInAction(() => {
						loadStates.checkingForExisting = false;
					});
				});
		}
	}; 

	return (
		<div className="bank-modal-container">
			<h4>Upload Bank</h4>

			<form onSubmit={onSubmit}>
				<TextField
					model={bankForm}
					modelProperty="name"
					label="Bank Name"
					isRequired
					errors={bankForm.errors.name}
				/>
				
				<FileUpload 
					model={bankForm} 
					modelProperty="file" 
					buttonText="Upload CSV" 
					disableDropArea 
					isDisabled={loadStates.uploadingBank}
					preview={(file): JSX.Element => {
						if (file) {
							return <div className="csv-label">{file.name}</div>;
						}
						return <></>;
					}}
					errors={bankForm.errors.file}
				/>
				
				<div className="form-controls">
					<Button
						type="button"
						colors={Colors.Primary}
						display={Display.Outline}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'cancel' }}
						onClick={(): void => store.modal.hide()}
					>
						Cancel
					</Button>

					{loadStates.uploadingBank
						? <CustomSpinner />
						: (
							<Button
								type="submit"
								disabled={loadStates.checkingForExisting}
								colors={Colors.Primary}
								display={Display.Solid}
								sizes={Sizes.Medium}
								buttonProps={{ id: 'submit' }}
							>
								Upload
							</Button>
						)}
				</div>
			</form>

			{loadStates.checkingForExisting
				? <CustomSpinner />
				: (
					<Button
						disabled={loadStates.uploadingBank}
						display={Display.Solid} 
						colors={Colors.Secondary}
						onClick={(e): void => checkForExistingTracks(e)}
					>
						Check for existing tracks
					</Button>
				)}

			{trackStore.existingTracks.length > 0 && (
				<TrackValidationPanel validatedTracks={trackStore.existingTracks} checkExisting />
			)}

			{trackStore.noExistingTracks && (
				<div className="no-existing">No Existing Tracks</div>
			)}
		</div>
	);
};

export default observer(CreateBankModal);
