import { observer } from 'mobx-react';
import { Droppable } from 'react-beautiful-dnd';
import { runInAction } from 'mobx';
import { contextMenu } from 'react-contexify';
import React from 'react';
import { useQuery } from 'react-query';
import Axios from 'axios';
import { ContextMenu } from '../../Components/ContextMenu/ContextMenu';
import { store } from '../../../Models/Store';
import { PlaylistSummary } from '../../../Util/PlaylistUtils';
import { Button, Colors, Display } from '../../Components/Button/Button';
import { SERVER_URL } from '../../../Constants';
import { TextField } from '../../Components/TextBox/TextBox';
import alert from '../../../Util/ToastifyUtils';
import DuplicatePlaylistConfirmation from './Modals/DuplicatePlaylistConfirmation';
import * as uuid from 'uuid';
import { Tooltip } from 'Views/Components/Tooltip/Tooltip';

interface IDeletePlaylistConfirmationProps {
	playlist: PlaylistSummary;
	deletePlaylist: (playlistSummary: PlaylistSummary) => void;
}

const DeletePlaylistConfirmation = observer((props: IDeletePlaylistConfirmationProps) => {
	const { playlist, deletePlaylist } = props;

	return (
		<div className="delete-confirmation-modal">
			<h4>
				{`Are you sure you want to delete ${playlist.name}?`}
			</h4>
			<div className="form-controls">
				<Button
					className="cancel"
					display={Display.Outline}
					colors={Colors.White}
					onClick={() => store.modal.hide()}
				>
					Cancel
				</Button>
				<Button
					className="submit"
					display={Display.Solid}
					colors={Colors.Primary}
					onClick={() => {
						deletePlaylist(playlist);
						store.modal.hide();
					}}
				>
					Delete Playlist
				</Button>
			</div>
		</div>
	);
});

interface IEditPlaylistConfirmationProps {
	playlist: PlaylistSummary;
	renamePlaylist: (playlistSummary: PlaylistSummary) => void;
}

const EditPlaylistConfirmation = observer((props: IEditPlaylistConfirmationProps) => {
	const { playlist, renamePlaylist } = props;

	return (
		<div className="edit-confirmation-modal">
			<h3>
				Edit Playlist Name
			</h3>
			<form onSubmit={e => {
				e.preventDefault();
				if (playlist.name.length === 0) {
					alert('Playlist name cannot be empty', 'error');
					return;
				}
				renamePlaylist(playlist);
				store.modal.hide();
			}}
			>
				<TextField
					model={playlist}
					modelProperty="name"
					label="Playlist Name"
					isRequired
				/>
			</form>
			<div className="form-controls">
				
				<Button
					className="cancel"
					display={Display.Outline}
					colors={Colors.White}
					onClick={() => store.modal.hide()}
				>
					Cancel
				</Button>
				<Button
					className="submit"
					display={Display.Solid}
					colors={Colors.Primary}
					onClick={() => {
						if (playlist.name.length === 0) {
							alert('Playlist name cannot be empty', 'error');
							return;
						}
						renamePlaylist(playlist);
						store.modal.hide();
					}}
				>
					Submit
				</Button>
			</div>
		</div>
	);
});

interface IPlaylistTileProps {
	playlist: PlaylistSummary;
	activePlaylistsStore: { active: PlaylistSummary; activePlaylists: PlaylistSummary[] };
	makeActive: (playlistSummary: PlaylistSummary) => void;
	closeActive: (playlistSummary: PlaylistSummary) => void;
	closePlaylist: (playlistSummary: PlaylistSummary) => void;
	deletePlaylist: (playlistSummary: PlaylistSummary) => void;
	isExpanded: boolean;
	renamePlaylist: (playlistSummary: PlaylistSummary) => void;
	duplicatePlaylist: (originalPlaylistId: string, playlistSummary: PlaylistSummary) => void;
}

const PlaylistTile = observer((props: IPlaylistTileProps) => {
	const {
		playlist,
		activePlaylistsStore,
		makeActive,
		closeActive,
		closePlaylist,
		deletePlaylist,
		isExpanded,
		renamePlaylist,
		duplicatePlaylist,
	} = props;

	const getTileClassName = (p: PlaylistSummary) => {
		if (isExpanded) {
			if (activePlaylistsStore.activePlaylists.map(x => x.id).includes(p.id)) {
				return ' active';
			}
		} else if (p.id === activePlaylistsStore.active.id) {
			return ' active';
		}
		return '';
	};

	const { status, data } = useQuery(
		['summary', playlist.id],
		async (): Promise<string> => {
			const res = await Axios.get(`${SERVER_URL}/api/playlist_search/summary?id=${playlist.id}`);

			return res.data.summary as string;
		},
	);

	return (
		<div
			className={`playlist-tile${getTileClassName(playlist)}`}
			onClick={() => runInAction(() => {
				if (isExpanded) {
					if (activePlaylistsStore.activePlaylists.map(x => x.id).includes(playlist.id)) { closeActive(playlist); } else { makeActive(playlist); }
				} else if (activePlaylistsStore.active.id === playlist.id) {
					closeActive(playlist);
				} else {
					makeActive(playlist);
				}
			})}
		>
			<Droppable droppableId={`destination-tile-${playlist.id}`} key={playlist.id}>
				{provided => (
					<div
						className="tile-drop-area"
						ref={provided.innerRef}
						{...provided.droppableProps}
					>
						<h5>{playlist.name}</h5>
						<p>{data}</p>
					</div>
				)}
			</Droppable>
			<div className="input-group mr-1">
				<Tooltip
					id={playlist.id}
					content={`Created: ${playlist.created ?? ''}\n`
						+ `Modified: ${playlist.modified ?? ''}\n`
						+ (playlist.mood ? `Mood: ${playlist.mood ?? ''}`: '')}
				></Tooltip>
			</div>
			<div
				className="icon-more-vertical icon-top"	
				data-testid={`context-menu-${playlist.id}`}
				onClick={e => {
					e.stopPropagation();
					contextMenu.show({ event: e, id: `context-menu-${playlist.id}` }); 
				}}
			/>
			<ContextMenu
				menuId={`context-menu-${playlist.id}`}
				actions={[
					{
						label: 'Close',
						onClick: (e): void => {
							e.event.stopPropagation();
							closeActive(playlist);
							closePlaylist(playlist);
						},
					},
					{ label: 'Delete', onClick: (): void => store.modal.show('Delete Playlist Confirmation', <DeletePlaylistConfirmation playlist={playlist} deletePlaylist={deletePlaylist} />) },
					{ label: 'Rename', onClick: (): void => store.modal.show('Rename Playlist Confirmation', <EditPlaylistConfirmation playlist={playlist} renamePlaylist={renamePlaylist} />) },
					{
						label: 'Duplicate',
						onClick: (): void => store.modal.show(
								'Duplicate Playlist Confirmation',
								<DuplicatePlaylistConfirmation
									originalPlaylist={playlist}
									newPlaylist={{ id: uuid.v4(), name: playlist.name, summary: playlist.summary }}
									duplicatePlaylist={duplicatePlaylist}
								/>
							)
					},
				]}
			/>
		</div>
	);
});

export default PlaylistTile;
