import { observer } from 'mobx-react';
import React from 'react';
import { Button, Colors, Display } from '../../Components/Button/Button';
import { store } from '../../../Models/Store';

interface IArchiveTrackConfirmationProps {
	trackName: string;
	archiveTrack: () => void;
}

const ArchiveTrackConfirmation = observer((props: IArchiveTrackConfirmationProps) => {
	const { trackName, archiveTrack } = props;

	return (
		<div className="delete-confirmation-modal">
			<h4>
				{`Are you sure you want to archive ${trackName}?`}
			</h4>
			<div className="form-controls">
				<Button
					className="cancel"
					display={Display.Outline}
					colors={Colors.White}
					onClick={() => store.modal.hide()}
				>
					Cancel
				</Button>
				<Button
					className="submit"
					display={Display.Solid}
					colors={Colors.Primary}
					onClick={() => {
						archiveTrack();
						store.modal.hide();
					}}
				>
					Archive Track
				</Button>
			</div>
		</div>
	);
});

export default ArchiveTrackConfirmation;
