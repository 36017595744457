import { observer, useLocalStore } from 'mobx-react';
import React, { useEffect } from 'react';
import { runInAction } from 'mobx';
import { useQueryClient } from 'react-query';
import { TrackEntity } from '../../../Models/Entities';
import { store } from '../../../Models/Store';
import alert from '../../../Util/ToastifyUtils';
import FadeControl from './FadeControl';
import {
	Button, Colors, Display, Sizes,
} from '../Button/Button';

interface IMarkTrackCompleteConfirmationProps {
	trackName: string;
	markComplete: () => void;
}

const MarkTrackComplete = observer((props: IMarkTrackCompleteConfirmationProps) => {
	const { trackName, markComplete } = props;

	return (
		<div className="delete-confirmation-modal">
			<h4>
				{`Are you sure you want to mark ${trackName} as done?`}
			</h4>
			<div className="form-controls">
				<Button
					className="cancel"
					display={Display.Outline}
					colors={Colors.White}
					onClick={() => store.modal.hide()}
				>
					Cancel
				</Button>
				<Button
					className="submit"
					display={Display.Solid}
					colors={Colors.Primary}
					onClick={() => markComplete()}
				>
					Mark Complete
				</Button>
			</div>
		</div>
	);
});

interface Props {
	track?: TrackEntity;
	fades?: {
		fadeInMinutes: number;
		fadeInSeconds: number;
		fadeOutMinutes: number;
		fadeOutSeconds: number;
		enabled?: boolean;
	}
	flexDirection?: 'row' | 'column';
}

const FadeControls = observer(({ track, fades: providedFades, flexDirection }: Props) => {
	const { track: storeTrack, getCurrentTime, setCurrentTime } = store;

	const currentTrack = track ?? storeTrack;

	const queryClient = useQueryClient();

	const fades = providedFades ?? useLocalStore(() => ({
		fadeInMinutes: 0,
		fadeInSeconds: 0,
		fadeOutMinutes: 0,
		fadeOutSeconds: 0,
		enabled: false,
	}));

	const fetchPermission = async (): Promise<boolean> => {
		store.getCanSetFades().then(res => {
			runInAction(() => {
				fades.enabled = res;
			});
		});

		return store.getCanUpload();
	};

	const markTrackComplete = (): void => {
		runInAction(() => {
			currentTrack.sorted = true;
		});

		currentTrack.save().then(() => {
			alert('Track marked complete', 'success');
			queryClient.refetchQueries('playlist');
			queryClient.refetchQueries('banks');
			store.modal.hide();
		});
	};

	const setFade = (
		fadeDirection: 'in' | 'out',
		fadeTimeInSeconds: number,
	): void => {
		// Round time to 1 decimal place, this is requested by the client
		const newFade = Math.floor(fadeTimeInSeconds*10)/10;

		if (fadeDirection === 'in') {
			// check if fade in is before fade out
			if (!!currentTrack.fadeOut && currentTrack.fadeOut !== 0 && newFade > currentTrack.fadeOut) {
				alert('Fade in time must be before fade out time', 'error');
				return;
			}

			// update currentTrack fadeIn
			if (newFade < 0) {
				runInAction(() => { currentTrack.fadeIn = 0; });
			} else {
				runInAction(() => { currentTrack.fadeIn = newFade; });
			}
		}

		if (fadeDirection === 'out') {
			// check if fade out is after fade in
			if (currentTrack.fadeIn !== 0 && newFade < currentTrack.fadeIn) {
				alert('Fade out time must be after fade in time', 'error');
				return;
			}

			// update currentTrack fadeOut
			if (newFade > currentTrack.length) {
				runInAction(() => { currentTrack.fadeOut = currentTrack.length; });
			} else {
				runInAction(() => { currentTrack.fadeOut = newFade; });
			}
		}

		currentTrack.save().then(() => {
			if (window.location.pathname === '/playlisting') {
				queryClient.refetchQueries('searchAll');
				queryClient.refetchQueries('collection_contents');
				store.openPlaylists.forEach(playlist => {
					queryClient.refetchQueries(['playlist_contents', playlist.id]);
				});
			} else if (
				window.location.pathname.includes('/profile-management/edit/')
			) {
				queryClient.refetchQueries('collection_contents');
				queryClient.refetchQueries('mood_contents');
			} else if (window.location.pathname === '/uploads') {
				queryClient.refetchQueries('collection_contents');
			}
		});

		runInAction(() => {
			store._track = currentTrack;
		});

		alert(`Fade ${fadeDirection} updated`, 'success');
	};

	useEffect(() => {
		runInAction(() => {
			fades.fadeInMinutes = currentTrack.fadeIn ? Math.floor(currentTrack.fadeIn / 60) : 0;
			fades.fadeInSeconds = currentTrack.fadeIn ? (currentTrack.fadeIn * 10 - Math.floor(currentTrack.fadeIn / 60) * 60 * 10) / 10 : 0;
			fades.fadeOutMinutes = currentTrack.fadeOut ? Math.floor(currentTrack.fadeOut / 60) : 0;
			fades.fadeOutSeconds = currentTrack.fadeOut ? (currentTrack.fadeOut * 10 - Math.floor(currentTrack.fadeOut / 60) * 60 * 10) / 10 : 0;
		});
	}, [currentTrack, currentTrack.fadeIn, currentTrack.fadeOut]);

	useEffect(() => {
		async function fetch() {
			await fetchPermission();
		}

		fetch();
	}, []);

	if (!getCurrentTime || !setCurrentTime) return null;

	return (
		<div className={`fade-controls ${flexDirection ?? ''} invert-border`}>
			<FadeControl
				fadeDirection="in"
				setFade={setFade}
				currentTrack={currentTrack}
				fades={fades}
			/>

			<FadeControl
				fadeDirection="out"
				setFade={setFade}
				currentTrack={currentTrack}
				fades={fades}
			/>

			{!currentTrack.sorted && (
				<div className="fade-row round-right">
					<Button
						className="done-track-button"
						sizes={Sizes.Small}
						display={Display.Solid}
						colors={Colors.Secondary}
						onClick={() => store.modal.show('Mark Track Complete Confirmation', 
							<MarkTrackComplete trackName={currentTrack.title} markComplete={markTrackComplete} />)}
					>
						Done
					</Button>
				</div>
			)}
		</div>
	);
});

export default FadeControls;
