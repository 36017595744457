import React from 'react';
import { contextMenu } from 'react-contexify';
import { MoodEntity } from '../../../../Models/Entities';
import { Button } from '../../../Components/Button/Button';
import { ContextMenu, IContextMenuActions } from '../../../Components/ContextMenu/ContextMenu';

interface MoodTileProps {
	mood: MoodEntity;
	onClick: () => void;
	contextMenuActions?: IContextMenuActions;
	isActive?: boolean
}

const MoodTile = (props: MoodTileProps): JSX.Element => {
	const {
 mood, onClick, contextMenuActions = [], isActive = false,
} = props;
	const { id, colour } = mood;

	return (
		<div
			key={`mood-title-key-${id}`}
			className={`mood-wrapper${isActive ? ` selected ${colour}` : ''}`}
		>
			<div
				role="button"
				tabIndex={0}
				className={`mood-tile ${mood.colour}`}
				onClick={onClick}
			>
				<div>
					{mood.name}
				</div>

				{contextMenuActions.length > 0 && (
					<>
						<Button
							className="mood-context-button"
							icon={{ icon: 'more-vertical', iconPos: 'icon-right' }}
							onClick={e => contextMenu.show({ id: `mood-menu-${mood.id}`, event: e })}
						/>
						<ContextMenu
							menuId={`mood-menu-${mood.id}`}
							actions={contextMenuActions}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default MoodTile;
