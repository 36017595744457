import { useEffect, RefObject } from 'react';

export type intersectionObserverOptions = {
	root: RefObject<Element> | null;
	target: RefObject<Element>;
	onIntersect: () => void;
	threshold?: number;
	rootMargin?: string;
	enabled?: boolean;
};

export default ({
	root,
	target,
	onIntersect,
	threshold = 1.0,
	rootMargin = '0px',
	enabled = true,
}: intersectionObserverOptions) => {
	useEffect(() => {
		if (!enabled) {
			return () => undefined;
		}

		const observer = new IntersectionObserver(
			entries => entries.forEach(entry => entry.isIntersecting && onIntersect()),
			{
				root: root && root.current,
				rootMargin,
				threshold,
			},
		);

		const el = target && target.current;

		if (!el) {
			return () => undefined;
		}

		observer.observe(el);

		return () => observer.unobserve(el);
	}, [target.current, enabled]);
};
