/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import ApplicationUserAgencyEntity from 'Models/Security/Acl/ApplicationUserAgencyEntity';
import AgencyPlaylisterAgencyEntity from 'Models/Security/Acl/AgencyPlaylisterAgencyEntity';
import AgencyAdminAgencyEntity from 'Models/Security/Acl/AgencyAdminAgencyEntity';
import VisitorsAgencyEntity from 'Models/Security/Acl/VisitorsAgencyEntity';
import MustardAdminAgencyEntity from 'Models/Security/Acl/MustardAdminAgencyEntity';
import MustardManagementAgencyEntity from 'Models/Security/Acl/MustardManagementAgencyEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IAgencyEntityAttributes extends IModelAttributes {
	name: string;
	zoneLicences: number;
	trackLicences: number;
	primaryContactName: string;
	primaryContactEmail: string;
	primaryContactPhoneNumber: string;
	fadeInDuration: number;
	fadeOutDuration: number;
	fadeVolume: number;
	archived: boolean;

	agencyAdminss: Array<
		| Models.AgencyAdminEntity
		| Models.IAgencyAdminEntityAttributes
	>;
	agencyPlaylisterss: Array<
		| Models.AgencyPlaylisterEntity
		| Models.IAgencyPlaylisterEntityAttributes
	>;
	clientss: Array<
		| Models.ClientEntity
		| Models.IClientEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('AgencyEntity', 'Agency')
// % protected region % [Customise your entity metadata here] end
export default class AgencyEntity extends Model
	implements IAgencyEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new ApplicationUserAgencyEntity(),
		new AgencyPlaylisterAgencyEntity(),
		new AgencyAdminAgencyEntity(),
		new VisitorsAgencyEntity(),
		new MustardAdminAgencyEntity(),
		new MustardManagementAgencyEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'name',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@Validators.Required()
	@Validators.Unique()
	@observable
	@attribute<AgencyEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Zone Licences'] off begin
	/**
	 * How many zones the agency can create
	 */
	@Validators.Required()
	@Validators.Integer()
	@observable
	@attribute<AgencyEntity, number>()
	@CRUD({
		name: 'Zone Licences',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public zoneLicences: number;
	// % protected region % [Modify props to the crud options here for attribute 'Zone Licences'] end

	// % protected region % [Modify props to the crud options here for attribute 'Track Licences'] off begin
	/**
	 * How many tracks the agency can addper profile
	 */
	@Validators.Required()
	@Validators.Integer()
	@observable
	@attribute<AgencyEntity, number>()
	@CRUD({
		name: 'Track Licences',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public trackLicences: number;
	// % protected region % [Modify props to the crud options here for attribute 'Track Licences'] end

	// % protected region % [Modify props to the crud options here for attribute 'Primary Contact Name'] off begin
	@observable
	@attribute<AgencyEntity, string>()
	@CRUD({
		name: 'Primary Contact Name',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public primaryContactName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Primary Contact Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Primary Contact Email'] off begin
	@observable
	@attribute<AgencyEntity, string>()
	@CRUD({
		name: 'Primary Contact Email',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public primaryContactEmail: string;
	// % protected region % [Modify props to the crud options here for attribute 'Primary Contact Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'Primary Contact Phone Number'] off begin
	@observable
	@attribute<AgencyEntity, string>()
	@CRUD({
		name: 'Primary Contact Phone Number',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public primaryContactPhoneNumber: string;
	// % protected region % [Modify props to the crud options here for attribute 'Primary Contact Phone Number'] end

	// % protected region % [Modify props to the crud options here for attribute 'Fade In Duration'] off begin
	/**
	 * Fade In Duration
	 */
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<AgencyEntity, number>()
	@CRUD({
		name: 'Fade In Duration',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public fadeInDuration: number;
	// % protected region % [Modify props to the crud options here for attribute 'Fade In Duration'] end

	// % protected region % [Modify props to the crud options here for attribute 'Fade Out Duration'] off begin
	/**
	 * Fade Out Duration
	 */
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<AgencyEntity, number>()
	@CRUD({
		name: 'Fade Out Duration',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public fadeOutDuration: number;
	// % protected region % [Modify props to the crud options here for attribute 'Fade Out Duration'] end

	// % protected region % [Modify props to the crud options here for attribute 'Fade Volume'] off begin
	/**
	 * Fade Volume
	 */
	@Validators.Required()
	@Validators.Integer()
	@observable
	@attribute<AgencyEntity, number>()
	@CRUD({
		name: 'Fade Volume',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public fadeVolume: number;
	// % protected region % [Modify props to the crud options here for attribute 'Fade Volume'] end

	// % protected region % [Modify props to the crud options here for attribute 'Archived'] off begin
	@observable
	@attribute<AgencyEntity, boolean>()
	@CRUD({
		name: 'Archived',
		displayType: 'checkbox',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public archived: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Archived'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Agency Admins'] off begin
		name: 'Agency Adminss',
		displayType: 'reference-multicombobox',
		order: 110,
		referenceTypeFunc: () => Models.AgencyAdminEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'agencyAdminss',
			oppositeEntity: () => Models.AgencyAdminEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Agency Admins'] end
	})
	public agencyAdminss: Models.AgencyAdminEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Agency Playlisters'] off begin
		name: 'Agency Playlisterss',
		displayType: 'reference-multicombobox',
		order: 120,
		referenceTypeFunc: () => Models.AgencyPlaylisterEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'agencyPlaylisterss',
			oppositeEntity: () => Models.AgencyPlaylisterEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Agency Playlisters'] end
	})
	public agencyPlaylisterss: Models.AgencyPlaylisterEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Clients'] off begin
		name: 'Clientss',
		displayType: 'reference-multicombobox',
		order: 130,
		referenceTypeFunc: () => Models.ClientEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'clientss',
			oppositeEntity: () => Models.ClientEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Clients'] end
	})
	public clientss: Models.ClientEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IAgencyEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IAgencyEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.zoneLicences !== undefined) {
				this.zoneLicences = attributes.zoneLicences;
			}
			if (attributes.trackLicences !== undefined) {
				this.trackLicences = attributes.trackLicences;
			}
			if (attributes.primaryContactName !== undefined) {
				this.primaryContactName = attributes.primaryContactName;
			}
			if (attributes.primaryContactEmail !== undefined) {
				this.primaryContactEmail = attributes.primaryContactEmail;
			}
			if (attributes.primaryContactPhoneNumber !== undefined) {
				this.primaryContactPhoneNumber = attributes.primaryContactPhoneNumber;
			}
			if (attributes.fadeInDuration !== undefined) {
				this.fadeInDuration = attributes.fadeInDuration;
			}
			if (attributes.fadeOutDuration !== undefined) {
				this.fadeOutDuration = attributes.fadeOutDuration;
			}
			if (attributes.fadeVolume !== undefined) {
				this.fadeVolume = attributes.fadeVolume;
			}
			if (attributes.archived !== undefined) {
				this.archived = attributes.archived;
			}
			if (attributes.agencyAdminss !== undefined && Array.isArray(attributes.agencyAdminss)) {
				for (const model of attributes.agencyAdminss) {
					if (model instanceof Models.AgencyAdminEntity) {
						this.agencyAdminss.push(model);
					} else {
						this.agencyAdminss.push(new Models.AgencyAdminEntity(model));
					}
				}
			}
			if (attributes.agencyPlaylisterss !== undefined && Array.isArray(attributes.agencyPlaylisterss)) {
				for (const model of attributes.agencyPlaylisterss) {
					if (model instanceof Models.AgencyPlaylisterEntity) {
						this.agencyPlaylisterss.push(model);
					} else {
						this.agencyPlaylisterss.push(new Models.AgencyPlaylisterEntity(model));
					}
				}
			}
			if (attributes.clientss !== undefined && Array.isArray(attributes.clientss)) {
				for (const model of attributes.clientss) {
					if (model instanceof Models.ClientEntity) {
						this.clientss.push(model);
					} else {
						this.clientss.push(new Models.ClientEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		agencyAdminss {
			${Models.AgencyAdminEntity.getAttributes().join('\n')}
		}
		agencyPlaylisterss {
			${Models.AgencyPlaylisterEntity.getAttributes().join('\n')}
		}
		clientss {
			${Models.ClientEntity.getAttributes().join('\n')}
			${Models.ClientEntity.getFiles().map(f => f.name).join('\n')}
			venuess {
				${Models.VenueEntity.getAttributes().join('\n')}
				${Models.VenueEntity.getFiles().map(f => f.name).join('\n')}
				zoness {
					${Models.ZoneEntity.getAttributes().join('\n')}
					${Models.ZoneEntity.getFiles().map(f => f.name).join('\n')}
					schedule {
						${Models.ScheduleEntity.getAttributes().join('\n')}
					}
				}
			}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			agencyAdminss: {},
			agencyPlaylisterss: {},
			clientss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'clientss',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(AgencyEntity.prototype, 'created');
CRUD(modifiedAttr)(AgencyEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
