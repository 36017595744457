// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { action, runInAction } from 'mobx';
import { useQueryClient } from 'react-query';
import {
	ApplicationUserEntity, ManagedByManagesVenue, ManagedByManagesZone,
	VenueEntity,
	ZoneEntity,
} from '../../../../Models/Entities';
import { ComboboxOption } from '../../../Components/Combobox/Combobox';
import {
	fetchVenue,
	fetchVenues,
	fetchZone,
	fetchZones,
	fetchZonesByVenue,
	searchVenues,
} from '../../../../Util/ApolloQueries';
import { isEmail } from '../../../../Validators/Functions/Email';
import alertToast from '../../../../Util/ToastifyUtils';
import EditApplicationUserTileHeader from './EditApplicationUserTileComponents/EditApplicationUserTileHeader';
import EditApplicationUserTileUserDetails from './EditApplicationUserTileComponents/EditApplicationUserTileUserDetails';
import EditApplicationUserAccessControls from './EditApplicationUserTileComponents/EditApplicationUserAccessControls';
import { VenueMap, VenueRelationDetails } from './EditApplicationUserInterfaces';

const defaultApplicationUserDetails = {
	firstName: '',
	lastName: '',
	displayName: '',
	email: '',
	disableVolumeAdjustment: false,
	errors: {
		firstName: '',
		lastName: '',
		displayName: '',
		email: '',
	},
};

interface IEditApplicationUserTileProps {
	user: ApplicationUserEntity;
}

const EditApplicationUserTile = observer((props: IEditApplicationUserTileProps) => {
	const { user } = props;

	const queryClient = useQueryClient();

	const mode = useLocalStore(() => ({ edit: false }));

	const applicationUserDetails = useLocalStore(() => (
		defaultApplicationUserDetails
	));

	const managementAccess = useLocalStore(() => ({
		venues: [] as VenueMap<unknown>[],
		newVenue: false,
	}));

	const mapVenueToVenueStore = action((venue: VenueEntity, options: { addZones: boolean } = { addZones: true }) => {
		const venueAlreadyInStore = managementAccess.venues.filter(v => v.selectedVenue === venue.id).length > 0;

		if (venueAlreadyInStore) { return; }

		const newVenueMap = {
			venueOptions: [{ display: venue.name, value: venue.id }],
			selectedVenue: venue.id,
			venueRelationDetails: {
				zoneOptions: [
					{ display: 'All', value: 'all' },
					...venue.zoness.map((z: ZoneEntity) => ({ display: z.name, value: z.id })),
				],
				zoneRelations: [] as string[],
			},
			error: '',
		} as VenueMap<unknown>;
		if (options.addZones) {
			newVenueMap.venueRelationDetails.zoneRelations = ['all'];
		}
		managementAccess.venues = [...managementAccess.venues, newVenueMap];
	});

	const mapZoneToVenueStore = action((zone: ZoneEntity) => {
		const venueId = zone.venueId ?? '';
		const isVenueAlreadyInStore = managementAccess.venues.filter(v => v.selectedVenue === venueId).length > 0;
		if (!isVenueAlreadyInStore) {
			const newVenueMap = {
				venueOptions: [{ display: zone.venue.name, value: venueId }],
				selectedVenue: venueId,
				venueRelationDetails: {
					zoneOptions: [{ display: 'All', value: 'all' }, { display: zone.name, value: zone.id }],
					zoneRelations: [zone.id],
				},
				error: '',
			} as VenueMap<unknown>;
			managementAccess.venues = [...managementAccess.venues, newVenueMap];
			return;
		}

		const existingVenueMap = managementAccess.venues.filter(v => v.selectedVenue === venueId)[0];
		existingVenueMap.venueRelationDetails = {
			zoneOptions: [{ display: 'All', value: 'all' }, { display: zone.name, value: zone.id }],
			zoneRelations: [...existingVenueMap.venueRelationDetails.zoneRelations ?? [], zone.id],
		};
	});

	const addSiblingZonesToZoneOptions = (zoneList: ZoneEntity[], venueId: string) => {
		zoneList.forEach(zone => {
			const venue = managementAccess.venues.filter(v => v.selectedVenue === venueId)[0];
			if (!venue) { return; }

			const zoneOptionAlreadyExists = venue
				.venueRelationDetails
				.zoneOptions
				.filter(zo => zo.value === zone.id).length > 0;

			if (zoneOptionAlreadyExists) { return; }

			runInAction(() => {
				venue.venueRelationDetails.zoneOptions = [
					...venue.venueRelationDetails.zoneOptions,
					{ display: zone.name, value: zone.id },
				];
			});
		});
	};

	const fetchVenueAndMapToStore = async (venueId: string, options: { addZones: boolean } = { addZones: true }) => {
		const venue = await fetchVenue(venueId);
		mapVenueToVenueStore(venue, options);
	};

	const fetchVenuesAndMapToStore = async (venueIdList: string[], options: { addZones: boolean } = { addZones: true }) => {
		const venues = await fetchVenues(venueIdList);
		venues.forEach(v => mapVenueToVenueStore(v, options));
	};

	const fetchZonesAndMapToStore = async (zoneIdList: string[]) => {
		const zones = await fetchZones(zoneIdList);
		zones.forEach(async z => {
			mapZoneToVenueStore(z);

			addSiblingZonesToZoneOptions(z.venue.zoness, z.venueId ?? '');
		});
	};

	useEffect(() => {
		const updateUser = async () => {
			runInAction(() => {
				applicationUserDetails.firstName = user.firstName;
				applicationUserDetails.lastName = user.lastName;
				applicationUserDetails.displayName = user.displayName;
				applicationUserDetails.email = user.email;
				applicationUserDetails.disableVolumeAdjustment = user.disableVolumeAdjustment;
				applicationUserDetails.errors = defaultApplicationUserDetails.errors;
			});
		};

		updateUser();

		const fetchData = async () => {
			const venuePromises = [] as Promise<void>[];
			const managedVenueIdList = user.managesVenues.map(mv => mv.managesVenueId);

			if (managedVenueIdList.length > 0) {
				venuePromises.push(fetchVenuesAndMapToStore(managedVenueIdList));
			}

			await Promise.all(venuePromises);

			const zonePromises = [] as Promise<void>[];
			zonePromises.push(fetchZonesAndMapToStore(user.managesZones.map(mz => mz.managesZoneId)));

			await Promise.all(zonePromises);
		};
		fetchData();
	}, [applicationUserDetails, fetchVenuesAndMapToStore, fetchZonesAndMapToStore, user]);

	const enableEdit = () => runInAction(() => { mode.edit = !mode.edit; });

	const addVenue = action(() => {
		managementAccess.newVenue = true;
	});

	const removeAccess = action((venueId: string) => {
		managementAccess.venues = [...managementAccess.venues.filter(v => v.selectedVenue !== venueId)];
	});

	const searchVenuesAndMapToVenueOptions = async (searchTerm: string): Promise<ComboboxOption<unknown>[]> => {
		const venues = await searchVenues(searchTerm);
		return venues.map(v => ({ display: v.name, value: v.id }));
	};

	const fetchZonesAndMapToNewManagementAccess = async (venueId: string) => {
		runInAction(() => { managementAccess.newVenue = false; });
		await fetchVenueAndMapToStore(venueId, { addZones: false });
	};

	const validateField = action((field: string) => {
		if (applicationUserDetails[field] === '') {
			applicationUserDetails.errors[field] = 'Field cannot be empty';
		} else {
			applicationUserDetails.errors[field] = '';
		}

		if (field === 'email' && applicationUserDetails.email !== '' && !isEmail(applicationUserDetails.email)) {
			applicationUserDetails.errors.email = 'Invalid email';
		}
	});

	const validate = (): boolean => {
		Object.keys(applicationUserDetails).forEach(field => {
			if (field !== 'errors') { validateField(field); }
		});
		managementAccess.venues.forEach(venue => {
			runInAction(() => {
				if (venue.venueRelationDetails.zoneRelations.length === 0) {
					venue.error = 'Must have at least one zone selected';
				} else {
					venue.error = '';
				}
			});
		});

		const hasUserErrors = Object.values(applicationUserDetails.errors).filter(v => v !== '').length > 0;
		const hasAccessErrors = managementAccess.venues.filter(v => v.error !== '').length > 0;
		return hasUserErrors || hasAccessErrors;
	};

	const updateUserDetails = action(() => {
		Object.keys(applicationUserDetails).forEach(field => {
			if (field !== 'errors' && user[field] !== applicationUserDetails[field]) {
				user[field] = applicationUserDetails[field];
			}
		});
	});

	const isManagingVenue = (venue: VenueRelationDetails<unknown>) => venue?.zoneRelations?.includes('all');
	const managedZoneIds = () => managementAccess.venues
		.filter(venue => !isManagingVenue(venue.venueRelationDetails))
		.map(venue => venue.venueRelationDetails.zoneRelations);
	const wasManagingVenue = (venueId: string) => user.managesVenues.map(mv => mv.managesVenueId).includes(venueId);
	const wasManagingZone = (zoneId: string) => user.managesZones.map(mz => mz.managesZoneId).includes(zoneId);

	const getNewVenuesToManage = () => managementAccess.venues
		.filter(venue => isManagingVenue(venue.venueRelationDetails) && !wasManagingVenue(venue.selectedVenue))
		.map(venue => venue.selectedVenue);

	const getNewZonesToManage = () => Array.prototype
		.concat.apply([], managedZoneIds())
		.filter(zone => !wasManagingZone(zone));

	const getManagedVenuesToRemove = () => {
		const wasManagedVenuesIds = user.managesVenues.map(mv => mv.managesVenueId);
		const stillManagingVenuesIds = managementAccess.venues
			.filter(venue => isManagingVenue(venue.venueRelationDetails)).map(venue => venue.selectedVenue);
		return wasManagedVenuesIds.filter(venueId => !stillManagingVenuesIds.includes(venueId));
	};

	const getManagedZonesToRemove = () => {
		const wasManagedZonesIds = user.managesZones.map(mz => mz.managesZoneId);
		const stillManagingZoneIds = Array.prototype.concat.apply([], managedZoneIds());
		return wasManagedZonesIds.filter(zoneId => !stillManagingZoneIds.includes(zoneId));
	};

	const processAccessChanges = () => {
		const newVenueToManageIds = getNewVenuesToManage();
		const newZoneToManageIds = getNewZonesToManage();
		const managedVenueToRemove = getManagedVenuesToRemove();
		const managedZoneToRemove = getManagedZonesToRemove();

		return {
			newVenueToManageIds,
			newZoneToManageIds,
			managedVenueToRemove,
			managedZoneToRemove,
		};
	};

	const updateAccessChanges = action((accessChanges: {
		newVenueToManageIds: string[]
		newZoneToManageIds: string[]
		managedVenueToRemove: string[]
		managedZoneToRemove: string[]
	}) => {
		const relationUpdates = [] as Promise<void>[];

		accessChanges.newVenueToManageIds.forEach(venueId => {
			relationUpdates.push(new ManagedByManagesVenue({
				managesVenueId: venueId,
				managedById: user.id,
			}).save());
		});

		accessChanges.newZoneToManageIds.forEach(zoneId => {
			relationUpdates.push(new ManagedByManagesZone({
				managesZoneId: zoneId,
				managedById: user.id,
			}).save());
		});

		accessChanges.managedVenueToRemove.forEach(venueId => {
			relationUpdates.push(user.managesVenues.filter(mv => mv.managesVenueId === venueId)[0].delete());
		});

		accessChanges.managedZoneToRemove.forEach(zoneId => {
			relationUpdates.push(user.managesZones.filter(mz => mz.managesZoneId === zoneId)[0].delete());
		});

		return relationUpdates;
	});

	const [isSubmitting, setIsSubmitting] = useState(false);
	const submit = async (e: React.MouseEvent) => {
		if (isSubmitting) {
			return;
		}
		setIsSubmitting(true);
		e.preventDefault();
		const hasErrors = validate();

		if (hasErrors) {
			alertToast('Incorrect details provided', 'error');
			return;
		}

		updateUserDetails();

		const accessChanges = processAccessChanges();
		const relationUpdates = updateAccessChanges(accessChanges);

		user.save()
			.then(async () => {
				await Promise.all(relationUpdates);
			})
			.then(() => {
				queryClient.refetchQueries('application-user');
				alertToast('Saved User');
				runInAction(() => { mode.edit = false; });
			}).catch(() => {
				alertToast('Something went wrong', 'error');
			})
			.finally(() => {
				setIsSubmitting(false);
			});
	};
	return (
		<div className="application-user-edit-container">
			<EditApplicationUserTileHeader
				enableEdit={enableEdit}
			/>
			<h2 className="user-name">
				{mode.edit ? 'Edit user' : `${user.firstName} ${user.lastName}`}
			</h2>
			<EditApplicationUserTileUserDetails
				applicationUserDetails={applicationUserDetails}
				mode={mode}
			/>
			<div className="separator" />
			<h3>Management Access</h3>
			<EditApplicationUserAccessControls
				managementAccess={managementAccess}
				mode={mode}
				addVenue={addVenue}
				removeAccess={removeAccess}
				searchVenuesAndMapToVenueOptions={searchVenuesAndMapToVenueOptions}
				fetchZonesAndMapToNewManagementAccess={fetchZonesAndMapToNewManagementAccess}
				submit={submit}
				isSubmitting={isSubmitting}
			/>
		</div>
	);
});

export default EditApplicationUserTile;
