import React from 'react';
import { RouteComponentProps } from 'react-router';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import { PlaylistingTile } from './PlaylistingTile/PlaylistingTile';
import { store } from '../../../Models/Store';
import classNames from 'classnames';

export const PlaylistingPage = (props: RouteComponentProps) => {
	const navigationLinks = getFrontendNavLinks(props);
	const {
		history, location, match, staticContext,
	} = props;

	return (
		<SecuredPage>
			<Navigation
				linkGroups={navigationLinks}
				orientation={Orientation.VERTICAL}
				match={match}
				location={location}
				history={history}
				staticContext={staticContext}
			/>
			<div className={classNames('body-content', 'logged-in', store.hasBackendAccess && 'body-admin')}>
				<PlaylistingTile />
			</div>
		</SecuredPage>
	);
};
