/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { noop } from 'lodash';
import { action as mobxAction, observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Display } from 'Views/Components/Button/Button';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import If from 'Views/Components/If/If';
import classNames from 'classnames';
import { ICollectionHeaderProps } from './CollectionHeaders';
import { ICollectionBulkActionProps } from './Collection';
import CollectionFilterPanel, { ICollectionFilterPanelProps } from './CollectionFilterPanel';
import SearchForm from 'Views/Components/SearchForm/SearchForm';
// % protected region % [Add extra imports here] off begin
// % protected region % [Add extra imports here] end

interface ICollectionMenuProps<T> {
	selectedItems: T[];
	search?: boolean;
	filterConfig?: ICollectionFilterPanelProps<T>;
	headers?: Array<ICollectionHeaderProps<T>>;
	onSearchTriggered?: (searchTerm: string) => void;
	additionalActions?: React.ReactNode[];
	cancelAllSelection?: () => void;
	totalSelectedItems: number;
	selectedBulkActions?: Array<ICollectionBulkActionProps<T>>;
	showSelectAll?: boolean;
	onSelectAll?: () => void;
	filterOrientationRow?: boolean;
	totalRecords: number;
	// % protected region % [Add any extra ICollectionMenuProps fields here] on begin
	collectionTitle?: string;
	disableSearch?: boolean;
	disableFilter?: boolean;
	smallTitle?: boolean;
	// % protected region % [Add any extra ICollectionMenuProps fields here] end
}

interface ISearch {
	searchTerm: string;
}

@observer
class CollectionMenu<T> extends React.Component<ICollectionMenuProps<T>> {
	@observable
	private exportExpanded: boolean = false;

	@observable
	private search: ISearch = { searchTerm: '' };

	@observable
	private showFilter: boolean = false;

	// % protected region % [Add extra fields here] off begin
	// % protected region % [Add extra fields here] end

	// % protected region % [Override render method of CollectionMenu here] on begin
	public render() {
		// TODO: handle more buttons and button group rendering properly
		const {
			filterConfig,
			totalSelectedItems,
			selectedBulkActions,
			search,
			showSelectAll,
			filterOrientationRow,
			onSelectAll,
			cancelAllSelection,
			totalRecords,
			additionalActions = [],
			selectedItems,
			collectionTitle,
			disableSearch,
			disableFilter,
			smallTitle,
		} = this.props;

		// The action buttons
		let bulkActionsBtnGroup;
		if (totalSelectedItems && selectedBulkActions && selectedBulkActions.length) {
			const bulkActionButtons = selectedBulkActions.map((action, actIdx) => {
				const icon = action.showIcon && action.icon && action.iconPos
					? { icon: action.icon, iconPos: action.iconPos }
					: undefined;
				const key = `${actIdx}-${action.label}`;
				return (
					<Button
						key={key}
						className={action.buttonClass}
						icon={icon}
						buttonProps={{
							onClick: event => {
								action.bulkAction(selectedItems, event);
							},
						}}
					>
						{action.label}
					</Button>
				);
			});

			if (bulkActionButtons && bulkActionButtons.length) {
				bulkActionsBtnGroup = (
					<ButtonGroup className="collection__selection-actions" alignment={Alignment.HORIZONTAL}>
						{bulkActionButtons}
					</ButtonGroup>
				);
			}
		}

		const hasFilter = !!filterConfig && !!filterConfig.filters.length;

		let collectionTitleElement;
		if (collectionTitle && smallTitle) {
			collectionTitleElement = <h5>{collectionTitle}</h5>;
		} else if (collectionTitle) {
			collectionTitleElement = <h1>{collectionTitle}</h1>;
		} else {
			collectionTitleElement = <></>;
		}

		const selectOptionsClass = classNames('collection_select-options', totalSelectedItems === 0 ? 'hide' : null);

		return (
			<>
				{additionalActions.length && disableSearch && disableFilter ? (
					<section aria-label="collection menu" className="collection__menu flattened">
						{collectionTitleElement}
						{this.renderAdditionalActions()}
					</section>
				) : (
					<>
						{collectionTitleElement}
						<section aria-label="collection menu" className="collection__menu">
							<If condition={search}>
								<SearchForm
									model={this.search}
									onSubmit={this.onSearchButtonClick}
									label="A search for entities"
									classNameSuffix="collection"
								/>
							</If>
							<If condition={hasFilter || additionalActions.length > 0}>
								<section className="collection__actions">
									<If condition={hasFilter}>
										<Button
											display={Display.Solid}
											icon={{ icon: 'filter', iconPos: 'icon-top' }}
											onClick={mobxAction(() => {
												this.showFilter = !this.showFilter;
											})}
										>
											Filter
										</Button>
									</If>
									{this.renderAdditionalActions()}
								</section>
							</If>
						</section>
						<If condition={hasFilter && this.showFilter}>
							<section
								aria-label="collection filters"
								className={`collection__filters ${filterOrientationRow ? 'orientation_row' : ''}`}
							>
								<CollectionFilterPanel
									filters={filterConfig ? filterConfig.filters : []}
									onApplyFilter={filterConfig ? filterConfig.onApplyFilter : noop}
									onClearFilter={filterConfig ? filterConfig.onClearFilter : noop}
									onFilterChanged={(filterConfig && filterConfig.onFilterChanged)
										? filterConfig.onFilterChanged
										: noop}
								/>
							</section>
						</If>
						<If condition={totalSelectedItems !== 0}>
							<section
								aria-label="select options"
								className={selectOptionsClass}
							>
								{bulkActionsBtnGroup}
								<p className="crud__selection--count">
									<span className="selection-count">{totalSelectedItems}</span> items are selected
								</p>
								<If condition={showSelectAll}>
									<Button
										className="crud__selection--select-all"
										onClick={onSelectAll}
										display={Display.Text}
									>
										Select all {totalRecords} items
									</Button>
								</If>
								<Button className="crud__selection--cancel" onClick={cancelAllSelection}>Cancel</Button>
							</section>
						</If>
					</>
				)}
			</>
		);
	}
	// % protected region % [Override render method of CollectionMenu here] end

	// % protected region % [Override renderAdditionalActions method here] off begin
	private renderAdditionalActions(): React.ReactNode {
		const { additionalActions = [] } = this.props;
		if (additionalActions.length > 0) {
			return (
				<>
					{additionalActions}
				</>
			);
		}

		return null;
	}
	// % protected region % [Override renderAdditionalActions method here] end

	private onSearchButtonClick = () => {
		const { onSearchTriggered } = this.props;
		if (onSearchTriggered) {
			onSearchTriggered(this.search.searchTerm);
		}
	}

	// % protected region % [Add extra methods here] off begin
	// % protected region % [Add extra methods here] end
}

export default CollectionMenu;
