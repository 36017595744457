import React from 'react';

interface LineError {
	lineNumber: number;
	lineContents: string;
}

interface TrackErrorPanelProps {
	incorrectTracks: Array<LineError>;
}

const TrackValidationPanel = (props: TrackErrorPanelProps): JSX.Element => {
	const { incorrectTracks } = props;

	return (
		<div className="existing-track-panel">
			<div className="existing-track-info">
				{incorrectTracks.length}
				{' '}
				Failed Tracks
			</div>

			<div className="existing-track-warning">
				The following lines have not been formatted correctly and the tracks cannot be imported. Please review the below lines and manually add these tracks.
			</div>

			<div className="existing-track-container">
				{incorrectTracks.map(line => (
					<div className="existing-track-row">
						<div className="line-number">{line.lineNumber}</div>
						<div className="line-info">
							{line.lineContents}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default TrackValidationPanel;
