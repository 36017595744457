import React from 'react';
import { observer } from 'mobx-react';
import Axios from 'axios';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from '../../../Components/Button/Button';
import { store } from '../../../../Models/Store';
import { ScheduleEntity } from '../../../../Models/Entities';
import alertToast from '../../../../Util/ToastifyUtils';
import { SERVER_URL } from '../../../../Constants';

interface IDeleteScheduleModalProps {
	schedule: ScheduleEntity;
	resetScheduleState: (schedule: ScheduleEntity) => void;
}

const DeleteScheduleModal = observer((props: IDeleteScheduleModalProps) => {
	const { schedule, resetScheduleState } = props;

	const deleteSchedule = async () => {
		Axios.post(`${SERVER_URL}/api/entity/ScheduleEntity/RemoveSchedule/${schedule.id}`)
			.then(() => resetScheduleState(schedule))
			.then(() => alertToast('Deleted Schedule', 'success'))
			.then(() => store.modal.hide())
			.catch(() => alertToast('Something went wrong', 'error'));
	};

	return (
		<div>
			<h4>Delete Schedule</h4>
			<div className="modal-message">
				<p>
					Are you sure you want to delete
					{' '}
					{schedule.name}
					?
				</p>
				{schedule.zoness.length > 0 ? (
					<div>
						<p>{`${schedule.name} is currently assigned to the following zones`}</p>
						{schedule.zoness.map(z => <p>{` - ${z.name}`}</p>)}	
					</div>
				) : (
					<p>{`${schedule.name} is not currently assigned to the any zones`}</p>
				)}
				<p>Do you want to proceed?</p>
			</div>
			<div className="form-controls">
				<Button
					display={Display.Outline}
					colors={Colors.White}
					sizes={Sizes.Medium}
					className="cancel"
					onClick={() => store.modal.hide()}
				>
					Cancel
				</Button>
				<Button
					display={Display.Solid}
					colors={Colors.Primary}
					sizes={Sizes.Medium}
					onClick={deleteSchedule}
				>
					Confirm
				</Button>
			</div>
		</div>
	);
});

export default DeleteScheduleModal;
