/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import AgencyPlaylisterAlbumsReferenceManyToMany from '../Security/Acl/AgencyPlaylisterAlbumsReferenceManyToMany';
import MustardManagementAlbumsReferenceManyToMany from '../Security/Acl/MustardManagementAlbumsReferenceManyToMany';
import MustardAdminAlbumsReferenceManyToMany from '../Security/Acl/MustardAdminAlbumsReferenceManyToMany';
import AgencyAdminAlbumsReferenceManyToMany from '../Security/Acl/AgencyAdminAlbumsReferenceManyToMany';
import VisitorsAlbumsReferenceManyToMany from '../Security/Acl/VisitorsAlbumsReferenceManyToMany';
import ApplicationUserAlbumsReferenceManyToMany from '../Security/Acl/ApplicationUserAlbumsReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IArtistsAlbumsAttributes extends IModelAttributes {
	artistsId: string;
	albumsId: string;

	artists: Models.ArtistEntity | Models.IArtistEntityAttributes;
	albums: Models.AlbumEntity | Models.IAlbumEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('ArtistsAlbums')
export default class ArtistsAlbums
	extends Model
	implements IArtistsAlbumsAttributes {
	public static acls: IAcl[] = [
		new AgencyPlaylisterAlbumsReferenceManyToMany(),
		new MustardManagementAlbumsReferenceManyToMany(),
		new MustardAdminAlbumsReferenceManyToMany(),
		new AgencyAdminAlbumsReferenceManyToMany(),
		new VisitorsAlbumsReferenceManyToMany(),
		new ApplicationUserAlbumsReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public artistsId: string;

	@observable
	@attribute()
	public albumsId: string;

	@observable
	@attribute({ isReference: true })
	public artists: Models.ArtistEntity;

	@observable
	@attribute({ isReference: true })
	public albums: Models.AlbumEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IArtistsAlbumsAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.artistsId) {
				this.artistsId = attributes.artistsId;
			}
			if (attributes.albumsId) {
				this.albumsId = attributes.albumsId;
			}

			if (attributes.artists) {
				if (attributes.artists instanceof Models.ArtistEntity) {
					this.artists = attributes.artists;
					this.artistsId = attributes.artists.id;
				} else {
					this.artists = new Models.ArtistEntity(attributes.artists);
					this.artistsId = this.artists.id;
				}
			} else if (attributes.artistsId !== undefined) {
				this.artistsId = attributes.artistsId;
			}

			if (attributes.albums) {
				if (attributes.albums instanceof Models.AlbumEntity) {
					this.albums = attributes.albums;
					this.albumsId = attributes.albums.id;
				} else {
					this.albums = new Models.AlbumEntity(attributes.albums);
					this.albumsId = this.albums.id;
				}
			} else if (attributes.albumsId !== undefined) {
				this.albumsId = attributes.albumsId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
