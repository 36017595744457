/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AgencyAdminAnnouncementEntity from 'Models/Security/Acl/AgencyAdminAnnouncementEntity';
import VisitorsAnnouncementEntity from 'Models/Security/Acl/VisitorsAnnouncementEntity';
import ApplicationUserAnnouncementEntity from 'Models/Security/Acl/ApplicationUserAnnouncementEntity';
import AgencyPlaylisterAnnouncementEntity from 'Models/Security/Acl/AgencyPlaylisterAnnouncementEntity';
import MustardManagementAnnouncementEntity from 'Models/Security/Acl/MustardManagementAnnouncementEntity';
import MustardAdminAnnouncementEntity from 'Models/Security/Acl/MustardAdminAnnouncementEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { FileListPreview } from 'Views/Components/CRUD/Attributes/AttributeFile';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IAnnouncementEntityAttributes extends IModelAttributes {
	name: string;
	audioId: string;
	audio: Blob;
	trackID: string;

	announcementTimeslotss: Array<
		| Models.AnnouncementTimeslotEntity
		| Models.IAnnouncementTimeslotEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('AnnouncementEntity', 'Announcement')
// % protected region % [Customise your entity metadata here] end
export default class AnnouncementEntity extends Model
	implements IAnnouncementEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AgencyAdminAnnouncementEntity(),
		new VisitorsAnnouncementEntity(),
		new ApplicationUserAnnouncementEntity(),
		new AgencyPlaylisterAnnouncementEntity(),
		new MustardManagementAnnouncementEntity(),
		new MustardAdminAnnouncementEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@observable
	@attribute<AnnouncementEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Audio'] off begin
	@observable
	@attribute<AnnouncementEntity, string>({ file: 'audio' })
	@CRUD({
		name: 'Audio',
		displayType: 'file',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		fileAttribute: 'audio',
		displayFunction: attr => attr
			? <FileListPreview url={attr} />
			: 'No File Attached',
	})
	public audioId: string;

	@observable
	public audio: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Audio'] end

	// % protected region % [Modify props to the crud options here for attribute 'Track ID'] off begin
	@Validators.Uuid()
	@observable
	@attribute<AnnouncementEntity, string>()
	@CRUD({
		name: 'Track ID',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public trackID: string;
	// % protected region % [Modify props to the crud options here for attribute 'Track ID'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Announcement Timeslots'] off begin
		name: 'Announcement Timeslotss',
		displayType: 'reference-multicombobox',
		order: 40,
		referenceTypeFunc: () => Models.AnnouncementTimeslotEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'announcementTimeslotss',
			oppositeEntity: () => Models.AnnouncementTimeslotEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Announcement Timeslots'] end
	})
	public announcementTimeslotss: Models.AnnouncementTimeslotEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IAnnouncementEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IAnnouncementEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.audio !== undefined) {
				this.audio = attributes.audio;
			}
			if (attributes.audioId !== undefined) {
				this.audioId = attributes.audioId;
			}
			if (attributes.trackID !== undefined) {
				this.trackID = attributes.trackID;
			}
			if (attributes.announcementTimeslotss !== undefined && Array.isArray(attributes.announcementTimeslotss)) {
				for (const model of attributes.announcementTimeslotss) {
					if (model instanceof Models.AnnouncementTimeslotEntity) {
						this.announcementTimeslotss.push(model);
					} else {
						this.announcementTimeslotss.push(new Models.AnnouncementTimeslotEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		announcementTimeslotss {
			${Models.AnnouncementTimeslotEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			announcementTimeslotss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'announcementTimeslotss',
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(AnnouncementEntity.prototype, 'created');
CRUD(modifiedAttr)(AnnouncementEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
