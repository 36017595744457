/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AgencyPlaylisterTrackEntity from 'Models/Security/Acl/AgencyPlaylisterTrackEntity';
import ApplicationUserTrackEntity from 'Models/Security/Acl/ApplicationUserTrackEntity';
import VisitorsTrackEntity from 'Models/Security/Acl/VisitorsTrackEntity';
import MustardManagementTrackEntity from 'Models/Security/Acl/MustardManagementTrackEntity';
import MustardAdminTrackEntity from 'Models/Security/Acl/MustardAdminTrackEntity';
import AgencyAdminTrackEntity from 'Models/Security/Acl/AgencyAdminTrackEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { FileListPreview } from 'Views/Components/CRUD/Attributes/AttributeFile';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ITrackEntityAttributes extends IModelAttributes {
	title: string;
	audioId: string;
	audio: Blob;
	bpm: number;
	length: number;
	genre: string;
	discoTrackID: string;
	sorted: boolean;
	fadeIn: number;
	fadeOut: number;
	waveform: string;
	composer: string;
	isrc: string;
	productcataloguenumber: string;
	comment: string;
	archived: boolean;
	isValid: boolean;
	uploadErrorMessage: string;
	downloadStatus: Enums.downloadStatus;
	isExplicit: boolean;

	bankss: Array<
		| Models.BanksBankTracks
		| Models.IBanksBankTracksAttributes
	>;
	controlAuditss: Array<
		| Models.ControlAuditEntity
		| Models.IControlAuditEntityAttributes
	>;
	genress: Array<
		| Models.TracksGenres
		| Models.ITracksGenresAttributes
	>;
	moodTrackss: Array<
		| Models.MoodTrackEntity
		| Models.IMoodTrackEntityAttributes
	>;
	playlistTrackss: Array<
		| Models.PlaylistTrackEntity
		| Models.IPlaylistTrackEntityAttributes
	>;
	agencyTagss: Array<
		| Models.AgencyTagsTrackAgencyTags
		| Models.IAgencyTagsTrackAgencyTagsAttributes
	>;
	tagss: Array<
		| Models.TagsTrackTags
		| Models.ITagsTrackTagsAttributes
	>;
	albumId?: string;
	album?: Models.AlbumEntity | Models.IAlbumEntityAttributes;
	artistss: Array<
		| Models.ArtistsTracks
		| Models.IArtistsTracksAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('TrackEntity', 'Track')
// % protected region % [Customise your entity metadata here] end
export default class TrackEntity extends Model
	implements ITrackEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AgencyPlaylisterTrackEntity(),
		new ApplicationUserTrackEntity(),
		new VisitorsTrackEntity(),
		new MustardManagementTrackEntity(),
		new MustardAdminTrackEntity(),
		new AgencyAdminTrackEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Title'] off begin
	@observable
	@attribute<TrackEntity, string>()
	@CRUD({
		name: 'Title',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public title: string;
	// % protected region % [Modify props to the crud options here for attribute 'Title'] end

	// % protected region % [Modify props to the crud options here for attribute 'Audio'] off begin
	@observable
	@attribute<TrackEntity, string>({ file: 'audio' })
	@CRUD({
		name: 'Audio',
		displayType: 'file',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		fileAttribute: 'audio',
		displayFunction: attr => attr
			? <FileListPreview url={attr} />
			: 'No File Attached',
	})
	public audioId: string;

	@observable
	public audio: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Audio'] end

	// % protected region % [Modify props to the crud options here for attribute 'BPM'] off begin
	@Validators.Integer()
	@observable
	@attribute<TrackEntity, number>()
	@CRUD({
		name: 'BPM',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public bpm: number;
	// % protected region % [Modify props to the crud options here for attribute 'BPM'] end

	// % protected region % [Modify props to the crud options here for attribute 'Length'] off begin
	@Validators.Integer()
	@observable
	@attribute<TrackEntity, number>()
	@CRUD({
		name: 'Length',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public length: number;
	// % protected region % [Modify props to the crud options here for attribute 'Length'] end

	// % protected region % [Modify props to the crud options here for attribute 'Genre'] on begin
	@observable
	@attribute()
	// Hide the genre field as it has been superceded by the genre tag
	public genre: string;
	// % protected region % [Modify props to the crud options here for attribute 'Genre'] end

	// % protected region % [Modify props to the crud options here for attribute 'Disco Track ID'] off begin
	/**
	 * Disco Track ID
	 */
	@Validators.Unique()
	@observable
	@attribute<TrackEntity, string>()
	@CRUD({
		name: 'Disco Track ID',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public discoTrackID: string;
	// % protected region % [Modify props to the crud options here for attribute 'Disco Track ID'] end

	// % protected region % [Modify props to the crud options here for attribute 'Sorted'] off begin
	@observable
	@attribute<TrackEntity, boolean>()
	@CRUD({
		name: 'Sorted',
		displayType: 'checkbox',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public sorted: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Sorted'] end

	// % protected region % [Modify props to the crud options here for attribute 'Fade In'] off begin
	@Validators.Numeric()
	@observable
	@attribute<TrackEntity, number>()
	@CRUD({
		name: 'Fade In',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public fadeIn: number;
	// % protected region % [Modify props to the crud options here for attribute 'Fade In'] end

	// % protected region % [Modify props to the crud options here for attribute 'Fade Out'] off begin
	@Validators.Numeric()
	@observable
	@attribute<TrackEntity, number>()
	@CRUD({
		name: 'Fade Out',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public fadeOut: number;
	// % protected region % [Modify props to the crud options here for attribute 'Fade Out'] end

	// % protected region % [Modify props to the crud options here for attribute 'Waveform'] off begin
	@observable
	@attribute<TrackEntity, string>()
	@CRUD({
		name: 'Waveform',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public waveform: string;
	// % protected region % [Modify props to the crud options here for attribute 'Waveform'] end

	// % protected region % [Modify props to the crud options here for attribute 'Composer'] off begin
	@observable
	@attribute<TrackEntity, string>()
	@CRUD({
		name: 'Composer',
		displayType: 'textfield',
		order: 110,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public composer: string;
	// % protected region % [Modify props to the crud options here for attribute 'Composer'] end

	// % protected region % [Modify props to the crud options here for attribute 'ISRC'] off begin
	@observable
	@attribute<TrackEntity, string>()
	@CRUD({
		name: 'ISRC',
		displayType: 'textfield',
		order: 120,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public isrc: string;
	// % protected region % [Modify props to the crud options here for attribute 'ISRC'] end

	// % protected region % [Modify props to the crud options here for attribute 'ProductCatalogueNumber'] off begin
	@observable
	@attribute<TrackEntity, string>()
	@CRUD({
		name: 'ProductCatalogueNumber',
		displayType: 'textfield',
		order: 130,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public productcataloguenumber: string;
	// % protected region % [Modify props to the crud options here for attribute 'ProductCatalogueNumber'] end

	// % protected region % [Modify props to the crud options here for attribute 'Comment'] off begin
	@observable
	@attribute<TrackEntity, string>()
	@CRUD({
		name: 'Comment',
		displayType: 'textfield',
		order: 140,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public comment: string;
	// % protected region % [Modify props to the crud options here for attribute 'Comment'] end

	// % protected region % [Modify props to the crud options here for attribute 'Archived'] off begin
	@observable
	@attribute<TrackEntity, boolean>()
	@CRUD({
		name: 'Archived',
		displayType: 'checkbox',
		order: 150,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public archived: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Archived'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Valid'] off begin
	/**
	 * Defines if TrackEntity has all required fields
	 */
	@Validators.Required()
	@observable
	@attribute<TrackEntity, boolean>()
	@CRUD({
		name: 'Is Valid',
		displayType: 'checkbox',
		order: 160,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isValid: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Is Valid'] end

	// % protected region % [Modify props to the crud options here for attribute 'Upload Error Message'] off begin
	/**
	 * Displays error or exception on failed upload
	 */
	@observable
	@attribute<TrackEntity, string>()
	@CRUD({
		name: 'Upload Error Message',
		displayType: 'textfield',
		order: 170,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public uploadErrorMessage: string;
	// % protected region % [Modify props to the crud options here for attribute 'Upload Error Message'] end

	// % protected region % [Modify props to the crud options here for attribute 'Download Status'] off begin
	@Validators.Required()
	@observable
	@attribute<TrackEntity, Enums.downloadStatus>()
	@CRUD({
		name: 'Download Status',
		displayType: 'enum-combobox',
		order: 180,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.downloadStatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.downloadStatusOptions),
		displayFunction: (attr: Enums.downloadStatus) => Enums.downloadStatusOptions[attr],
	})
	public downloadStatus: Enums.downloadStatus;
	// % protected region % [Modify props to the crud options here for attribute 'Download Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Explicit'] off begin
	@observable
	@attribute<TrackEntity, boolean>()
	@CRUD({
		name: 'Is Explicit',
		displayType: 'checkbox',
		order: 190,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isExplicit: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Explicit'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Banks'] off begin
		name: 'Banks',
		displayType: 'reference-multicombobox',
		order: 200,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.BanksBankTracks,
		optionEqualFunc: makeJoinEqualsFunc('banksId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'trackEntity',
			oppositeEntityName: 'bankEntity',
			relationName: 'bankTracks',
			relationOppositeName: 'banks',
			entity: () => Models.TrackEntity,
			joinEntity: () => Models.BanksBankTracks,
			oppositeEntity: () => Models.BankEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Banks'] end
	})
	public bankss: Models.BanksBankTracks[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Control Audits'] off begin
		name: 'Control Auditss',
		displayType: 'reference-multicombobox',
		order: 210,
		referenceTypeFunc: () => Models.ControlAuditEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'controlAuditss',
			oppositeEntity: () => Models.ControlAuditEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Control Audits'] end
	})
	public controlAuditss: Models.ControlAuditEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Genres'] off begin
		name: 'Genres',
		displayType: 'reference-multicombobox',
		order: 220,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.TracksGenres,
		optionEqualFunc: makeJoinEqualsFunc('genresId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'trackEntity',
			oppositeEntityName: 'genreEntity',
			relationName: 'tracks',
			relationOppositeName: 'genres',
			entity: () => Models.TrackEntity,
			joinEntity: () => Models.TracksGenres,
			oppositeEntity: () => Models.GenreEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Genres'] end
	})
	public genress: Models.TracksGenres[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Mood Tracks'] off begin
		name: 'Mood Trackss',
		displayType: 'reference-multicombobox',
		order: 230,
		referenceTypeFunc: () => Models.MoodTrackEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'moodTrackss',
			oppositeEntity: () => Models.MoodTrackEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Mood Tracks'] end
	})
	public moodTrackss: Models.MoodTrackEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Playlist Tracks'] off begin
		name: 'Playlist Trackss',
		displayType: 'reference-multicombobox',
		order: 240,
		referenceTypeFunc: () => Models.PlaylistTrackEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'playlistTrackss',
			oppositeEntity: () => Models.PlaylistTrackEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Playlist Tracks'] end
	})
	public playlistTrackss: Models.PlaylistTrackEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Agency Tags'] off begin
		name: 'Agency Tags',
		displayType: 'reference-multicombobox',
		order: 250,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.AgencyTagsTrackAgencyTags,
		optionEqualFunc: makeJoinEqualsFunc('agencyTagsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'trackEntity',
			oppositeEntityName: 'agencyTagEntity',
			relationName: 'trackAgencyTags',
			relationOppositeName: 'agencyTags',
			entity: () => Models.TrackEntity,
			joinEntity: () => Models.AgencyTagsTrackAgencyTags,
			oppositeEntity: () => Models.AgencyTagEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Agency Tags'] end
	})
	public agencyTagss: Models.AgencyTagsTrackAgencyTags[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Tags'] off begin
		name: 'Tags',
		displayType: 'reference-multicombobox',
		order: 260,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.TagsTrackTags,
		optionEqualFunc: makeJoinEqualsFunc('tagsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'trackEntity',
			oppositeEntityName: 'tagEntity',
			relationName: 'trackTags',
			relationOppositeName: 'tags',
			entity: () => Models.TrackEntity,
			joinEntity: () => Models.TagsTrackTags,
			oppositeEntity: () => Models.TagEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Tags'] end
	})
	public tagss: Models.TagsTrackTags[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Album'] off begin
		name: 'Album',
		displayType: 'reference-combobox',
		order: 270,
		referenceTypeFunc: () => Models.AlbumEntity,
		// % protected region % [Modify props to the crud options here for reference 'Album'] end
	})
	public albumId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public album: Models.AlbumEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Artists'] off begin
		name: 'Artists',
		displayType: 'reference-multicombobox',
		order: 280,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.ArtistsTracks,
		optionEqualFunc: makeJoinEqualsFunc('artistsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'trackEntity',
			oppositeEntityName: 'artistEntity',
			relationName: 'tracks',
			relationOppositeName: 'artists',
			entity: () => Models.TrackEntity,
			joinEntity: () => Models.ArtistsTracks,
			oppositeEntity: () => Models.ArtistEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Artists'] end
	})
	public artistss: Models.ArtistsTracks[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ITrackEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ITrackEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.title !== undefined) {
				this.title = attributes.title;
			}
			if (attributes.audio !== undefined) {
				this.audio = attributes.audio;
			}
			if (attributes.audioId !== undefined) {
				this.audioId = attributes.audioId;
			}
			if (attributes.bpm !== undefined) {
				this.bpm = attributes.bpm;
			}
			if (attributes.length !== undefined) {
				this.length = attributes.length;
			}
			if (attributes.genre !== undefined) {
				this.genre = attributes.genre;
			}
			if (attributes.discoTrackID !== undefined) {
				this.discoTrackID = attributes.discoTrackID;
			}
			if (attributes.sorted !== undefined) {
				this.sorted = attributes.sorted;
			}
			if (attributes.fadeIn !== undefined) {
				this.fadeIn = attributes.fadeIn;
			}
			if (attributes.fadeOut !== undefined) {
				this.fadeOut = attributes.fadeOut;
			}
			if (attributes.waveform !== undefined) {
				this.waveform = attributes.waveform;
			}
			if (attributes.composer !== undefined) {
				this.composer = attributes.composer;
			}
			if (attributes.isrc !== undefined) {
				this.isrc = attributes.isrc;
			}
			if (attributes.productcataloguenumber !== undefined) {
				this.productcataloguenumber = attributes.productcataloguenumber;
			}
			if (attributes.comment !== undefined) {
				this.comment = attributes.comment;
			}
			if (attributes.archived !== undefined) {
				this.archived = attributes.archived;
			}
			if (attributes.isValid !== undefined) {
				this.isValid = attributes.isValid;
			}
			if (attributes.uploadErrorMessage !== undefined) {
				this.uploadErrorMessage = attributes.uploadErrorMessage;
			}
			if (attributes.downloadStatus !== undefined) {
				this.downloadStatus = attributes.downloadStatus;
			}
			if (attributes.isExplicit !== undefined) {
				this.isExplicit = attributes.isExplicit;
			}
			if (attributes.bankss !== undefined && Array.isArray(attributes.bankss)) {
				for (const model of attributes.bankss) {
					if (model instanceof Models.BanksBankTracks) {
						this.bankss.push(model);
					} else {
						this.bankss.push(new Models.BanksBankTracks(model));
					}
				}
			}
			if (attributes.controlAuditss !== undefined && Array.isArray(attributes.controlAuditss)) {
				for (const model of attributes.controlAuditss) {
					if (model instanceof Models.ControlAuditEntity) {
						this.controlAuditss.push(model);
					} else {
						this.controlAuditss.push(new Models.ControlAuditEntity(model));
					}
				}
			}
			if (attributes.genress !== undefined && Array.isArray(attributes.genress)) {
				for (const model of attributes.genress) {
					if (model instanceof Models.TracksGenres) {
						this.genress.push(model);
					} else {
						this.genress.push(new Models.TracksGenres(model));
					}
				}
			}
			if (attributes.moodTrackss !== undefined && Array.isArray(attributes.moodTrackss)) {
				for (const model of attributes.moodTrackss) {
					if (model instanceof Models.MoodTrackEntity) {
						this.moodTrackss.push(model);
					} else {
						this.moodTrackss.push(new Models.MoodTrackEntity(model));
					}
				}
			}
			if (attributes.playlistTrackss !== undefined && Array.isArray(attributes.playlistTrackss)) {
				for (const model of attributes.playlistTrackss) {
					if (model instanceof Models.PlaylistTrackEntity) {
						this.playlistTrackss.push(model);
					} else {
						this.playlistTrackss.push(new Models.PlaylistTrackEntity(model));
					}
				}
			}
			if (attributes.agencyTagss !== undefined && Array.isArray(attributes.agencyTagss)) {
				for (const model of attributes.agencyTagss) {
					if (model instanceof Models.AgencyTagsTrackAgencyTags) {
						this.agencyTagss.push(model);
					} else {
						this.agencyTagss.push(new Models.AgencyTagsTrackAgencyTags(model));
					}
				}
			}
			if (attributes.tagss !== undefined && Array.isArray(attributes.tagss)) {
				for (const model of attributes.tagss) {
					if (model instanceof Models.TagsTrackTags) {
						this.tagss.push(model);
					} else {
						this.tagss.push(new Models.TagsTrackTags(model));
					}
				}
			}
			if (attributes.albumId !== undefined) {
				this.albumId = attributes.albumId;
			}
			if (attributes.album !== undefined) {
				if (attributes.album === null) {
					this.album = attributes.album;
				} else if (attributes.album instanceof Models.AlbumEntity) {
					this.album = attributes.album;
					this.albumId = attributes.album.id;
				} else {
					this.album = new Models.AlbumEntity(attributes.album);
					this.albumId = this.album.id;
				}
			}
			if (attributes.artistss !== undefined && Array.isArray(attributes.artistss)) {
				for (const model of attributes.artistss) {
					if (model instanceof Models.ArtistsTracks) {
						this.artistss.push(model);
					} else {
						this.artistss.push(new Models.ArtistsTracks(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		genress {
			${Models.TracksGenres.getAttributes().join('\n')}
			genres {
				${Models.GenreEntity.getAttributes().join('\n')}
			}
		}
		bankss {
			${Models.BanksBankTracks.getAttributes().join('\n')}
			banks {
				${Models.BankEntity.getAttributes().join('\n')}
			}
		}
		agencyTagss {
			${Models.AgencyTagsTrackAgencyTags.getAttributes().join('\n')}
			agencyTags {
				${Models.AgencyTagEntity.getAttributes().join('\n')}
			}
		}
		tagss {
			${Models.TagsTrackTags.getAttributes().join('\n')}
			tags {
				${Models.TagEntity.getAttributes().join('\n')}
			}
		}
		artistss {
			${Models.ArtistsTracks.getAttributes().join('\n')}
			artists {
				${Models.ArtistEntity.getAttributes().join('\n')}
				${Models.ArtistEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
		controlAuditss {
			${Models.ControlAuditEntity.getAttributes().join('\n')}
		}
		moodTrackss {
			${Models.MoodTrackEntity.getAttributes().join('\n')}
		}
		playlistTrackss {
			${Models.PlaylistTrackEntity.getAttributes().join('\n')}
		}
		album {
			${Models.AlbumEntity.getAttributes().join('\n')}
			${Models.AlbumEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			genress: {},
			bankss: {},
			agencyTagss: {},
			tagss: {},
			artistss: {},
			controlAuditss: {},
			moodTrackss: {},
			playlistTrackss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'bankss',
							'controlAuditss',
							'genress',
							'playlistTrackss',
							'agencyTagss',
							'tagss',
							'artistss',
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.title;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(TrackEntity.prototype, 'created');
CRUD(modifiedAttr)(TrackEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
