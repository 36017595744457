import React from 'react';
import { RouteComponentProps } from 'react-router';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import { store } from '../../../../Models/Store';
import EditApplicationUserTile from './EditApplicationUserTile';
import { getFetchSingleQuery } from '../../../../Util/EntityUtils';
import { ApplicationUserEntity } from '../../../../Models/Entities';

interface IEditApplicationUserPage extends RouteComponentProps {
	match: {
		params: {
			applicationUserId?: string;
		};
		isExact: boolean;
		path: string;
		url: string;
	};
}

export const EditApplicationUserPage = (props: IEditApplicationUserPage) => {
	const navigationLinks = getFrontendNavLinks(props);
	const {
		history, location, match, staticContext,
	} = props;
	
	const { match: { params: { applicationUserId } } } = props;
	
	if (applicationUserId === null) {
		return <p>No User Id Provided</p>;
	}
	
	const fetchUser = async () => {
		const res = await store.apolloClient.query(
			{
				query: getFetchSingleQuery(ApplicationUserEntity, new ApplicationUserEntity().defaultExpands, true),
				fetchPolicy: 'network-only',
				variables: {
					args: [
						{
							path: 'id',
							comparison: 'equal',
							value: applicationUserId,
						},
					],
				},
			},
		);
		return new ApplicationUserEntity(res.data.applicationUserEntity);
	};
	
	const {
		isLoading,
		data: user,
		isError,
	} = useQuery(['application-user', applicationUserId], fetchUser, { refetchOnWindowFocus: false });

	if (isLoading) {
		return <div style={{width: "100vw", height: "100vh", backgroundColor: "rgb(18, 18, 18)"}}>
			<p>Loading...</p>
		</div>
	}
	
	if (isError) {
		return <p>Error...</p>;
	}
	
	if (!user) {
		return <p>User Not Found</p>;
	}
	
	return (
		<SecuredPage>
			<Navigation
				linkGroups={navigationLinks}
				orientation={Orientation.VERTICAL}
				match={match}
				location={location}
				history={history}
				staticContext={staticContext}
			/>
			<div className={classNames('body-content', 'logged-in', store.hasBackendAccess && 'body-admin')}>
				<EditApplicationUserTile
					user={user}
				/>
			</div>
		</SecuredPage>
	);
};
