import React, { useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { runInAction } from 'mobx';
import { useApolloClient } from '@apollo/client';
import {
	ApplicationUserEntity,
	ManagedByManagesZone,
	VenueEntity,
	ZoneEntity,
} from '../../../Models/Entities';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from '../../Components/Button/Button';
import { store } from '../../../Models/Store';
import { getFetchAllConditional } from '../../../Util/EntityUtils';
import { MultiCombobox } from '../../Components/Combobox/MultiCombobox';
import { Combobox } from '../../Components/Combobox/Combobox';
import alertToast from '../../../Util/ToastifyUtils';

interface IUserModalProps {
	// eslint-disable-next-line react/require-default-props
	venue: VenueEntity;
	refetchClient: () => void;
	user?: ApplicationUserEntity;
}

interface IUserForm {
	user: string;
	zones: string[];
	errors: {
		user: string;
		zones: string;
	};
}

const defaultUserForm: IUserForm = {
	user: '',
	zones: [],
	errors: {
		user: '',
		zones: '',
	},
};

const UserModal = (props: IUserModalProps): JSX.Element => {
	const {
		venue,
		user,
		refetchClient,
	} = props;

	const userStore = useLocalStore(() => ({
		zones: [] as ZoneEntity[],
		users: [] as ApplicationUserEntity[],
	}));

	const apollo = useApolloClient();

	useEffect(() => {
		const orderBy = [
			{ path: 'name', descending: false },
		];

		const applicationUserEntityOrderBy = [
			{ path: 'displayName', descending: false },
		];
		
		const fetchData = async () => {
			await apollo.query({
				query: getFetchAllConditional(ZoneEntity, '', {
					expandType: 'list',
					removeCount: true,
				}),
				fetchPolicy: 'network-only',
				variables: { args: [[{ path: 'venueId', comparison: 'equal', value: venue.id }]], orderBy },
			}).then((res: any) => {
				runInAction(() => userStore.zones = res.data.zoneEntitys.map((z: ZoneEntity) => new ZoneEntity(z)));
			}).catch(err => console.log('Error fetching zones', err));

			await apollo.query({
				query: getFetchAllConditional(ApplicationUserEntity, 'managesZones { id, managesZoneId }', {
					expandType: 'list',
					removeCount: true,
				}),
				fetchPolicy: 'network-only',
				variables: { args: [], applicationUserEntityOrderBy },
			}).then((res: any) => {
				runInAction(() => userStore.users = res.data.applicationUserEntitys.map((u: ApplicationUserEntity) => new ApplicationUserEntity(u)));
			}).catch(err => console.log('Error fetching zones', err));
		};

		fetchData();
	}, []);

	const userForm = useLocalStore(() => (user?.id ? {
		user: user.id,
		zones: user.managesZones.map(mz => mz.managesZoneId),
		errors: {
			user: '',
			zones: '',
		},
	} : defaultUserForm));

	const onSubmit = async (e: React.SyntheticEvent) => {
		e.preventDefault();

		// Get from store
		const userToSave = user !== undefined
			? user
			: userStore.users.filter(u => u.id === userForm.user)[0];

		// Get list of Zone Ids to be removed
		const zonesToRemove = userToSave.managesZones
			.filter(mz => userForm.zones.includes(mz.managesZoneId))
			.map(mz => mz.managesZoneId);

		// Get list of zones to be added
		let zonesToAdd = userStore.zones
			.filter(z => userForm.zones.includes(z.id))
			.map(z => new ManagedByManagesZone({
				managesZoneId: z.id,
				managedById: userToSave.id,
			}));

		// Get list of zones ids to be removed
		const currentVenueZonesToRemove = userStore.zones
			.filter(z => z.venueId == venue.id && !userForm.zones.includes(z.id))
			.map(z => z.id);

		// Get managed zones to be deleted
		const managedZonesToDelete = userToSave.managesZones
			.filter(mz => currentVenueZonesToRemove.includes(mz.managesZoneId));

		// Only add zones which are not already managed
		zonesToAdd = zonesToAdd.filter(z => !userToSave.managesZones.map(mz => mz.managesZoneId).includes(z.managesZoneId));

		userToSave.managesZones = [...userToSave.managesZones.filter(mz => !zonesToRemove.includes(mz.managesZoneId)), ...zonesToAdd];

		userToSave.save({ managesVenues: {}, managesZones: {} })
			.then(() => {
				managedZonesToDelete.forEach(mz => {
					mz.delete();
				});

				alertToast('Saved User', 'success');
				refetchClient();
			})
			.catch(() => {
				alertToast('Error Saving User', 'error');
			});

		store.modal.hide();
	};

	return (
		<div className="user-modal-container">
			<form onSubmit={onSubmit}>
				<Combobox
					model={userForm}
					modelProperty="user"
					label="Email"
					className="email"
					options={userStore.users.map(u => ({ display: u.email, value: u.id }))}
					errors={userForm.errors.user}
					onAfterChange={() => {
						runInAction(() => {
							userForm.zones = userStore.zones
								.filter(z => userStore.users
									.filter(u => u.id === userForm.user)[0].managesZones
										.map(mz => mz.managesZoneId)
										.includes(z.id))
								.map(z => z.id);
						});
					}}
					isDisabled={user !== undefined}
				/>
				<MultiCombobox
					model={userForm}
					modelProperty="zones"
					label="Zones"
					options={userStore.zones.map(z => ({ display: z.name, value: z.id }))}
					errors={userForm.errors.zones !== undefined ? userForm.errors.zones : ''}
				/>
				<div className="form-controls">
					<Button type="button" colors={Colors.Primary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }} onClick={() => store.modal.hide()}>
						Cancel
					</Button>
					<Button type="submit" colors={Colors.Primary} display={Display.Solid} sizes={Sizes.Medium} buttonProps={{ id: 'submit' }}>
						Save User
					</Button>
				</div>
			</form>
		</div>
	);
};

export default observer(UserModal);
