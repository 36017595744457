/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { SERVER_URL } from 'Constants';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AgencyPlaylisterVenueEntity from 'Models/Security/Acl/AgencyPlaylisterVenueEntity';
import AgencyAdminVenueEntity from 'Models/Security/Acl/AgencyAdminVenueEntity';
import MustardManagementVenueEntity from 'Models/Security/Acl/MustardManagementVenueEntity';
import MustardAdminVenueEntity from 'Models/Security/Acl/MustardAdminVenueEntity';
import VisitorsVenueEntity from 'Models/Security/Acl/VisitorsVenueEntity';
import ApplicationUserVenueEntity from 'Models/Security/Acl/ApplicationUserVenueEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IVenueEntityAttributes extends IModelAttributes {
	name: string;
	logoId: string;
	logo: Blob;
	isActive: boolean;
	facebookURL: string;
	instagramURL: string;
	websiteURL: string;
	spotifyRadioURL: string;
	agencyOwnerId: string;
	streetAddressLineOne: string;
	streetAddressLineTwo: string;
	city: string;
	state: string;
	postCode: string;
	latitude: number;
	longitude: number;
	archived: boolean;

	contactss: Array<
		| Models.ContactEntity
		| Models.IContactEntityAttributes
	>;
	managedBys: Array<
		| Models.ManagedByManagesVenue
		| Models.IManagedByManagesVenueAttributes
	>;
	clientId?: string;
	client?: Models.ClientEntity | Models.IClientEntityAttributes;
	countryId?: string;
	country?: Models.CountryEntity | Models.ICountryEntityAttributes;
	regionId?: string;
	region?: Models.RegionEntity | Models.IRegionEntityAttributes;
	venueTypeId?: string;
	venueType?: Models.VenueTypeEntity | Models.IVenueTypeEntityAttributes;
	zoness: Array<
		| Models.ZoneEntity
		| Models.IZoneEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('VenueEntity', 'Venue')
// % protected region % [Customise your entity metadata here] end
export default class VenueEntity extends Model
	implements IVenueEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AgencyPlaylisterVenueEntity(),
		new AgencyAdminVenueEntity(),
		new MustardManagementVenueEntity(),
		new MustardAdminVenueEntity(),
		new VisitorsVenueEntity(),
		new ApplicationUserVenueEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'name',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@Validators.Required()
	@observable
	@attribute<VenueEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Logo'] on begin
	@observable
	@attribute({ file: 'logo' })
	@CRUD({
		name: 'Logo',
		displayType: 'file',
		order: 20,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'logo',
		displayFunction: attr => (attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Venue" style={{ maxWidth: '300px' }} />
			: 'No File Attached'),
	})
	public logoId: string;

	@observable
	public logo: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Logo'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Active'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Is Active',
		displayType: 'checkbox',
		order: 40,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public isActive: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Active'] end

	// % protected region % [Modify props to the crud options here for attribute 'Facebook URL'] on begin
	@Validators.Url()
	@observable
	@attribute()
	@CRUD({
		name: 'Facebook URL',
		displayType: 'textfield',
		order: 50,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public facebookURL: string;
	// % protected region % [Modify props to the crud options here for attribute 'Facebook URL'] end

	// % protected region % [Modify props to the crud options here for attribute 'Instagram URL'] off begin
	@Validators.Url()
	@observable
	@attribute<VenueEntity, string>()
	@CRUD({
		name: 'Instagram URL',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public instagramURL: string;
	// % protected region % [Modify props to the crud options here for attribute 'Instagram URL'] end

	// % protected region % [Modify props to the crud options here for attribute 'Website URL'] off begin
	@Validators.Url()
	@observable
	@attribute<VenueEntity, string>()
	@CRUD({
		name: 'Website URL',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public websiteURL: string;
	// % protected region % [Modify props to the crud options here for attribute 'Website URL'] end

	// % protected region % [Modify props to the crud options here for attribute 'Spotify Radio URL'] off begin
	/**
	 * Spotify Radio URL
	 */
	@Validators.Url()
	@observable
	@attribute<VenueEntity, string>()
	@CRUD({
		name: 'Spotify Radio URL',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public spotifyRadioURL: string;
	// % protected region % [Modify props to the crud options here for attribute 'Spotify Radio URL'] end

	// % protected region % [Modify props to the crud options here for attribute 'Agency Owner Id'] off begin
	@Validators.Uuid()
	@observable
	@attribute<VenueEntity, string>()
	@CRUD({
		name: 'Agency Owner Id',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public agencyOwnerId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Agency Owner Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Street Address Line One'] off begin
	@observable
	@attribute<VenueEntity, string>()
	@CRUD({
		name: 'Street Address Line One',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public streetAddressLineOne: string;
	// % protected region % [Modify props to the crud options here for attribute 'Street Address Line One'] end

	// % protected region % [Modify props to the crud options here for attribute 'Street Address Line Two'] off begin
	@observable
	@attribute<VenueEntity, string>()
	@CRUD({
		name: 'Street Address Line Two',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public streetAddressLineTwo: string;
	// % protected region % [Modify props to the crud options here for attribute 'Street Address Line Two'] end

	// % protected region % [Modify props to the crud options here for attribute 'City'] off begin
	@observable
	@attribute<VenueEntity, string>()
	@CRUD({
		name: 'City',
		displayType: 'textfield',
		order: 110,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public city: string;
	// % protected region % [Modify props to the crud options here for attribute 'City'] end

	// % protected region % [Modify props to the crud options here for attribute 'State'] off begin
	@observable
	@attribute<VenueEntity, string>()
	@CRUD({
		name: 'State',
		displayType: 'textfield',
		order: 120,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public state: string;
	// % protected region % [Modify props to the crud options here for attribute 'State'] end

	// % protected region % [Modify props to the crud options here for attribute 'Post Code'] off begin
	@observable
	@attribute<VenueEntity, string>()
	@CRUD({
		name: 'Post Code',
		displayType: 'textfield',
		order: 130,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public postCode: string;
	// % protected region % [Modify props to the crud options here for attribute 'Post Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Latitude'] off begin
	@Validators.Min(-90)
	@Validators.Max(90)
	@Validators.Numeric()
	@observable
	@attribute<VenueEntity, number>()
	@CRUD({
		name: 'Latitude',
		displayType: 'textfield',
		order: 140,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public latitude: number;
	// % protected region % [Modify props to the crud options here for attribute 'Latitude'] end

	// % protected region % [Modify props to the crud options here for attribute 'Longitude'] off begin
	@Validators.Min(-180)
	@Validators.Max(180)
	@Validators.Numeric()
	@observable
	@attribute<VenueEntity, number>()
	@CRUD({
		name: 'Longitude',
		displayType: 'textfield',
		order: 150,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public longitude: number;
	// % protected region % [Modify props to the crud options here for attribute 'Longitude'] end

	// % protected region % [Modify props to the crud options here for attribute 'Archived'] off begin
	@observable
	@attribute<VenueEntity, boolean>()
	@CRUD({
		name: 'Archived',
		displayType: 'checkbox',
		order: 160,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public archived: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Archived'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Contacts'] off begin
		name: 'Contactss',
		displayType: 'reference-multicombobox',
		order: 170,
		referenceTypeFunc: () => Models.ContactEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'contactss',
			oppositeEntity: () => Models.ContactEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Contacts'] end
	})
	public contactss: Models.ContactEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Managed By'] off begin
		name: 'Managed By',
		displayType: 'reference-multicombobox',
		order: 180,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.ManagedByManagesVenue,
		optionEqualFunc: makeJoinEqualsFunc('managedById'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'venueEntity',
			oppositeEntityName: 'applicationUserEntity',
			relationName: 'managesVenue',
			relationOppositeName: 'managedBy',
			entity: () => Models.VenueEntity,
			joinEntity: () => Models.ManagedByManagesVenue,
			oppositeEntity: () => Models.ApplicationUserEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Managed By'] end
	})
	public managedBys: Models.ManagedByManagesVenue[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Client'] on begin
		name: 'Client',
		displayType: 'reference-combobox',
		headerColumn: true,
		order: 110,
		referenceTypeFunc: () => Models.ClientEntity,
		// TODO: TYPE: wrong type, Model doesnt have client
		displayFunction: (attr: any, that: Model) => <>{(that as any).client !== null ? (that as any).client.name : ''}</>,
		// % protected region % [Modify props to the crud options here for reference 'Client'] end
	})
	public clientId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public client: Models.ClientEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Country'] off begin
		name: 'Country',
		displayType: 'reference-combobox',
		order: 200,
		referenceTypeFunc: () => Models.CountryEntity,
		// % protected region % [Modify props to the crud options here for reference 'Country'] end
	})
	public countryId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public country: Models.CountryEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Region'] off begin
		name: 'Region',
		displayType: 'reference-combobox',
		order: 210,
		referenceTypeFunc: () => Models.RegionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Region'] end
	})
	public regionId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public region: Models.RegionEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Venue Type'] off begin
		name: 'Venue Type',
		displayType: 'reference-combobox',
		order: 220,
		referenceTypeFunc: () => Models.VenueTypeEntity,
		// % protected region % [Modify props to the crud options here for reference 'Venue Type'] end
	})
	public venueTypeId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public venueType: Models.VenueTypeEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Zones'] off begin
		name: 'Zoness',
		displayType: 'reference-multicombobox',
		order: 230,
		referenceTypeFunc: () => Models.ZoneEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'zoness',
			oppositeEntity: () => Models.ZoneEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Zones'] end
	})
	public zoness: Models.ZoneEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IVenueEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IVenueEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.logo !== undefined) {
				this.logo = attributes.logo;
			}
			if (attributes.logoId !== undefined) {
				this.logoId = attributes.logoId;
			}
			if (attributes.isActive !== undefined) {
				this.isActive = attributes.isActive;
			}
			if (attributes.facebookURL !== undefined) {
				this.facebookURL = attributes.facebookURL;
			}
			if (attributes.instagramURL !== undefined) {
				this.instagramURL = attributes.instagramURL;
			}
			if (attributes.websiteURL !== undefined) {
				this.websiteURL = attributes.websiteURL;
			}
			if (attributes.spotifyRadioURL !== undefined) {
				this.spotifyRadioURL = attributes.spotifyRadioURL;
			}
			if (attributes.agencyOwnerId !== undefined) {
				this.agencyOwnerId = attributes.agencyOwnerId;
			}
			if (attributes.streetAddressLineOne !== undefined) {
				this.streetAddressLineOne = attributes.streetAddressLineOne;
			}
			if (attributes.streetAddressLineTwo !== undefined) {
				this.streetAddressLineTwo = attributes.streetAddressLineTwo;
			}
			if (attributes.city !== undefined) {
				this.city = attributes.city;
			}
			if (attributes.state !== undefined) {
				this.state = attributes.state;
			}
			if (attributes.postCode !== undefined) {
				this.postCode = attributes.postCode;
			}
			if (attributes.latitude !== undefined) {
				this.latitude = attributes.latitude;
			}
			if (attributes.longitude !== undefined) {
				this.longitude = attributes.longitude;
			}
			if (attributes.archived !== undefined) {
				this.archived = attributes.archived;
			}
			if (attributes.contactss !== undefined && Array.isArray(attributes.contactss)) {
				for (const model of attributes.contactss) {
					if (model instanceof Models.ContactEntity) {
						this.contactss.push(model);
					} else {
						this.contactss.push(new Models.ContactEntity(model));
					}
				}
			}
			if (attributes.managedBys !== undefined && Array.isArray(attributes.managedBys)) {
				for (const model of attributes.managedBys) {
					if (model instanceof Models.ManagedByManagesVenue) {
						this.managedBys.push(model);
					} else {
						this.managedBys.push(new Models.ManagedByManagesVenue(model));
					}
				}
			}
			if (attributes.clientId !== undefined) {
				this.clientId = attributes.clientId;
			}
			if (attributes.client !== undefined) {
				if (attributes.client === null) {
					this.client = attributes.client;
				} else if (attributes.client instanceof Models.ClientEntity) {
					this.client = attributes.client;
					this.clientId = attributes.client.id;
				} else {
					this.client = new Models.ClientEntity(attributes.client);
					this.clientId = this.client.id;
				}
			}
			if (attributes.countryId !== undefined) {
				this.countryId = attributes.countryId;
			}
			if (attributes.country !== undefined) {
				if (attributes.country === null) {
					this.country = attributes.country;
				} else if (attributes.country instanceof Models.CountryEntity) {
					this.country = attributes.country;
					this.countryId = attributes.country.id;
				} else {
					this.country = new Models.CountryEntity(attributes.country);
					this.countryId = this.country.id;
				}
			}
			if (attributes.regionId !== undefined) {
				this.regionId = attributes.regionId;
			}
			if (attributes.region !== undefined) {
				if (attributes.region === null) {
					this.region = attributes.region;
				} else if (attributes.region instanceof Models.RegionEntity) {
					this.region = attributes.region;
					this.regionId = attributes.region.id;
				} else {
					this.region = new Models.RegionEntity(attributes.region);
					this.regionId = this.region.id;
				}
			}
			if (attributes.venueTypeId !== undefined) {
				this.venueTypeId = attributes.venueTypeId;
			}
			if (attributes.venueType !== undefined) {
				if (attributes.venueType === null) {
					this.venueType = attributes.venueType;
				} else if (attributes.venueType instanceof Models.VenueTypeEntity) {
					this.venueType = attributes.venueType;
					this.venueTypeId = attributes.venueType.id;
				} else {
					this.venueType = new Models.VenueTypeEntity(attributes.venueType);
					this.venueTypeId = this.venueType.id;
				}
			}
			if (attributes.zoness !== undefined && Array.isArray(attributes.zoness)) {
				for (const model of attributes.zoness) {
					if (model instanceof Models.ZoneEntity) {
						this.zoness.push(model);
					} else {
						this.zoness.push(new Models.ZoneEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		managedBys {
			${Models.ManagedByManagesVenue.getAttributes().join('\n')}
			managedBy {
				${Models.ApplicationUserEntity.getAttributes().join('\n')}
				${Models.ApplicationUserEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
		contactss {
			${Models.ContactEntity.getAttributes().join('\n')}
		}
		client {
			${Models.ClientEntity.getAttributes().join('\n')}
			${Models.ClientEntity.getFiles().map(f => f.name).join('\n')}
		}
		country {
			${Models.CountryEntity.getAttributes().join('\n')}
		}
		region {
			${Models.RegionEntity.getAttributes().join('\n')}
		}
		venueType {
			${Models.VenueTypeEntity.getAttributes().join('\n')}
		}
		zoness {
			${Models.ZoneEntity.getAttributes().join('\n')}
			${Models.ZoneEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			managedBys: {},
			contactss: {},
			zoness: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'contactss',
							'managedBys',
							'zoness',
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public listExpands = `
		client {
			${Models.ClientEntity.getAttributes().join('\n')}
		}
	`;

	public venueZoneExpands = `
		zoness {
			${Models.ZoneEntity.getAttributes().join('\n')}
			${Models.ZoneEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;

	public venuePageExpands = `
			venueType {
				${Models.VenueTypeEntity.getAttributes().join('\n')}
			}
			contactss {
				${Models.ContactEntity.getAttributes().join('\n')}
			}
			zoness {
				${Models.ZoneEntity.getAttributes().join('\n')}
				${Models.ZoneEntity.getFiles().map(f => f.name).join('\n')}
			}
			country {
				${Models.CountryEntity.getAttributes().join('\n')}
			}
			region {
				${Models.RegionEntity.getAttributes().join('\n')}
			}
			client {
				${Models.ClientEntity.getAttributes().join('\n')}
				agency {
					${Models.AgencyEntity.getAttributes().join('\n')}
				}
			}
			managedBys {
				${Models.ManagedByManagesVenue.getAttributes().join('\n')}
			}
			`;

	public renderAddress = () => {
		const result =				`${(this.streetAddressLineOne && `${this.streetAddressLineOne}, `)
				+ (this.streetAddressLineTwo && `${this.streetAddressLineTwo}, `)
				+ (this.city && `${this.city}, `)
				+ (this.state && `${this.state}, `)
				+ (this.postCode && `${this.postCode}, `)
				+ (this.country.name && `${this.country.name}, `)
				+ (this.region.name && `${this.region.name}`)
				}; ${this.latitude}, ${this.longitude}`;

		return result;
	};
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(VenueEntity.prototype, 'created');
CRUD(modifiedAttr)(VenueEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
