/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import React, { useEffect } from 'react';
import { History } from 'history';
import { useHistory } from 'react-router';
import { runInAction } from 'mobx';
import { confirmModal, IConfirmModalOptions } from '../Views/Components/Modal/ModalUtils';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

// % protected region % [Change default text here] off begin
const defaultTitle = 'You have unsaved changes';
const defaultMessage = <div>Are you sure you want to leave?</div>;
// % protected region % [Change default text here] end

/**
 * A hook to render a confirmation modal when the cantLeave state is True.
 * This function will set cantLeave to false (as a MobX action) when the user confirms to leave.
 *
 * @param cantLeave is True when the user needs to be prompted before leaving the page
 * @param title of the modal which will default to the defaultTitle
 * @param message within the modal which will default to the defaultMessage
 * @param options to configure the text within in the modals buttons
 */
export function useLeavePageConfirmation(
	cantLeave: boolean,
	title?: string,
	message?: React.ReactNode,
	options: IConfirmModalOptions = {},
) {
	const history = useHistory();
	useEffect(() => {
		return leavePageConfirmation(cantLeave, history, title, message, options);
	}, [cantLeave, title, message, options, history]);
}

function beforeUnload(event: BeforeUnloadEvent) {
	if (event) {
		event.returnValue = 'Sure?';
	}
	return 'Sure?';
}

/**
 * Helper function to handle the navigation between pages.
 */
export function leavePageConfirmation(
	cantLeave: boolean,
	history: History,
	title?: string,
	message?: React.ReactNode,
	options: IConfirmModalOptions = {},
) {
	let block = cantLeave;

	const unblock = history.block(tx => {
		if (!block) {
			unblock();
			history.push(tx.pathname);
			runInAction(() => {
				block = false;
			});
			return false;
		}
		confirmModal(title ?? defaultTitle, message ?? defaultMessage, options)
			.then(() => {
				unblock();
				history.push(tx.pathname);
				runInAction(() => {
					block = false;
				});
			}).catch(() => {});

		return false;
	});

	window.addEventListener('beforeunload', beforeUnload);

	return () => {
		unblock();
		window.removeEventListener('beforeunload', beforeUnload);
	};
}

export interface LeavePageConfirmationProps {
	/** True if user can't leave the page, false otherwise */
	cantLeave: boolean;
	/** The title of the confirmation modal */
	title?: string;
	/** The inner message of the confirmation modal */
	message?: React.ReactNode;
	/** Custom options to pass to modal */
	options?: IConfirmModalOptions;
	/** Children */
	children?: React.ReactNode;
}

/**
 * A wrapper to bring the confirmationModal to a class component.
 *
 * @param props to pass to the confirmation modal
 * @returns child components
 */
export function LeavePageConfirmation(props: LeavePageConfirmationProps) {
	const {
		cantLeave,
		title,
		message,
		options,
		children,
	} = props;

	useLeavePageConfirmation(cantLeave, title, message, options);
	return <>{children}</>;
}

// % protected region % [Add extra methods here] off begin
// % protected region % [Add extra methods here] end
