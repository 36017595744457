import { TrackEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import {
	Button, Colors, Display, Sizes, 
} from '../Button/Button';
import { TextField } from '../TextBox/TextBox';
import { ReactComponent as Play } from '../../../assets/play.svg';

interface Props {
	fadeDirection: 'in' | 'out';
	setFade: (fadeDirection: 'in' | 'out', fadeTimeInSeconds: number) => void;
	fades: {
		fadeInMinutes: number;
		fadeInSeconds: number;
		fadeOutMinutes: number;
		fadeOutSeconds: number;
	};
	currentTrack: TrackEntity;
}

const FadeControl = observer(({
	fadeDirection, setFade, fades, currentTrack, 
}: Props) => {
	const [displayManualFade, setDisplayManualFade] = useState(false);

	const { track: storeTrack, getCurrentTime, setCurrentTime } = store;

	let currentFade: number;
	let fadeMinutes: number;
	let fadeSeconds: number;

	if (fadeDirection === 'in') {
		currentFade = currentTrack.fadeIn;
		fadeMinutes = fades.fadeInMinutes;
		fadeSeconds = fades.fadeInSeconds;
	} else {
		currentFade = currentTrack.fadeOut;
		fadeMinutes = fades.fadeOutMinutes;
		fadeSeconds = fades.fadeOutSeconds;
	}

	return (
		<div className={
			`fade-row${
				displayManualFade
					? ' invert-border invert-top' 
					: ''
			}${
				fadeDirection === 'in' ? ' round-left' : ''
			}${
				fadeDirection === 'out' && displayManualFade ? ' round-right' : ''
			}`
		}
		>
			<div className={`manual-fade${!displayManualFade ? ' hidden' : ''}`}>
				<TextField
					modelProperty={fadeDirection === 'in' ? 'fadeInMinutes' : 'fadeOutMinutes'}
					model={fades}
					placeholder="min"
				/>
				<div className="colon">:</div>
				<TextField
					modelProperty={fadeDirection === 'in' ? 'fadeInSeconds' : 'fadeOutSeconds'}
					model={fades}
					placeholder="sec"
				/>
				<Button
					sizes={Sizes.ExtraSmall}
					colors={Colors.Primary}
					display={Display.Solid}
					onClick={() => setFade(
						fadeDirection,
						Number(fadeMinutes) * 60 + Number(fadeSeconds),
					)}
					disabled={
						currentFade === Number(fadeMinutes) * 60 + Number(fadeSeconds)
					}
					className="save-btn"
				>
					{currentFade === Number(fadeMinutes) * 60 + Number(fadeSeconds)
						? 'Saved'
						: 'Save'}
				</Button>
			</div>

			<span className="fade-label">
				{fadeDirection.charAt(0).toUpperCase() + fadeDirection.slice(1)}
			</span>

			<Button
				className="fade-play-btn"
				onClick={() => {
					if (currentTrack !== storeTrack) {
						runInAction(() => {
							store._track = currentTrack;
							store.pause = false;
						});
					} else {
						setCurrentTime(currentFade);
					}
				}}
				type="button"
				disabled={!currentFade}
				display={Display.Text}
				sizes={Sizes.ExtraSmall}
				colors={Colors.Primary}
			>
				<Play />
			</Button>

			<Button
				sizes={Sizes.Small}
				colors={Colors.Secondary}
				display={Display.Outline}
				onClick={() => setFade(fadeDirection, getCurrentTime())}
				disabled={
					currentFade === parseFloat(getCurrentTime().toFixed(1))
					|| currentTrack.id !== storeTrack.id
				}
			>
				Set Here
			</Button>

			<Button
				onClick={() => setDisplayManualFade(!displayManualFade)}
				display={Display.Text}
				className={`show-fade-btn${displayManualFade ? ' open' : ''}`}
			>
				{displayManualFade ? 'close' : `${fadeMinutes}m${fadeSeconds}s`}
			</Button>
		</div>
	);
});

export default FadeControl;
