interface ILoadingContinerProps {
	name?: string;
}

const LoadingContainer = ({ name }: ILoadingContinerProps) => {
	return (
		<div className="loading-container">
			<div className="simple-loader-sm" />
		</div>
	);
};

export default LoadingContainer;
