/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import ApplicationUserTrackTagsReferenceManyToMany from '../Security/Acl/ApplicationUserTrackTagsReferenceManyToMany';
import AgencyPlaylisterTrackTagsReferenceManyToMany from '../Security/Acl/AgencyPlaylisterTrackTagsReferenceManyToMany';
import VisitorsTrackTagsReferenceManyToMany from '../Security/Acl/VisitorsTrackTagsReferenceManyToMany';
import MustardManagementTrackTagsReferenceManyToMany from '../Security/Acl/MustardManagementTrackTagsReferenceManyToMany';
import AgencyAdminTrackTagsReferenceManyToMany from '../Security/Acl/AgencyAdminTrackTagsReferenceManyToMany';
import MustardAdminTrackTagsReferenceManyToMany from '../Security/Acl/MustardAdminTrackTagsReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ITagsTrackTagsAttributes extends IModelAttributes {
	tagsId: string;
	trackTagsId: string;

	tags: Models.TagEntity | Models.ITagEntityAttributes;
	trackTags: Models.TrackEntity | Models.ITrackEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('TagsTrackTags')
export default class TagsTrackTags
	extends Model
	implements ITagsTrackTagsAttributes {
	public static acls: IAcl[] = [
		new ApplicationUserTrackTagsReferenceManyToMany(),
		new AgencyPlaylisterTrackTagsReferenceManyToMany(),
		new VisitorsTrackTagsReferenceManyToMany(),
		new MustardManagementTrackTagsReferenceManyToMany(),
		new AgencyAdminTrackTagsReferenceManyToMany(),
		new MustardAdminTrackTagsReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public tagsId: string;

	@observable
	@attribute()
	public trackTagsId: string;

	@observable
	@attribute({ isReference: true })
	public tags: Models.TagEntity;

	@observable
	@attribute({ isReference: true })
	public trackTags: Models.TrackEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ITagsTrackTagsAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.tagsId) {
				this.tagsId = attributes.tagsId;
			}
			if (attributes.trackTagsId) {
				this.trackTagsId = attributes.trackTagsId;
			}

			if (attributes.tags) {
				if (attributes.tags instanceof Models.TagEntity) {
					this.tags = attributes.tags;
					this.tagsId = attributes.tags.id;
				} else {
					this.tags = new Models.TagEntity(attributes.tags);
					this.tagsId = this.tags.id;
				}
			} else if (attributes.tagsId !== undefined) {
				this.tagsId = attributes.tagsId;
			}

			if (attributes.trackTags) {
				if (attributes.trackTags instanceof Models.TrackEntity) {
					this.trackTags = attributes.trackTags;
					this.trackTagsId = attributes.trackTags.id;
				} else {
					this.trackTags = new Models.TrackEntity(attributes.trackTags);
					this.trackTagsId = this.trackTags.id;
				}
			} else if (attributes.trackTagsId !== undefined) {
				this.trackTagsId = attributes.trackTagsId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
