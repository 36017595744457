/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { SERVER_URL } from 'Constants';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import ApplicationUserApplicationUserEntity from 'Models/Security/Acl/ApplicationUserApplicationUserEntity';
import AgencyAdminApplicationUserEntity from 'Models/Security/Acl/AgencyAdminApplicationUserEntity';
import AgencyPlaylisterApplicationUserEntity from 'Models/Security/Acl/AgencyPlaylisterApplicationUserEntity';
import MustardManagementApplicationUserEntity from 'Models/Security/Acl/MustardManagementApplicationUserEntity';
import VisitorsApplicationUserEntity from 'Models/Security/Acl/VisitorsApplicationUserEntity';
import MustardAdminApplicationUserEntity from 'Models/Security/Acl/MustardAdminApplicationUserEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IApplicationUserEntityAttributes extends IModelAttributes {
	email: string;
	userName: string;
	twoFactorEnabled: boolean;
	displayName: string;
	firstName: string;
	lastName: string;
	displayPictureId: string;
	displayPicture: Blob;
	preferMiles: boolean;
	disableVolumeAdjustment: boolean;

	commandss: Array<
		| Models.ControlAuditEntity
		| Models.IControlAuditEntityAttributes
	>;
	favouriteZones: Array<
		| Models.FavouritedByFavouriteZone
		| Models.IFavouritedByFavouriteZoneAttributes
	>;
	feedbackss: Array<
		| Models.FeedbackEntity
		| Models.IFeedbackEntityAttributes
	>;
	managesVenues: Array<
		| Models.ManagedByManagesVenue
		| Models.IManagedByManagesVenueAttributes
	>;
	managesZones: Array<
		| Models.ManagedByManagesZone
		| Models.IManagedByManagesZoneAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ApplicationUserEntity', 'Application User')
// % protected region % [Customise your entity metadata here] end
export default class ApplicationUserEntity extends Model
	implements IApplicationUserEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new ApplicationUserApplicationUserEntity(),
		new AgencyAdminApplicationUserEntity(),
		new AgencyPlaylisterApplicationUserEntity(),
		new MustardManagementApplicationUserEntity(),
		new VisitorsApplicationUserEntity(),
		new MustardAdminApplicationUserEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		'email',
		'userName',
		'twoFactorEnabled',
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Email'] off begin
	@Validators.Email()
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Email',
		displayType: 'displayfield',
		order: 10,
		createFieldType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public email: string;
	// % protected region % [Modify props to the crud options here for attribute 'Email'] end

	@observable
	@attribute()
	// % protected region % [Modify props to the crud options here for attribute 'TwoFactorEnabled'] off begin
	@CRUD({
		name: 'TwoFactorEnabled',
		displayType: 'displayfield',
		order: 20,
		createFieldType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public twoFactorEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'TwoFactorEnabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'UserName'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'UserName',
		displayType: 'hidden',
	})
	public userName: string;
	// % protected region % [Modify props to the crud options here for attribute 'UserName'] end

	// % protected region % [Modify props to the crud options here for attribute 'Password'] off begin
	@Validators.Length(12, 128)
	@observable
	@CRUD({
		name: 'Password',
		displayType: 'hidden',
		order: 30,
		createFieldType: 'password',
	})
	public password: string;
	// % protected region % [Modify props to the crud options here for attribute 'Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] off begin
	@Validators.Custom('Password Match', (e: string, target: ApplicationUserEntity) => {
		return new Promise(resolve => resolve(target.password !== e ? 'Password fields do not match' : null));
	})
	@observable
	@CRUD({
		name: 'Confirm Password',
		displayType: 'hidden',
		order: 40,
		createFieldType: 'password',
	})
	public _confirmPassword: string;
	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'Display Name'] off begin
	@observable
	@attribute<ApplicationUserEntity, string>()
	@CRUD({
		name: 'Display Name',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public displayName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Display Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'First Name'] off begin
	@Validators.Required()
	@observable
	@attribute<ApplicationUserEntity, string>()
	@CRUD({
		name: 'First Name',
		displayType: 'textfield',
		order: 60,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public firstName: string;
	// % protected region % [Modify props to the crud options here for attribute 'First Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Last Name'] off begin
	@Validators.Required()
	@observable
	@attribute<ApplicationUserEntity, string>()
	@CRUD({
		name: 'Last Name',
		displayType: 'textfield',
		order: 70,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public lastName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Last Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Display Picture'] on begin
	@observable
	@attribute({ file: 'displayPicture' })
	@CRUD({
		name: 'Display Picture',
		displayType: 'file',
		order: 70,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'displayPicture',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Application User" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public displayPictureId: string;

	@observable
	public displayPicture: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Display Picture'] end

	// % protected region % [Modify props to the crud options here for attribute 'Prefer Miles'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Prefer Miles',
		displayType: 'checkbox',
		order: 80,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public preferMiles: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Prefer Miles'] end

	// % protected region % [Modify props to the crud options here for attribute 'Disable Volume Adjustment'] off begin
	@observable
	@attribute<ApplicationUserEntity, boolean>()
	@CRUD({
		name: 'Disable Volume Adjustment',
		displayType: 'checkbox',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public disableVolumeAdjustment: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Disable Volume Adjustment'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Commands'] off begin
		name: 'Commandss',
		displayType: 'reference-multicombobox',
		order: 110,
		referenceTypeFunc: () => Models.ControlAuditEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'commandss',
			oppositeEntity: () => Models.ControlAuditEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Commands'] end
	})
	public commandss: Models.ControlAuditEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Favourite Zone'] off begin
		name: 'Favourite Zone',
		displayType: 'reference-multicombobox',
		order: 120,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.FavouritedByFavouriteZone,
		optionEqualFunc: makeJoinEqualsFunc('favouriteZoneId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'applicationUserEntity',
			oppositeEntityName: 'zoneEntity',
			relationName: 'favouritedBy',
			relationOppositeName: 'favouriteZone',
			entity: () => Models.ApplicationUserEntity,
			joinEntity: () => Models.FavouritedByFavouriteZone,
			oppositeEntity: () => Models.ZoneEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Favourite Zone'] end
	})
	public favouriteZones: Models.FavouritedByFavouriteZone[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Feedbacks'] off begin
		name: 'Feedbackss',
		displayType: 'reference-multicombobox',
		order: 130,
		referenceTypeFunc: () => Models.FeedbackEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'feedbackss',
			oppositeEntity: () => Models.FeedbackEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Feedbacks'] end
	})
	public feedbackss: Models.FeedbackEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Manages Venue'] off begin
		name: 'Manages Venue',
		displayType: 'reference-multicombobox',
		order: 140,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.ManagedByManagesVenue,
		optionEqualFunc: makeJoinEqualsFunc('managesVenueId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'applicationUserEntity',
			oppositeEntityName: 'venueEntity',
			relationName: 'managedBy',
			relationOppositeName: 'managesVenue',
			entity: () => Models.ApplicationUserEntity,
			joinEntity: () => Models.ManagedByManagesVenue,
			oppositeEntity: () => Models.VenueEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Manages Venue'] end
	})
	public managesVenues: Models.ManagedByManagesVenue[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Manages Zone'] off begin
		name: 'Manages Zone',
		displayType: 'reference-multicombobox',
		order: 150,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.ManagedByManagesZone,
		optionEqualFunc: makeJoinEqualsFunc('managesZoneId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'applicationUserEntity',
			oppositeEntityName: 'zoneEntity',
			relationName: 'managedBy',
			relationOppositeName: 'managesZone',
			entity: () => Models.ApplicationUserEntity,
			joinEntity: () => Models.ManagedByManagesZone,
			oppositeEntity: () => Models.ZoneEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Manages Zone'] end
	})
	public managesZones: Models.ManagedByManagesZone[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IApplicationUserEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IApplicationUserEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.email !== undefined) {
				this.email = attributes.email;
			}
			if (attributes.userName !== undefined) {
				this.userName = attributes.userName;
			}
			if (attributes.twoFactorEnabled !== undefined) {
				this.twoFactorEnabled = attributes.twoFactorEnabled;
			}
			if (attributes.displayName !== undefined) {
				this.displayName = attributes.displayName;
			}
			if (attributes.firstName !== undefined) {
				this.firstName = attributes.firstName;
			}
			if (attributes.lastName !== undefined) {
				this.lastName = attributes.lastName;
			}
			if (attributes.displayPicture !== undefined) {
				this.displayPicture = attributes.displayPicture;
			}
			if (attributes.displayPictureId !== undefined) {
				this.displayPictureId = attributes.displayPictureId;
			}
			if (attributes.preferMiles !== undefined) {
				this.preferMiles = attributes.preferMiles;
			}
			if (attributes.disableVolumeAdjustment !== undefined) {
				this.disableVolumeAdjustment = attributes.disableVolumeAdjustment;
			}
			if (attributes.commandss !== undefined && Array.isArray(attributes.commandss)) {
				for (const model of attributes.commandss) {
					if (model instanceof Models.ControlAuditEntity) {
						this.commandss.push(model);
					} else {
						this.commandss.push(new Models.ControlAuditEntity(model));
					}
				}
			}
			if (attributes.favouriteZones !== undefined && Array.isArray(attributes.favouriteZones)) {
				for (const model of attributes.favouriteZones) {
					if (model instanceof Models.FavouritedByFavouriteZone) {
						this.favouriteZones.push(model);
					} else {
						this.favouriteZones.push(new Models.FavouritedByFavouriteZone(model));
					}
				}
			}
			if (attributes.feedbackss !== undefined && Array.isArray(attributes.feedbackss)) {
				for (const model of attributes.feedbackss) {
					if (model instanceof Models.FeedbackEntity) {
						this.feedbackss.push(model);
					} else {
						this.feedbackss.push(new Models.FeedbackEntity(model));
					}
				}
			}
			if (attributes.managesVenues !== undefined && Array.isArray(attributes.managesVenues)) {
				for (const model of attributes.managesVenues) {
					if (model instanceof Models.ManagedByManagesVenue) {
						this.managesVenues.push(model);
					} else {
						this.managesVenues.push(new Models.ManagedByManagesVenue(model));
					}
				}
			}
			if (attributes.managesZones !== undefined && Array.isArray(attributes.managesZones)) {
				for (const model of attributes.managesZones) {
					if (model instanceof Models.ManagedByManagesZone) {
						this.managesZones.push(model);
					} else {
						this.managesZones.push(new Models.ManagedByManagesZone(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		managesVenues {
			${Models.ManagedByManagesVenue.getAttributes().join('\n')}
			managesVenue {
				${Models.VenueEntity.getAttributes().join('\n')}
				${Models.VenueEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
		managesZones {
			${Models.ManagedByManagesZone.getAttributes().join('\n')}
			managesZone {
				${Models.ZoneEntity.getAttributes().join('\n')}
				${Models.ZoneEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] on begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			managesVenues: {},
			managesZones: {},
		};

		if (formMode === 'create') {
			(relationPath as any).password = {};

			if (this.password !== this._confirmPassword) {
				throw Error('Password fields do not match');
			}
		}
		return this.save(
			relationPath,
			{
				graphQlInputType: formMode === 'create'
					? `[${this.getModelName()}CreateInput]`
					: `[${this.getModelName()}Input]`,
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'managesVenues',
							'managesZones',
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.email;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ApplicationUserEntity.prototype, 'created');
CRUD(modifiedAttr)(ApplicationUserEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
