// @ts-nocheck
import { runInAction } from 'mobx';
import { inject } from 'mobx-react';
import { InjectableFrequencyEntity } from 'Models/Entities';

export interface IMoodForm {
	name: string;
	colour: string;
	injectable: boolean;
	injectableFrequencyEntitys: InjectableFrequencyEntity[];
	errors: {
		name?: string;
		frequency?: {[id: string] : string};
		startDate?: {[id: string] : string};
		endDate?: {[id: string] : string};
	};
}

export const moodFormErrorMessages = {
	name: 'Mood must have a name.',
	frequency: 'Frequency must be an integer greater than 0.',
	startDate: 'The start date must be a date.',
	endDate: 'The end date must not be before the start date.',
};

const validateMoodFormField = (field: PropertyKey, condition: boolean, errorField: IMoodForm['errors'], errorMessage?: string, index?: number): void => {
	if (condition) {
		runInAction(() => {
			// If it's an injectible form field, need to save with the injectableEntity id
			if (index !== undefined) {
				if (errorField[field] == null) {
					errorField[field] = {};
				}

				errorField[field][index] = errorMessage ?? moodFormErrorMessages[field];
			} else {
				errorField[field] = errorMessage;
			}
		});
	} else {
		runInAction(() => {
			if (index && errorField[field]) {
				delete errorField[field][index];
			} else {
				delete errorField[field];
			}
		});
	}
};

export const validateMoodFormFields = (moodForm: IMoodForm): void => {
	validateMoodFormField('name', moodForm.name.length === 0, moodForm.errors, moodFormErrorMessages.name);

	if (moodForm.injectable) {
		// Validate each injectibleFrequency Entity
		moodForm.injectableFrequencyEntitys.forEach((injectableFrequencyEntity, index) => {
			validateMoodFormField(
				'frequency',
				injectableFrequencyEntity.frequency <= 0 || !Number.isInteger(injectableFrequencyEntity.frequency),
				moodForm.errors,
				moodFormErrorMessages.frequency,
				index,
			);
			validateMoodFormField(
				'startDate',
				injectableFrequencyEntity.start instanceof Date === false,
				moodForm.errors,
				moodFormErrorMessages.startDate,
				index,
			);
			validateMoodFormField(
				'endDate',
				injectableFrequencyEntity.end instanceof Date === false || injectableFrequencyEntity.end < injectableFrequencyEntity.start,
				moodForm.errors,
				moodFormErrorMessages.endDate,
				index,
			);

			moodForm.injectableFrequencyEntitys.forEach((entity, entityIndex) => {
				if (index !== entityIndex && injectableFrequencyEntity.start && injectableFrequencyEntity.end) {
					// Check for two different frequencies overlapping
					const overlap = ((injectableFrequencyEntity.start.getTime() <= entity.end.getTime()) && (injectableFrequencyEntity.end.getTime() >= entity.start.getTime()));

					// Only run validator if overlap exists, otherwise will override and remove error messages.
					if (overlap) {
						validateMoodFormField(
						'startDate',
						overlap,
						moodForm.errors,
						'Frequencies cannot overlap',
						index,
						);
					}
				}
			});
		});
	}
};

export const moodColours = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's'];
