import {
	IVenueEntityAttributes,
	IZoneEntityAttributes,
	VenueEntity,
	ZoneEntity,
} from '../Models/Entities';
import { store } from '../Models/Store';
import { getFetchAllConditional, getFetchSingleQuery } from './EntityUtils';

const orderBy = [
	{ path: 'name', descending: false },
];

const fetchVenue = async (venueId: string): Promise<VenueEntity> => {
	const { data } = await store.apolloClient.query({
		query: getFetchSingleQuery(VenueEntity, new VenueEntity().venuePageExpands, true),
		fetchPolicy: 'network-only',
		variables: { args: [{ path: 'id', comparison: 'equal', value: venueId }], orderBy },
	});

	return new VenueEntity(data.venueEntity);
};

const fetchVenues = async (venueIdList: string[]): Promise<VenueEntity[]> => {
	const { data } = await store.apolloClient.query<{venueEntitys: IVenueEntityAttributes[]}>({
		query: getFetchAllConditional(VenueEntity, new VenueEntity().venueZoneExpands, {
			expandType: 'list',
			removeCount: true,
		}),
		fetchPolicy: 'network-only',
		variables: { args: [[{ path: 'id', comparison: 'in', value: venueIdList }]], orderBy },
	});

	return data.venueEntitys.map(v => new VenueEntity(v));
};

const searchVenues = async (searchTerm: string): Promise<VenueEntity[]> => {
	const { data } = await store.apolloClient.query<{venueEntitys: IVenueEntityAttributes[]}>({
		query: getFetchAllConditional(VenueEntity, new VenueEntity().venuePageExpands, {
			expandType: 'list',
			removeCount: true,
		}),
		fetchPolicy: 'network-only',
		variables: {
			args: [[{
				path: 'name', comparison: 'like', value: `%${searchTerm}%`, case: 'INVARIANT_CULTURE_IGNORE_CASE',
			}]],
			orderBy,
		},
	});

	return data.venueEntitys.map(v => new VenueEntity(v));
};

const fetchZone = async (zoneId: string): Promise<ZoneEntity> => {
	const { data } = await store.apolloClient.query({
		query: getFetchSingleQuery(ZoneEntity, new ZoneEntity().defaultExpands, true),
		fetchPolicy: 'network-only',
		variables: { args: [{ path: 'id', comparison: 'equal', value: zoneId }], orderBy },
	});

	return new ZoneEntity(data.zoneEntity);
};

const fetchZones = async (zoneIdList: string[]): Promise<ZoneEntity[]> => {
	// Return unique values
	// eslint-disable-next-line no-param-reassign
	zoneIdList = [...new Set(zoneIdList)];
	const { data } = await store.apolloClient.query<{zoneEntitys: IZoneEntityAttributes[]}>({
		query: getFetchAllConditional(ZoneEntity, new ZoneEntity().zoneVenueExpands, {
			expandType: 'list',
			removeCount: true,
		}),
		fetchPolicy: 'network-only',
		variables: { args: [[{ path: 'id', comparison: 'in', value: zoneIdList }]], orderBy },
	});

	return data.zoneEntitys.map(z => new ZoneEntity(z));
};

const fetchZonesByVenue = async (venueId: string): Promise<ZoneEntity[]> => {
	const { data } = await store.apolloClient.query<{zoneEntitys: IZoneEntityAttributes[]}>({
		query: getFetchAllConditional(ZoneEntity, '', {
			expandType: 'list',
			removeCount: true,
		}),
		fetchPolicy: 'network-only',
		variables: { args: [[{ path: 'venueId', comparison: 'equal', value: venueId }]], orderBy },
	});

	return data.zoneEntitys.map(z => new ZoneEntity(z));
};

export {
	fetchVenue, fetchVenues, searchVenues, fetchZone, fetchZones, fetchZonesByVenue
};
