/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsContactEntity from 'Models/Security/Acl/VisitorsContactEntity';
import AgencyPlaylisterContactEntity from 'Models/Security/Acl/AgencyPlaylisterContactEntity';
import ApplicationUserContactEntity from 'Models/Security/Acl/ApplicationUserContactEntity';
import AgencyAdminContactEntity from 'Models/Security/Acl/AgencyAdminContactEntity';
import MustardManagementContactEntity from 'Models/Security/Acl/MustardManagementContactEntity';
import MustardAdminContactEntity from 'Models/Security/Acl/MustardAdminContactEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IContactEntityAttributes extends IModelAttributes {
	name: string;
	emailAddress: string;
	phoneNumber: string;
	mainContact: boolean;
	agencyOwnerId: string;

	clientId?: string;
	client?: Models.ClientEntity | Models.IClientEntityAttributes;
	venueId?: string;
	venue?: Models.VenueEntity | Models.IVenueEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ContactEntity', 'Contact')
// % protected region % [Customise your entity metadata here] end
export default class ContactEntity extends Model
	implements IContactEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsContactEntity(),
		new AgencyPlaylisterContactEntity(),
		new ApplicationUserContactEntity(),
		new AgencyAdminContactEntity(),
		new MustardManagementContactEntity(),
		new MustardAdminContactEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@observable
	@attribute<ContactEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Email Address'] off begin
	@Validators.Email()
	@observable
	@attribute<ContactEntity, string>()
	@CRUD({
		name: 'Email Address',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public emailAddress: string;
	// % protected region % [Modify props to the crud options here for attribute 'Email Address'] end

	// % protected region % [Modify props to the crud options here for attribute 'Phone Number'] off begin
	@observable
	@attribute<ContactEntity, string>()
	@CRUD({
		name: 'Phone Number',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public phoneNumber: string;
	// % protected region % [Modify props to the crud options here for attribute 'Phone Number'] end

	// % protected region % [Modify props to the crud options here for attribute 'Main Contact'] off begin
	/**
	 * Specifies if this contact is the primary contact for client/venue
	 */
	@observable
	@attribute<ContactEntity, boolean>()
	@CRUD({
		name: 'Main Contact',
		displayType: 'checkbox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public mainContact: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Main Contact'] end

	// % protected region % [Modify props to the crud options here for attribute 'Agency Owner Id'] off begin
	@Validators.Uuid()
	@observable
	@attribute<ContactEntity, string>()
	@CRUD({
		name: 'Agency Owner Id',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public agencyOwnerId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Agency Owner Id'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Client'] off begin
		name: 'Client',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.ClientEntity,
		// % protected region % [Modify props to the crud options here for reference 'Client'] end
	})
	public clientId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public client: Models.ClientEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Venue'] off begin
		name: 'Venue',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.VenueEntity,
		// % protected region % [Modify props to the crud options here for reference 'Venue'] end
	})
	public venueId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public venue: Models.VenueEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IContactEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IContactEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.emailAddress !== undefined) {
				this.emailAddress = attributes.emailAddress;
			}
			if (attributes.phoneNumber !== undefined) {
				this.phoneNumber = attributes.phoneNumber;
			}
			if (attributes.mainContact !== undefined) {
				this.mainContact = attributes.mainContact;
			}
			if (attributes.agencyOwnerId !== undefined) {
				this.agencyOwnerId = attributes.agencyOwnerId;
			}
			if (attributes.clientId !== undefined) {
				this.clientId = attributes.clientId;
			}
			if (attributes.client !== undefined) {
				if (attributes.client === null) {
					this.client = attributes.client;
				} else if (attributes.client instanceof Models.ClientEntity) {
					this.client = attributes.client;
					this.clientId = attributes.client.id;
				} else {
					this.client = new Models.ClientEntity(attributes.client);
					this.clientId = this.client.id;
				}
			}
			if (attributes.venueId !== undefined) {
				this.venueId = attributes.venueId;
			}
			if (attributes.venue !== undefined) {
				if (attributes.venue === null) {
					this.venue = attributes.venue;
				} else if (attributes.venue instanceof Models.VenueEntity) {
					this.venue = attributes.venue;
					this.venueId = attributes.venue.id;
				} else {
					this.venue = new Models.VenueEntity(attributes.venue);
					this.venueId = this.venue.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		client {
			${Models.ClientEntity.getAttributes().join('\n')}
			${Models.ClientEntity.getFiles().map(f => f.name).join('\n')}
		}
		venue {
			${Models.VenueEntity.getAttributes().join('\n')}
			${Models.VenueEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.emailAddress;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ContactEntity.prototype, 'created');
CRUD(modifiedAttr)(ContactEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
