/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { RefObject, useEffect, useRef } from "react";
import { Droppable } from "react-beautiful-dnd";
import { observer, useLocalStore } from "mobx-react";
import { useInfiniteQuery, useQueryClient } from "react-query";
import Axios from "axios";
import { contextMenu } from "react-contexify";
import { runInAction } from "mobx";
import {
	ContentsSearchResultObject,
	IEditorTrackLists,
	MultiSelectStore,
	PlaylistSummary,
} from "../../../Util/PlaylistUtils";
import { PlaylistTrackEntity, TrackEntity } from "../../../Models/Entities";
import { SERVER_URL } from "../../../Constants";
import useIntersectionObserver from "../../../Util/useIntersectionObserver";
import useContainerScroll from "../../../Util/useContainerScroll";
import EditorTrackList from "./EditorTrackList";
import { ContextMenu } from "../../Components/ContextMenu/ContextMenu";
import alert from "../../../Util/ToastifyUtils";
import { alertModal } from "../../Components/Modal/ModalUtils";
import RemoveTrackConfirmationModal from "./RemoveTrackConfirmationModal";
import AlbumArtPlaceholder from "assets/empty-album-art.svg";
import { Tooltip } from "Views/Components/Tooltip/Tooltip";
import axios from "axios";
import alertToast from "../../../Util/ToastifyUtils";

const PlaylistEditorSortingOptions = {
	similarity: "Similarity",
	genreAtoZ: "Genre A-Z",
	genreZtoA: "Genre Z-A",
	titleAtoZ: "Title A-Z",
	titleZtoA: "Title Z-A",
	mostRecentlyAdded: "Most Recently Added",
	leastRecentlyAdded: "Least Recently Added",
	artistAtoZ: "Artist A-Z",
	artistZtoA: "Artist Z-A",
	AlbumAtoZ: "Album A-Z",
	AlbumZtoA: "Album Z-A",
	bpmAsc: "BPM Ascending",
	bpmDesc: "BPM Descending",
} as const;

interface IPlaylistTrackPanelProps {
	playlist: PlaylistSummary;
	multiSelectStore: MultiSelectStore;
	multiSelect: (
		event: React.MouseEvent<HTMLInputElement>,
		index: number,
		start: boolean,
		track: string,
		trackList: string[],
		collectionId: string
	) => void;
	isExpanded: boolean;
	closeActive: (playlist: PlaylistSummary) => void;
	editorTrackLists: { editor: IEditorTrackLists };
	addTracklist: (playlistId: string, trackList: TrackEntity[]) => void;
}

const PlaylistTrackPanel = observer((props: IPlaylistTrackPanelProps) => {
	const {
		playlist,
		multiSelect,
		multiSelectStore,
		isExpanded,
		closeActive,
		editorTrackLists,
		addTracklist,
	} = props;

	const scrollRef = useContainerScroll(`${playlist.id}-editor`);

	const orderStore = useLocalStore(() => ({
		orderBy: "Similarity",
	}));

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
		useInfiniteQuery(
			["playlist_contents", playlist.id, orderStore.orderBy],
			async ({ pageParam = 0 }): Promise<ContentsSearchResultObject> => {
				const url =
					`${SERVER_URL}/api/playlist_search/collection_contents/${playlist.id}` +
					`?PageNo=${pageParam}` +
					"&PageSize=100" +
					"&entityType=playlist" +
					`&orderBy=${orderStore.orderBy}`;

				const res = await Axios.get(url);
				return res.data as ContentsSearchResultObject;
			},
			{
				getNextPageParam: (lastPage: any) =>
					lastPage.nextPageNo === -1 ? undefined : lastPage.nextPageNo,
			}
		);

	const queryClient = useQueryClient();

	useEffect(
		() => () => {
			if (!isExpanded) {
				queryClient.refetchQueries([
					"playlist_contents",
					playlist.id,
					orderStore.orderBy,
				]);
			}
		},
		[]
	);

	// triggers fetching more content when reached
	const loadMoreButtonRef =
		useRef<HTMLButtonElement>() as RefObject<HTMLButtonElement>;

	// tracks the position of the scroll container to see if the loadMoreButtonRef has been reached
	useIntersectionObserver({
		root: null,
		target: loadMoreButtonRef,
		onIntersect: fetchNextPage,
		enabled: hasNextPage,
	});

	useEffect(() => {
		const pages = data?.pages;

		if (pages) {
			const listOfLists = [].concat.apply(
				pages?.map((page) => page.playlist)
			) as PlaylistTrackEntity[][];
			const listOfPlaylistTracks = [] as PlaylistTrackEntity[];

			listOfLists.forEach((list: PlaylistTrackEntity[]) => {
				list.forEach((entity: PlaylistTrackEntity) => {
					const playlistTrack = entity as PlaylistTrackEntity;
					listOfPlaylistTracks.push(new PlaylistTrackEntity(playlistTrack));
				});
			});

			const listOfTracks = listOfPlaylistTracks.map((pt) => pt.track);

			addTracklist(playlist.id, listOfTracks);
		}
	}, [addTracklist, data, playlist.id]);

	const removeTracks = () => {
		const payload = {
			playlistId: playlist.id,
			trackIds: multiSelectStore.selected,
		};

		Axios.post(`${SERVER_URL}/api/playlist_search/preremove_check`, payload)
			.then((res) => {
				if (res.data) {
					alertModal(
						"",
						<RemoveTrackConfirmationModal
							payload={payload}
							multiSelectStore={multiSelectStore}
							orderStore={orderStore}
						/>
					);
				} else {
					Axios.post(`${SERVER_URL}/api/playlist_search/remove_tracks`, payload)
						.then(() => {
							runInAction(() => {
								multiSelectStore.selected = [];
							});

							queryClient.refetchQueries([
								"playlist_contents",
								playlist.id,
								orderStore.orderBy,
							]);
							queryClient.refetchQueries(["summary", playlist.id]);

							alert("Tracks removed", "success");
						})
						.catch(() => alert("Something went wrong", "error"));
				}
			})
			.catch(() => console.log("badness"));
	};

	const menuId = `context-menu-playlist-${playlist.id}`;

	const ContextMenuButton = (label: string) => {
		return {
			buttonClass: orderStore.orderBy === label ? "btn--solid" : "",
			label: label,
			onClick: (): void => {
				runInAction(() => {
					orderStore.orderBy = label;
				});
			},
		};
	};

	const downloadCsv = async () => {
		try {
			if (playlist.id) {
				const response = await axios.get(
					`${SERVER_URL}/api/playlist_search/playlist-info/csv/${playlist.id}`,
					{
						responseType: "blob",
					}
				);

				const blob = new Blob([response.data], {
					type: "text/csv;charset=utf-8;",
				});
				const url = URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				const filename = `${playlist.name} Info.csv`;
				link.setAttribute("download", filename);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		} catch (err) {
			console.error("Error fetching track info CSV:", err);
			alertToast(
				"Error fetching track info CSV. Please try again later or contact support!",
				"error"
			);
		}
	};

	return (
		<div className={`playlist-contents ${isExpanded ? "expanded" : ""}`}>
			<div className="playlist-contents-header">
				<div className="playlist-contents-label">
					<h5 className="playlist-name">{playlist.name}</h5>
					<span className="summary">{data?.pages[0].summary}</span>
				</div>
				<div className="actions">
					<div className="input-group mr-1">
						<Tooltip
							id={playlist.id}
							content={`${
								`Created: ${playlist.created ?? ""}\n` +
								`Modified: ${playlist.modified ?? ""}\n`
							}${playlist.mood ? `Mood: ${playlist.mood ?? ""}` : ""}`}
						/>
					</div>
					<div
						className="icon-minus icon-top"
						onClick={() => closeActive(playlist)}
					/>
					<div
						className="icon-order icon-top sort-playlist"
						onClick={(e) =>
							contextMenu.show({
								event: e,
								id: `sort-menu-playlist-${playlist.id}`,
							})
						}
					/>
					<div
						className="icon-more-vertical icon-top"
						onClick={(e) => {
							contextMenu.show({ event: e, id: menuId });
						}}
					/>
					<ContextMenu
						menuId={menuId}
						actions={[
							{
								label: "Export Playlist To CSV",
								onClick: (): void => {
									window.open(
										`${SERVER_URL}/api/entity/PlaylistEntity/ExportToCSV/${playlist.id}`,
										"_blank"
									);
								},
							},
							{
								label: "Remove Selected Tracks",
								onClick: (): void => {
									removeTracks();
								},
							},
							{
								label: "Download Playlist Information",
								onClick: downloadCsv,
							},
						]}
					/>
					<ContextMenu
						menuId={`sort-menu-playlist-${playlist.id}`}
						actions={Object.values(PlaylistEditorSortingOptions).map((value) =>
							ContextMenuButton(value)
						)}
					/>
				</div>
			</div>
			<Droppable
				droppableId={`destination-${playlist.id}`}
				renderClone={(provided, snapshot, rubric) => (
					<div
						className="track-wrapper-clone"
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
					>
						<div className="select-count">{multiSelectStore?.numSelected}</div>

						<img
							src={AlbumArtPlaceholder}
							alt="album-art"
							className="album-art"
						/>
					</div>
				)}
			>
				{(provided) => (
					<div
						className="results-wrapper"
						ref={provided.innerRef}
						{...provided.droppableProps}
					>
						<div className="drop-area" role="drop-area">
							<div className="add-to-playlist icon-plus icon-left">
								<p>Drag tracks to playlist</p>
							</div>
						</div>
						<div className="results" ref={scrollRef}>
							<EditorTrackList
								isExpanded={isExpanded}
								playlist={playlist}
								multiSelect={multiSelect}
								multiSelectStore={multiSelectStore}
								editorTrackLists={editorTrackLists}
							/>
							{provided.placeholder}
							<div className="infinite-scroll-button-container">
								<button
									className="infiniteListEndButton"
									ref={loadMoreButtonRef}
									onClick={() => fetchNextPage()}
									disabled={!hasNextPage || isFetchingNextPage}
								>
									{isLoading || isFetchingNextPage || hasNextPage
										? "Loading..."
										: "Nothing more to load"}
								</button>
							</div>
						</div>
					</div>
				)}
			</Droppable>
		</div>
	);
});

export default PlaylistTrackPanel;
