/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import {
	ICollectionItemActionProps,
	expandFn,
	showExpandFn,
	actionFilterFn,
} from './Collection';
import { observable, computed, runInAction } from 'mobx';
import { Checkbox } from '../Checkbox/Checkbox';
import { Button } from '../Button/Button';
import { ButtonGroup, Alignment } from '../Button/ButtonGroup';
import { ICollectionHeaderPropsPrivate } from './CollectionHeaders';
import classNames from 'classnames';
import If from '../If/If';
import { EntityContextMenu, IEntityContextMenuActions } from '../EntityContextMenu/EntityContextMenu';
import { IIconProps } from 'Views/Components/Helpers/Common';
// % protected region % [Add extra imports here] off begin
// % protected region % [Add extra imports here] end

export interface ICollectionRowProps<T> {
	item: T;
	headers: Array<ICollectionHeaderPropsPrivate<T>>;
	actions?: Array<ICollectionItemActionProps<T>> | actionFilterFn<T>;
	actionsMore?: IEntityContextMenuActions<T>;
	selectableItems?: boolean;
	expandAction?: expandFn<T>;
	showExpandButton?: showExpandFn<T>;
	checked?: boolean;
	onChecked?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, checkedItem: T) => void;
	idColumn?: string;
	keyValue: string;
	dataFields?: (row: T) => { [key: string]: string };
	// % protected region % [Add extra collection row props here] off begin
	// % protected region % [Add extra collection row props here] end
}

/**
 * This is a row in a collection component
 */
@observer
class CollectionRow<T> extends React.Component<ICollectionRowProps<T>, any> {
	@observable
	private expanded = false;

	@observable
	private checked = { checked: this.props.checked }

	private moreMenuRef : EntityContextMenu<T> | null;

	// % protected region % [Add extra attributes here] off begin
	// % protected region % [Add extra attributes here] end

	/**
	 * The dom for the expanded row
	 */
	@computed
	private get expandDom() {
		// % protected region % [Customize expandDom computed field here] off begin
		const {
			headers,
			expandAction,
			item,
			selectableItems,
		} = this.props;

		if (expandAction && this.expanded) {
			// The magic number is since we have an extra column for the checkbox and another for the actions
			const colSpanOffset = selectableItems ? 2 : 1;

			const classes = classNames(
				'collection__item',
				'collection__item--is-expanded-child',
				(this.expanded ? '' : ' hide'),
			);

			return (
				<tr className={classes}>
					<td colSpan={headers.length + colSpanOffset}>
						{expandAction(item)}
					</td>
				</tr>
			);
		}

		return null;
		// % protected region % [Customize expandDom computed field here] end
	}

	constructor(props: ICollectionRowProps<T>) {
		super(props);
		this.checked.checked = props.checked;
		// % protected region % [Add extra constructor logic here] off begin
		// % protected region % [Add extra constructor logic here] end
	}

	public render() {
		// % protected region % [Customize render logic here] off begin
		const columns = [];

		const {
			headers,
			actions,
			showExpandButton,
			onChecked,
			selectableItems,
			dataFields: dataFields1,
			keyValue,
			idColumn,
			expandAction,
			item,
			checked,
			actionsMore,
		} = this.props;

		// The checkbox at the start of the row
		if (!!selectableItems) {
			columns.push(
				<td key="0-select" className="select-box">
					<Checkbox
						model={{}}
						modelProperty=""
						name="select"
						inputProps={{
							checked: checked,
							onChange: event => {
								if (onChecked) {
									onChecked(event, event.target.checked, item);
								}
							},
						}}
					/>
				</td>,
			);
		}

		// The columns from the item to display
		columns.push(headers.map(column => {
			let displayValue: React.ReactNode;

			if (column.transformItem) {
				displayValue = column.transformItem(item, column.name);
			} else if (item[column.name] || item[column.name] === 0) {
				if (typeof item[column.name].toLocaleDateString === 'function') {
					displayValue = moment(item[column.name]).format('DD/MM/YYYY');
				} else if (typeof item[column.name].toString === 'function') {
					displayValue = item[column.name].toString();
				} else {
					displayValue = item;
				}
			} else {
				displayValue = column.nullValue || 'None';
			}

			return (
				<td key={column.name}>
					{displayValue}
				</td>
			);
		}));

		// The action buttons
		let actionButtons: JSX.Element[] = [];

		if (typeof actions === 'function') {
			actionButtons = actions(item)
				.map((action, actIdx) => {
					const key = `${actIdx}-${action.label}`;
					if (!action.customButton) {
						const icon = action.showIcon
							&& action.icon
							&& action.iconPos ? { icon: action.icon, iconPos: action.iconPos } : undefined;
						return (
							<Button
								key={key}
								className={action.buttonClass}
								icon={icon}
								buttonProps={{ onClick: event => { action.action(item, event); } }}
							>
								{action.label}
							</Button>
						);
					}

					return <React.Fragment key={key}>{action.customButton(item)}</React.Fragment>;
				});
		} else if (Array.isArray(actions)) {
			actionButtons = actions
				.map((action, actIdx) => {
					const key = `${actIdx}-${action.label}`;
					if (!action.customButton) {
						const icon = action.showIcon
							&& action.icon
							&& action.iconPos ? { icon: action.icon, iconPos: action.iconPos } : undefined;
						return (
							<Button
								key={key}
								className={action.buttonClass}
								icon={icon}
								buttonProps={{ onClick: event => { action.action(item, event); } }}
							>
								{action.label}
							</Button>
						);
					}

					return <React.Fragment key={key}>{action.customButton(item)}</React.Fragment>;
				});
		}

		// The expand button if needed
		let expandButton = null;
		if (expandAction && (!showExpandButton || showExpandButton(item))) {
			let icon: IIconProps;
			if (this.expanded) {
				icon = { icon: 'chevron-up', iconPos: 'icon-top' };
			} else {
				icon = { icon: 'chevron-down', iconPos: 'icon-top' };
			}

			expandButton = (
				<Button
					icon={icon}
					onClick={() => {
						runInAction(() => {
							this.expanded = !this.expanded;
						});
					}}
				>
					Expand
				</Button>
			);
		}

		if (expandButton || actions || (actionsMore && actionsMore.length > 0)) {
			columns.push(
				<td className="list__items--actions" key={headers.length + 1}>
					<ButtonGroup alignment={Alignment.HORIZONTAL}>
						{actionButtons}
						{expandButton}
						<If condition={!!actionsMore && !!actionsMore.length}>
							<EntityContextMenu
								menuId={keyValue}
								actions={actionsMore || []}
								ref={ref => { this.moreMenuRef = ref || null; }}
								entity={item}
							/>
							<Button
								onClick={(event: React.MouseEvent<Element, MouseEvent>) => {
									if (this.moreMenuRef) {
										this.moreMenuRef.handleContextMenu(event);
									}
								}}
								icon={{ icon: 'more-horizontal', iconPos: 'icon-top' }}
							>
								More
							</Button>
						</If>
					</ButtonGroup>
				</td>,
			);
		}

		const prefixedDataFields = {};
		if (dataFields1) {
			const dataFields = dataFields1(item);
			Object.keys(dataFields).forEach(key => {
				prefixedDataFields[`data-${key}`] = dataFields[key];
			});
		}

		return (
			<>
				<tr
					className={
						classNames(
							'collection__item',
							(this.expanded ? 'collection__item--has-expanded-child' : null),
							this.checked.checked ? 'collection__item--selected' : null,
						)
					}
					data-id={idColumn ? item[idColumn] : undefined}
					{...prefixedDataFields}
				>
					{columns}
				</tr>
				{this.expandDom}
			</>
		);
		// % protected region % [Customize render logic here] end
	}
	// % protected region % [Add extra methods here] off begin
	// % protected region % [Add extra methods here] end
}

export default CollectionRow;
