import React, { useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { action, runInAction } from 'mobx';
import { useQueryClient } from 'react-query';
import { ApolloQueryResult } from '@apollo/client';
import moment from 'moment';
import {
	AgencyEntity, 
	IAgencyEntityAttributes,
	ProfileEntity,
	ScheduleEntity,
	ZoneEntity,
} from '../../../../Models/Entities';
import { store } from '../../../../Models/Store';
import { Combobox } from '../../../Components/Combobox/Combobox';
import {
	Button, Colors, Display, Sizes, 
} from '../../../Components/Button/Button';
import alert from '../../../../Util/ToastifyUtils';
import { getFetchSingleQuery } from '../../../../Util/EntityUtils';

interface IAssignTabProps {
	profile: ProfileEntity;	
}

interface IAssignedSchedule {
	client?: string | null;
	venue?: string | null;
	zone?: string | null;
	schedule?: string | null;
}

const AssignTab = observer((props: IAssignTabProps): JSX.Element => {
	const { profile } = props;
	const schedules = profile.scheduless;
	
	const canUpdate = !store.userGroups.some(ug => ug.name === 'AgencyPlaylister');

	const queryClient = useQueryClient();
	
	const agencyStore = useLocalStore(() => ({
		agency: new AgencyEntity(),
	}));
	
	const assignedScheduleStore = useLocalStore(() => ({
		newAssignment: {
			client: null,
			venue: null,
			zone: null,
			schedule: null,
		} as IAssignedSchedule,
		newAssigmentErrors: {
			zone: '',
		},
		assignedSchedules: [] as IAssignedSchedule[],
	}));
	
	const validateSelection = action((zoneId: string) => {
		if (assignedScheduleStore.assignedSchedules.filter(as => as.zone === zoneId).length > 0) {
			assignedScheduleStore.newAssigmentErrors.zone = 'A schedule has already been assigned to this zone.';
		} else if (assignedScheduleStore.newAssigmentErrors.zone !== '') {
			assignedScheduleStore.newAssigmentErrors.zone = '';
		}
	});

	const orderBy = [
		{ path: 'name', descending: false },
	];
	
	const getClients = async () => {
		await store.apolloClient.query<{agencyEntity: IAgencyEntityAttributes}>({
			query: getFetchSingleQuery(AgencyEntity, new AgencyEntity().defaultExpands, false),
			fetchPolicy: 'network-only',
			variables: {
				args: [{
					path: 'id',
					comparison: 'equal',
					value: profile.agencyOwnerId,
				}],
				orderBy,
			},
		}).then((res: ApolloQueryResult<{agencyEntity: IAgencyEntityAttributes}>) => {
			const agency = new AgencyEntity(res.data.agencyEntity);
			runInAction(() => {
				agencyStore.agency = agency;
				assignedScheduleStore.assignedSchedules = [];
			});
			agencyStore.agency.clientss.forEach(client => {
				client.venuess.forEach(venue => {
					if (venue.zoness.filter(zone => zone.scheduleId).length > 0) {
						venue.zoness.forEach(zone => {
							if (zone.schedule) {
								if (profile.scheduless.map(s => s.id).includes(zone.scheduleId || '')) {
									runInAction(() => {
										assignedScheduleStore.assignedSchedules = [...assignedScheduleStore.assignedSchedules,
											{
												client: client.id ?? '',
												venue: venue.id ?? '',
												zone: zone.id ?? '',
												schedule: zone.scheduleId ?? '',
											},
										];
									});
								}
							}
						});
					}
				});
			});
		});
	};
	
	useEffect(() => {
		getClients();
	}, []);

	const getClientOptions = () => agencyStore.agency.clientss.map(c => ({ display: c.name, value: c.id }));
	
	const getVenueOptions = (clientId: string) => agencyStore.agency.clientss
		.filter(c => c.id === clientId)[0]?.venuess
		.map(v => ({ display: v.name, value: v.id })) || [];

	const getZoneOptions = (clientId: string, venueId: string, zoneId: string) => agencyStore.agency.clientss
		.filter(c => c.id === clientId)[0]?.venuess
		.filter(v => v.id === venueId)[0]?.zoness
		.filter(z => z.id === zoneId || !assignedScheduleStore.assignedSchedules.map(as => as.zone).includes(z.id))
		.map(z => ({ display: z.name, value: z.id })) || [];
	
	const assignSchedule = (assignedSchedule?: IAssignedSchedule) => {
		let zone: ZoneEntity;
		let schedule: ScheduleEntity;
		if (assignedSchedule) {
			zone = new ZoneEntity(agencyStore.agency.clientss
				.filter(c => c.id === assignedSchedule?.client)[0].venuess
				.filter(v => v.id === assignedSchedule?.venue)[0].zoness
				.filter(z => z.id === assignedSchedule?.zone)[0]);

			schedule = schedules.filter(s => s.id === assignedSchedule.schedule)[0] as ScheduleEntity;
		} else {
			zone = new ZoneEntity(agencyStore.agency.clientss
				.filter(c => c.id === assignedScheduleStore.newAssignment.client)[0].venuess
				.filter(v => v.id === assignedScheduleStore.newAssignment.venue)[0].zoness
				.filter(z => z.id === assignedScheduleStore.newAssignment.zone)[0]);

			schedule = schedules.filter(s => s.id === assignedScheduleStore.newAssignment.schedule)[0] as ScheduleEntity;
		}
		runInAction(() => {
			if (zone !== null) {
				zone.schedule = schedule;
				zone.scheduleId = schedule.id;
				zone.profile = profile;
				zone.profileId = profile.id;
				
				// When assigning a zone to a profile, set the below values to enable sync
				zone.lastUpdated = moment().toDate();
				zone.updateRequired = moment().toDate();
				
				zone.save({ schedule: {} })
					.then(() => {
						alert('Assigned schedule to zone', 'success');
						runInAction(() => {
							assignedScheduleStore.newAssignment = {
								client: null,
								venue: null,
								zone: null,
								schedule: null,
							};
						});
						getClients();
						queryClient.refetchQueries('profile');
					})
					.catch(err => alert('Failed to assign schedule to zone', 'error'));
			}
		});
	};
	
	const unassignSchedule = async (assignedSchedule: IAssignedSchedule) => {
		const client = agencyStore.agency.clientss.filter(c => c.id === assignedSchedule.client)[0];
		const venue = client.venuess.filter(v => v.id === assignedSchedule.venue)[0];
		const zone = new ZoneEntity(venue.zoness.filter(z => z.id === assignedSchedule.zone)[0]);
		zone.scheduleId = undefined;
		zone.profileId = undefined;
		
		await zone.save()
			.then(() => alert('Unassigned Schedule', 'success'))
			.then(() => getClients())
			.catch(() => alert('Failed to remove schedule', 'error'));
	};
	
	return (
		<div className="assign-container">
			<h4>Assign Profile</h4>
			{assignedScheduleStore.assignedSchedules.map((as: IAssignedSchedule) => (
				<div className="assigned-schedule" key={`zone-${as.zone}`}>
					<Combobox
						model={as}
						modelProperty="client"
						label="Client"
						options={getClientOptions()}
						isDisabled
					/>
					<Combobox
						model={as}
						modelProperty="venue"
						label="Venue"
						options={getVenueOptions(as.client ?? '')}
						isDisabled
					/>
					<Combobox
						model={as}
						modelProperty="zone"
						label="Zone"
						options={getZoneOptions(as.client ?? '', as.venue ?? '', as.zone ?? '')}
						isDisabled
					/>
					<Combobox
						model={as}
						modelProperty="schedule"
						label="Schedule"
						options={schedules.map(s => ({ display: s.name, value: s.id }))}
						onAfterChange={() => {
							assignSchedule(as);
						}}
					/>
					{canUpdate && (
						<Button
							className="unassign-schedule"
							sizes={Sizes.Medium}
							colors={Colors.Error}
							display={Display.Text}
							icon={{ icon: 'bin-delete', iconPos: 'icon-top' }}
							onClick={() => unassignSchedule(as)}
						/>	
					)}
				</div>
			))}
			{assignedScheduleStore.newAssignment.client !== null && (
				<div className="assigned-schedule">
					<Combobox
						model={assignedScheduleStore.newAssignment}
						modelProperty="client"
						label="Client"
						options={getClientOptions()}
					/>
					<Combobox
						model={assignedScheduleStore.newAssignment}
						modelProperty="venue"
						label="Venue"
						options={getVenueOptions(assignedScheduleStore.newAssignment.client ?? '')}
						isDisabled={assignedScheduleStore.newAssignment.client === ''}
					/>
					<Combobox
						model={assignedScheduleStore.newAssignment}
						modelProperty="zone"
						label="Zone"
						options={getZoneOptions(assignedScheduleStore.newAssignment.client ?? '', assignedScheduleStore.newAssignment.venue ?? '', '')}
						isDisabled={assignedScheduleStore.newAssignment.venue === ''}
						errors={assignedScheduleStore.newAssigmentErrors.zone}
						onAfterChange={(e: any) => {
							validateSelection(assignedScheduleStore.newAssignment.zone ?? '');
						}}
					/>
					<Combobox
						model={assignedScheduleStore.newAssignment}
						modelProperty="schedule"
						label="Schedule"
						options={schedules.map(s => ({ display: s.name, value: s.id }))}
						isDisabled={assignedScheduleStore.newAssignment.zone === ''}
					/>
					<Button
						sizes={Sizes.Medium}
						colors={Colors.Primary}
						display={Display.Solid}
						onClick={() => assignSchedule()}
						disabled={assignedScheduleStore.newAssignment.schedule === ''}
					>
						Save
					</Button>
					<Button
						sizes={Sizes.Medium}
						colors={Colors.White}
						display={Display.Outline}
						onClick={() => runInAction(() => {
							assignedScheduleStore.newAssignment = {
								client: null,
								venue: null,
								zone: null,
								schedule: null,
							};
						})}
					>
						Discard
					</Button>
				</div>
			)}
			{canUpdate && (
				<Button
					sizes={Sizes.Medium}
					colors={Colors.Primary}
					display={Display.Text}
					icon={{ icon: 'plus', iconPos: 'icon-left' }}
					onClick={() => {
						runInAction(() => {
							assignedScheduleStore.newAssignment.client = '';
							assignedScheduleStore.newAssignment.venue = '';
							assignedScheduleStore.newAssignment.zone = '';
							assignedScheduleStore.newAssignment.schedule = '';
						});
					}}
				>
					New
				</Button>
			)}
		</div>
	);
});

export default AssignTab;
