import { observer } from 'mobx-react';
import { store } from '../../../../Models/Store';
import { PlaylistSummary } from '../../../../Util/PlaylistUtils';
import { Button, Colors, Display } from '../../../Components/Button/Button';
import { TextField } from '../../../Components/TextBox/TextBox';
import alert from '../../../../Util/ToastifyUtils';

interface IDuplicatePlaylistConfirmationProps {
	originalPlaylist: PlaylistSummary;
	newPlaylist: PlaylistSummary;
	duplicatePlaylist: (originalPlaylistId: string, playlistSummary: PlaylistSummary) => void;
}

const DuplicatePlaylistConfirmation = observer((props: IDuplicatePlaylistConfirmationProps) => {
	const { originalPlaylist, newPlaylist, duplicatePlaylist } = props;

	return (
		<div className="edit-confirmation-modal">
			<h3>
				Duplicate Playlist
			</h3>
			<form onSubmit={e => {
				e.preventDefault();
				if (newPlaylist.name.length === 0) {
					alert('Playlist name cannot be empty', 'error');
					return;
				}
				duplicatePlaylist(originalPlaylist.id, newPlaylist);
				store.modal.hide();
			}}
			>
				<TextField
					model={newPlaylist}
					modelProperty="name"
					label="New Playlist Name"
					isRequired
				/>
			</form>
			<div className="form-controls">
				
				<Button
					className="cancel"
					display={Display.Outline}
					colors={Colors.White}
					onClick={() => store.modal.hide()}
				>
					Cancel
				</Button>
				<Button
					className="submit"
					display={Display.Solid}
					colors={Colors.Primary}
					onClick={() => {
						if (newPlaylist.name.length === 0) {
							alert('Playlist name cannot be empty', 'error');
							return;
						}
						duplicatePlaylist(originalPlaylist.id, newPlaylist);
						store.modal.hide();
					}}
				>
					Submit
				</Button>
			</div>
		</div>
	);
});


export default DuplicatePlaylistConfirmation;
