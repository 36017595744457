import React from 'react';
import { RouteComponentProps } from 'react-router';
import Navigation, { Orientation } from '../../Components/Navigation/Navigation';
import { store } from '../../../Models/Store';
import SecuredPage from '../../Components/Security/SecuredPage';
import { getFrontendNavLinks } from '../../FrontendNavLinks';
import { ReportsTile } from './ReportsTile';
import classNames from 'classnames';

export const ReportsPage = (props: RouteComponentProps) => {
	const navigationLinks = getFrontendNavLinks(props);
	const {
		history, location, match, staticContext,
	} = props;
	
	return (
		<SecuredPage groups={['MustardManagement']}>
			<Navigation
				linkGroups={navigationLinks}
				orientation={Orientation.VERTICAL}
				match={match}
				location={location}
				history={history}
				staticContext={staticContext}
			/>
			<div className={classNames('body-content', 'logged-in', store.hasBackendAccess && 'body-admin')}>
				<ReportsTile />
			</div>
		</SecuredPage>
	);
};
