import React, { RefObject, useEffect, useRef } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { action, runInAction } from 'mobx';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import moment from 'moment';
import { contextMenu } from 'react-contexify';
import { DragDropContext } from 'react-beautiful-dnd';
import classNames from 'classnames';
import axios from 'axios';
import { useQueryClient } from 'react-query';
import { Tooltip } from '../../Components/Tooltip/Tooltip';
import LoadingContainer from '../../Components/LoadingContainer/LoadingContainer';
import { TextField } from '../../Components/TextBox/TextBox';
import {
	Button, Colors, Display, Sizes,
} from '../../Components/Button/Button';
import { store } from '../../../Models/Store';
import BankModal from './CreateBankModal';
import BankErrorModal from './BankUploadErrorModal';
import { BankEntity, IBankEntityAttributes, TrackEntity } from '../../../Models/Entities';
import { DELETE_BANK, GET_BANKS } from '../../../Util/GraphQLQueries';
import { ContextMenu } from '../../Components/ContextMenu/ContextMenu';
import BankTrackDetailsPanel from './BankTrackDetailsPanel';
import {
	getDestinationId, getSourceId, getTrackId, IEditorTrackLists,
} from '../../../Util/PlaylistUtils';
import { SERVER_URL } from '../../../Constants';
import alertToast from '../../../Util/ToastifyUtils';
import DeleteBankConfirmation from './DeleteBankConfirmation';
import { TrackList } from './TrackList';
import { PlaylistEditor } from '../Playlisting/PlaylistEditor';

interface BankStore {
	bank: BankEntity | undefined;
}
const defaultBankStore = {
  bank: undefined,
} as BankStore;

interface SavedBanks {
	banks: BankEntity | undefined
	banksHTML: JSX.Element | undefined
}
interface BanksStore {
	savedBanks: SavedBanks[]
}
const defaultBanksStore = {
	savedBanks:
		[{
			banks: undefined,
			banksHTML: undefined,
		}],
} as BanksStore;

interface ISortValues {
	path: 'created' | 'name' | 'ownerId',
	descending: boolean
}

export const UploadsTile = observer(() => {
	const queryClient = useQueryClient();
	// stores the search term used in the search panel
	const searchStore = useLocalStore(() => ({
		term: '',
	}));

	const sortStore = useLocalStore(() => ({
		path: 'created',
		descending: true,
	})) as ISortValues;

	const upload = useLocalStore(() => ({
		enabled: false,
	}));

	const resultStore = useLocalStore(() => ({
		banks: [] as BankEntity[],
		currentId: '',
		loaded: false,
	}));

	const trackStore = useLocalStore(() => ({
		currentTrack: new TrackEntity(),
	}));

	// stores the tracks that have bee selected with multiselect. numSelected is only used to force a rerender after selected has been modified.
	const multiSelectStore = useLocalStore(() => ({
		start: -1,
		end: -1,
		selected: [] as string[],
		numSelected: 0,
		collectionId: '',
	}));

	const clearMultiSelect = action(() => {
		multiSelectStore.start = -1;
		multiSelectStore.end = -1;
		multiSelectStore.selected = [];
		multiSelectStore.numSelected = 0;
		multiSelectStore.collectionId = '';
	});

	const multiSelect = action((
		event: React.MouseEvent<HTMLInputElement> | KeyboardEvent,
		index = -1,
		start: boolean,
		track: string,
		trackList: string[],
		collectionId: string,
	) => {
		if (multiSelectStore.collectionId !== collectionId) {
			clearMultiSelect();
			multiSelectStore.collectionId = collectionId;
		}

		// allows of adding or removing individual tracks
		if (event.ctrlKey || event.metaKey) {
			if (!multiSelectStore.selected.includes(track)) {
				multiSelectStore.selected.push(track);
				multiSelectStore.numSelected += 1;
			} else {
				multiSelectStore.selected = multiSelectStore.selected.filter(x => x !== track);
				multiSelectStore.numSelected -= 1;
			}
			multiSelectStore.start = index;
		}

		// add tracks in bulk
		if (event.shiftKey) {
			multiSelectStore.end = index;
			if (multiSelectStore.start < multiSelectStore.end) {
				const tracksToAdd = trackList
					.slice(multiSelectStore.start, multiSelectStore.end + 1)
					.filter(t => !multiSelectStore.selected.includes(t));
				multiSelectStore.selected.push(...tracksToAdd);
				multiSelectStore.numSelected += tracksToAdd.length;
			} else {
				const tracksToAdd = trackList
					.slice(multiSelectStore.end, multiSelectStore.start + 1)
					.filter(t => !multiSelectStore.selected.includes(t));
				multiSelectStore.selected.push(...tracksToAdd);
				multiSelectStore.numSelected += tracksToAdd.length;
			}
			multiSelectStore.start = index;
		}

		// if neither shift or ctrl, reset multiselected.
		if (!event.shiftKey && !(event.ctrlKey || event.metaKey)) {
			multiSelectStore.start = index;
			multiSelectStore.end = -1;
			multiSelectStore.selected = [track];
			multiSelectStore.numSelected = 0;
			multiSelectStore.numSelected = 1;
		}
	});

	// stores the current order of the tracks, which is then updated when a data fetch occurs.
	const editorTrackLists = useLocalStore(() => ({
		editor: {} as IEditorTrackLists,
	}));

	const addTracklist = action((playlistId: string, tracks: TrackEntity[]) => {
		editorTrackLists.editor[playlistId] = tracks;
	});

	const bankStore = useLocalStore(() => (defaultBankStore));

	const banksStore = defaultBanksStore;

	function compareBanks() {
		return (a: any, b: any) => {
			if (a.banks && b.banks) {
				switch (sortStore.path) {
					case 'created':
						// Sorts by Date or Alphanumerically if on the same date
						return sortStore.descending
							? (
								b.banks.created - a.banks.created
								|| a.banks.name.localeCompare(b.banks.name, undefined, { numeric: true })
							) : (
								a.banks.created - b.banks.created
								|| b.banks.name.localeCompare(a.banks.name, undefined, { numeric: true })
							);
					case 'name':
						// Sorts Alphanumerically or by date if the same name
						return sortStore.descending
							? (
								b.banks.name.localeCompare(a.banks.name, undefined, { numeric: true })
								|| b.banks.created.toLocaleDateString().localeCompare(a.banks.created.toLocaleDateString())
							) : (
								a.banks.name.localeCompare(b.banks.name, undefined, { numeric: true })
								|| b.banks.created.toLocaleDateString().localeCompare(a.banks.created.toLocaleDateString())
							);
					case 'ownerId':
						// Sorts by Owner or Newest if by the same creator or Alphanumerically if on same date
						return sortStore.descending
							? (
								b.banks.ownerId.localeCompare(a.banks.ownerId)
								|| b.banks.created.toLocaleDateString().localeCompare(a.banks.created.toLocaleDateString())
								|| b.banks.name.localeCompare(a.banks.name, undefined, { numeric: true })
							) : (
								a.banks.ownerId.localeCompare(b.banks.ownerId)
								|| b.banks.created.toLocaleDateString().localeCompare(a.banks.created.toLocaleDateString())
								|| b.banks.name.localeCompare(a.banks.name, undefined, { numeric: true })
							);
					default:
						return 0;
				}
			}
			return 0;
		};
	}

	// Creates the Bank Cards as seen in the list
	const createBankCard = (bank: BankEntity) => (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
		<div
			className={classNames(bankStore.bank?.id === bank.id && 'active', 'bank-detail-container')}
			onClick={() => {
				runInAction(() => {
					bankStore.bank = bank;
				});
			}}
			key={`bank-${bank.id}`}
		>
			<div className="bank-details">
				<div className="bank-name">{bank.name}</div>
				<div className="bank-uploader">
					{bank.ownerName}
					<br />
					<b>Total Tracks:</b>
					{' '}
					{bank.totalTrackCount}
					<br />
					{moment(bank.created).format('Do MMM YYYY')}
				</div>
			</div>

			<div className="input-group">
				{(bank.isProcessing && (
					<Tooltip
						id={`tooltip-uploading-${bank.id}`}
						content="Upload In Progress"
						icon="icon-upload"
						iconPosition="icon-top"
						customClasses="green"
						contentPointer={false}
					/>
				)) || ((bank.hasErrors && (
					<Tooltip
						id={`tooltip-errors-${bank.id}`}
						content="Upload failed"
						icon="icon-upload"
						iconPosition="icon-top"
						customClasses="red"
						contentPointer={false}
						onClick={() => store.modal.show(
							'Bank',
							<BankErrorModal bankId={bank.id} />,
							{ className: 'slideout-panel-right' },
						)}
					/>
				))
				)}
				{bank.requiresSorting && (
					<Tooltip
						id={`tooltip-sorted-${bank.id}`}
						content="Has Unsorted Tracks"
						icon="icon-warning"
						iconPosition="icon-top"
						contentPointer={false}
					/>
				)}
			</div>
		</div>
	);

	const banksAreEqual = (sourceBank: BankEntity, targetBank: BankEntity | undefined): boolean => {
		if (targetBank === undefined) return false;
		if (targetBank?.name !== sourceBank.name) return false;
		if (targetBank?.isProcessing !== sourceBank.isProcessing) return false;
		if (targetBank?.totalTrackCount !== sourceBank.totalTrackCount) return false;
		if (targetBank?.requiresSorting !== sourceBank.requiresSorting) return false;
		if (targetBank?.hasErrors !== sourceBank.hasErrors) return false;
		if (targetBank?.hasValidationError !== sourceBank.hasValidationError) return false;

		return true;
	};

	// Updates values when resultStore is updated and renders HTML
	const searchResults = () => {
		const { banks } = resultStore;
		const { savedBanks } = banksStore;
		const { loaded } = resultStore;

		if (!loaded) {
			return (
				<LoadingContainer name="Banks" />
			);
		}
		if (!banks.length) {
			return (
				<div className="search-placeholder">
					<h5>No banks match the search terms.</h5>
				</div>
			);
		}
		runInAction(() => {
			banks.forEach(newBank => {
				const prevSave = savedBanks.find(savedBank => newBank.id === savedBank.banks?.id) ?? undefined;
				if (prevSave) {
					// Compare banks and Overwrite if different
					const index = savedBanks.indexOf(prevSave);
					if (!banksAreEqual(newBank, prevSave.banks) || bankStore.bank?.id === newBank.id) {
						savedBanks[index] = { banks: newBank, banksHTML: createBankCard(newBank) };
					} else {
						const html = savedBanks[index].banksHTML;
						if ((html?.props.className ?? '' as string).includes('active')) {
							savedBanks[index] = { banks: newBank, banksHTML: createBankCard(newBank) };
						}
					}
				} else {
					// If there are no previous saves, add to the end of the HTML
					savedBanks.push({ banks: newBank, banksHTML: createBankCard(newBank) });
				}
			});
		});
		if (searchStore.term) {
			const filteredSavedBanks = savedBanks
				.filter(savedBank => savedBank.banks?.name
					.toLocaleLowerCase()
					.includes(searchStore.term.toLowerCase()))
				.sort(compareBanks());
			return filteredSavedBanks.map(b => b.banksHTML);
		}

		const sendBanks = savedBanks.slice().sort(compareBanks());
		return sendBanks.map(b => b.banksHTML);
	};

	// Fetch and Pagination
	const offset = 10;
	let skip = offset * (store.currentPage - 1);

	const listInnerRef = useRef() as RefObject<HTMLDivElement>;

	// Set tolerance for scroll height match
	const scrollHeightTolerance = 5;

	// fetchBanks appends new banks to an existing banks array. To help reduce load times.
	let banks: BankEntity[] = [];
	const fetchBanks = () => store.apolloClient.query<{bankEntitys: IBankEntityAttributes[]}>({
		query: GET_BANKS,
		fetchPolicy: 'network-only',
		variables: {
			skip,
			take: offset,
			orderBy: [
				{ path: sortStore.path, descending: sortStore.descending },
			],
			args: [
				{
					path: 'name',
					comparison: 'like',
					value: `%${searchStore.term}%`,
					case: 'INVARIANT_CULTURE_IGNORE_CASE',
				},
			],
		},
	}).then(res => runInAction(() => {
		const previousBanksLength = banks.length;
		const newBanks: BankEntity[] = res.data.bankEntitys.map((p: IBankEntityAttributes) => new BankEntity(p)) as BankEntity[];
		banks = banks.concat(newBanks.filter(a => !banks.find(b => b.id === a.id)));
		if (banks.length <= previousBanksLength) store.currentPage -= 1;
		resultStore.banks = banks;
		resultStore.loaded = true;
	}));

	// On scroll, we check if we've reached the bottom of the div
	// As a side note this fires on every increment of scroll. Don't put anything in here without if statements.
	const onScroll = AwesomeDebouncePromise(() => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
			if (scrollTop + clientHeight > (scrollHeight - scrollHeightTolerance)) {
        // Handles most of the value changes
        runInAction(() => {
					if (!store.currentPage) store.currentPage = 1;
          store.currentPage += 1;
          skip = offset * (store.currentPage - 1);
          banks = resultStore.banks;
          fetchBanks();
				});
			}
		}
	}, 200);

	// fetchSearch resets values and allows you to upload banks
	const fetchSearch = AwesomeDebouncePromise(async (newBank?: BankEntity) => store.apolloClient.query<{bankEntitys: IBankEntityAttributes[]}>({
		query: GET_BANKS,
		fetchPolicy: 'network-only',
		variables: {
			skip: 0,
			take: offset,
			orderBy: [
				{ path: sortStore.path, descending: sortStore.descending },
			],
			args: [
				{
					path: 'name',
					comparison: 'like',
					value: `%${searchStore.term}%`,
					case: 'INVARIANT_CULTURE_IGNORE_CASE',
				},
			],
		},
	}).then(res => {
		if (searchStore.term.length > 2 || searchStore.term.length === 0) { // MMSD-95
			runInAction(() => {
				store.currentPage = 1;
				resultStore.banks = res.data.bankEntitys.map((p: IBankEntityAttributes) => new BankEntity(p)) as BankEntity[];
				resultStore.loaded = true;
			});

			if (newBank) {
				runInAction(() => {
					bankStore.bank = newBank;
				});
			}
		}
	}), 1000);

	const deleteBank = (): void => {
		store.apolloClient
			.mutate({
				mutation: DELETE_BANK,
				variables: { bankEntityIds: bankStore.bank?.id },
			}).then(() => {
				runInAction(() => {
					bankStore.bank = new BankEntity();

					store.apolloClient.clearStore();
					fetchSearch();
				});
			});
	};

	const archiveTrack = (): void => {
		const { currentTrack } = trackStore;

		runInAction(() => { currentTrack.archived = true; });

		new TrackEntity(currentTrack)
			.save()
			.then(() => {
				runInAction(() => {
					trackStore.currentTrack = new TrackEntity();
					queryClient.refetchQueries(['playlist']);
				});
			});
	};

	useEffect(() => {
		const fetchPermission = async (): Promise<boolean> => {
			store.getCanUpload().then(res => {
				runInAction(() => {
					upload.enabled = res;
				});
			});

			return store.getCanUpload();
		};

		fetchPermission();
		fetchSearch();
	}, []);

	const onDragEnd = action((result: any) => {
		if (result.destination === null || result.destination.droppableId.includes('source')) {
			return;
		}

		const sourceId = getSourceId(result.source.droppableId);
		const destinationId = getDestinationId(result.destination.droppableId);
		const trackId = getTrackId(result.draggableId);

		if (sourceId === destinationId) {
			return;
		}
		// otherwise it's adding a track
		axios.post(`${SERVER_URL}/api/playlist_search/add_tracks`, {
			trackIds: multiSelectStore.selected.length ? multiSelectStore.selected : [trackId],
			playlistId: destinationId,
		}).then(() => {
			alertToast(`Successfully added ${multiSelectStore.selected.length} track(s)`, 'success');
			queryClient.refetchQueries(['playlist_contents', destinationId]);
			queryClient.refetchQueries(['summary', destinationId]);
			queryClient.refetchQueries(['collection']);
		}).catch(err => {
			alertToast('Failed to add tracks', 'error');
		});
	});

	return (
		<div className={`uploads-container ${store.hasBackendAccess ? 'admin' : ''}`}>
			<DragDropContext onDragEnd={onDragEnd}>
				<div className="bank-management-group">
					<div className="search-panel-container uploads">
						<div className="search">
							<div className="search-header">
								<h4>Unsorted Banks</h4>
								{upload.enabled && (
									<Button
										display={Display.Solid}
										sizes={Sizes.Small}
										colors={Colors.Primary}
										icon={{ icon: 'create', iconPos: 'icon-right' }}
										buttonProps={{
											onClick: (): void => {
												store.modal.show(
													'Bank',
													<BankModal callback={fetchSearch} />,
													{ className: 'slideout-panel-right' },
												);
											},
										}}
									>
										Upload Bank
									</Button>
								)}
							</div>
							<div className="search-filter-container">
								<TextField
									model={searchStore}
									modelProperty="term"
									className="search-term search-panel"
									placeholder="Search by bank name"
									onAfterChange={() => fetchSearch()}
								/>
								<Button
									className="search-filter"
									data-testid="filter-button-search-panel"
									display={Display.Solid}
									sizes={Sizes.Medium}
									colors={Colors.Grey}
									icon={{ icon: 'order', iconPos: 'icon-left' }}
									onClick={e => contextMenu.show({ event: e, id: 'context-menu-bank-search' })}
								/>
								<ContextMenu
									menuId="context-menu-bank-search"
									actions={[
										{
											label: 'Newest',
											onClick: (): void => {
												runInAction(() => {
													sortStore.path = 'created';
													sortStore.descending = true;
												});

												fetchBanks();
											},
										},
										{
											label: 'Bank Name',
											onClick: (): void => {
												runInAction(() => {
													sortStore.path = 'name';
													sortStore.descending = !sortStore.descending;
												});

												fetchBanks();
											},
										},
										{
											label: 'Oldest',
											onClick: (): void => {
												runInAction(() => {
													sortStore.path = 'created';
													sortStore.descending = false;
												});

												fetchBanks();
											},
										},
										{
											label: 'Uploader name',
											onClick: (): void => {
												runInAction(() => {
													sortStore.path = 'ownerId';
													sortStore.descending = true;
												});

												fetchBanks();
											},
										},
									]}
								/>
							</div>
						</div>
						{!resultStore.banks ? (
							<div className="search-placeholder">
								<h5>Search for Banks.</h5>
							</div>
						) : (
							<div className="track-container" ref={listInnerRef} onScroll={onScroll}>
								{searchResults()}
							</div>
						)}
					</div>

					{!!bankStore.bank?.id && (
						<TrackList
							multiSelectStore={multiSelectStore}
							multiSelect={multiSelect}
							header={(
								<div className="bankHeaderContainer">
									<h5>{bankStore.bank?.name}</h5>
									<button
										aria-label="context-menu"
										className="icon-more-vertical icon-top"
										data-testid="context-menu-delete-bank"
										onClick={e => contextMenu.show({ event: e, id: 'context-menu-delete-bank' })}
									/>
									<ContextMenu
										menuId="context-menu-delete-bank"
										actions={[
											{
												label: 'Delete Bank',
												onClick: (): void => {
													if (store.userGroups.some(ug => ug.name === 'AgencyPlaylister')) {
														alertToast(
															'You do not have permission to delete a bank.',
															'error',
														);
													} else {
														store.modal.show(
															'Delete Bank Confirmation',
															<DeleteBankConfirmation
																bankName={bankStore.bank?.name ?? ''}
																deleteBank={deleteBank}
															/>,
														);
													}
												},
											},
										]}
									/>
								</div>
							)}
							bankId={bankStore.bank.id}
							customTrackClickEvent={(t: TrackEntity): void => runInAction(() => {
								trackStore.currentTrack = t;
							})}
							order
						/>
					)}

					{!!trackStore.currentTrack.id && (
						<BankTrackDetailsPanel
							archiveTrack={archiveTrack}
							track={trackStore.currentTrack}
							showFadeSection
						/>
					)}
				</div>

				<PlaylistEditor
					isExpanded={false}
					hasExpanded={false}
					expandEditor={() => null}
					multiSelect={multiSelect}
					multiSelectStore={multiSelectStore}
					clearMultiSelect={clearMultiSelect}
					editorTrackLists={editorTrackLists}
					addTracklist={addTracklist}
					disableExpand
				/>
			</DragDropContext>
		</div>
	);
});
