/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AgencyPlaylisterPlaylistEntity from 'Models/Security/Acl/AgencyPlaylisterPlaylistEntity';
import MustardManagementPlaylistEntity from 'Models/Security/Acl/MustardManagementPlaylistEntity';
import VisitorsPlaylistEntity from 'Models/Security/Acl/VisitorsPlaylistEntity';
import AgencyAdminPlaylistEntity from 'Models/Security/Acl/AgencyAdminPlaylistEntity';
import ApplicationUserPlaylistEntity from 'Models/Security/Acl/ApplicationUserPlaylistEntity';
import MustardAdminPlaylistEntity from 'Models/Security/Acl/MustardAdminPlaylistEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IPlaylistEntityAttributes extends IModelAttributes {
	name: string;
	previousVersion: string;
	agencyOwnerId: string;

	tagss: Array<
		| Models.TagsPlaylistTags
		| Models.ITagsPlaylistTagsAttributes
	>;
	playlistTrackss: Array<
		| Models.PlaylistTrackEntity
		| Models.IPlaylistTrackEntityAttributes
	>;
	agencyTagss: Array<
		| Models.AgencyTagsPlaylists
		| Models.IAgencyTagsPlaylistsAttributes
	>;
	moodVersionss: Array<
		| Models.MoodVersionsPlaylists
		| Models.IMoodVersionsPlaylistsAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] on begin
	agencyOwnerName: string;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('PlaylistEntity', 'Playlist')
// % protected region % [Customise your entity metadata here] end
export default class PlaylistEntity extends Model
	implements IPlaylistEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AgencyPlaylisterPlaylistEntity(),
		new MustardManagementPlaylistEntity(),
		new VisitorsPlaylistEntity(),
		new AgencyAdminPlaylistEntity(),
		new ApplicationUserPlaylistEntity(),
		new MustardAdminPlaylistEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'name',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@observable
	@attribute<PlaylistEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Previous Version'] off begin
	@observable
	@attribute<PlaylistEntity, string>()
	@CRUD({
		name: 'Previous Version',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public previousVersion: string;
	// % protected region % [Modify props to the crud options here for attribute 'Previous Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Agency Owner Id'] on begin
	@Validators.Uuid()
	@observable
	@attribute<PlaylistEntity, string>()
	@CRUD({
		name: 'Agency Owner Id',
		displayType: 'reference-combobox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		referenceTypeFunc: () => Models.AgencyEntity,
		displayFunction(_attribute, that) {
			return (that as PlaylistEntity).agencyOwnerName;
		},
	})
	public agencyOwnerId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Agency Owner Id'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Tags'] off begin
		name: 'Tags',
		displayType: 'reference-multicombobox',
		order: 40,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.TagsPlaylistTags,
		optionEqualFunc: makeJoinEqualsFunc('tagsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'playlistEntity',
			oppositeEntityName: 'tagEntity',
			relationName: 'playlistTags',
			relationOppositeName: 'tags',
			entity: () => Models.PlaylistEntity,
			joinEntity: () => Models.TagsPlaylistTags,
			oppositeEntity: () => Models.TagEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Tags'] end
	})
	public tagss: Models.TagsPlaylistTags[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Playlist Tracks'] off begin
		name: 'Playlist Trackss',
		displayType: 'reference-multicombobox',
		order: 50,
		referenceTypeFunc: () => Models.PlaylistTrackEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'playlistTrackss',
			oppositeEntity: () => Models.PlaylistTrackEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Playlist Tracks'] end
	})
	public playlistTrackss: Models.PlaylistTrackEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Agency Tags'] off begin
		name: 'Agency Tags',
		displayType: 'reference-multicombobox',
		order: 60,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.AgencyTagsPlaylists,
		optionEqualFunc: makeJoinEqualsFunc('agencyTagsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'playlistEntity',
			oppositeEntityName: 'agencyTagEntity',
			relationName: 'playlists',
			relationOppositeName: 'agencyTags',
			entity: () => Models.PlaylistEntity,
			joinEntity: () => Models.AgencyTagsPlaylists,
			oppositeEntity: () => Models.AgencyTagEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Agency Tags'] end
	})
	public agencyTagss: Models.AgencyTagsPlaylists[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Mood Versions'] off begin
		name: 'Mood Versions',
		displayType: 'reference-multicombobox',
		order: 70,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.MoodVersionsPlaylists,
		optionEqualFunc: makeJoinEqualsFunc('moodVersionsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'playlistEntity',
			oppositeEntityName: 'moodVersionEntity',
			relationName: 'playlists',
			relationOppositeName: 'moodVersions',
			entity: () => Models.PlaylistEntity,
			joinEntity: () => Models.MoodVersionsPlaylists,
			oppositeEntity: () => Models.MoodVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Mood Versions'] end
	})
	public moodVersionss: Models.MoodVersionsPlaylists[] = [];

	// % protected region % [Add any custom attributes to the model here] on begin
	public agencyOwnerName: string;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IPlaylistEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] on begin
		// This is assigned in assignAttributes but somehow
		// it doesn't show in CRUD tables calling the following
		// this.models = data[dataModelName].map((e: any) => new ModelType(e));
		// Putting this here assigns it correctly
		if (attributes?.agencyOwnerName) {
			this.agencyOwnerName = attributes.agencyOwnerName;
		}
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IPlaylistEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.previousVersion !== undefined) {
				this.previousVersion = attributes.previousVersion;
			}
			if (attributes.agencyOwnerId !== undefined) {
				this.agencyOwnerId = attributes.agencyOwnerId;
			}
			if (attributes.tagss !== undefined && Array.isArray(attributes.tagss)) {
				for (const model of attributes.tagss) {
					if (model instanceof Models.TagsPlaylistTags) {
						this.tagss.push(model);
					} else {
						this.tagss.push(new Models.TagsPlaylistTags(model));
					}
				}
			}
			if (attributes.playlistTrackss !== undefined && Array.isArray(attributes.playlistTrackss)) {
				for (const model of attributes.playlistTrackss) {
					if (model instanceof Models.PlaylistTrackEntity) {
						this.playlistTrackss.push(model);
					} else {
						this.playlistTrackss.push(new Models.PlaylistTrackEntity(model));
					}
				}
			}
			if (attributes.agencyTagss !== undefined && Array.isArray(attributes.agencyTagss)) {
				for (const model of attributes.agencyTagss) {
					if (model instanceof Models.AgencyTagsPlaylists) {
						this.agencyTagss.push(model);
					} else {
						this.agencyTagss.push(new Models.AgencyTagsPlaylists(model));
					}
				}
			}
			if (attributes.moodVersionss !== undefined && Array.isArray(attributes.moodVersionss)) {
				for (const model of attributes.moodVersionss) {
					if (model instanceof Models.MoodVersionsPlaylists) {
						this.moodVersionss.push(model);
					} else {
						this.moodVersionss.push(new Models.MoodVersionsPlaylists(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.agencyOwnerName !== undefined) {
				this.agencyOwnerName = attributes.agencyOwnerName;
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		tagss {
			${Models.TagsPlaylistTags.getAttributes().join('\n')}
			tags {
				${Models.TagEntity.getAttributes().join('\n')}
			}
		}
		agencyTagss {
			${Models.AgencyTagsPlaylists.getAttributes().join('\n')}
			agencyTags {
				${Models.AgencyTagEntity.getAttributes().join('\n')}
			}
		}
		moodVersionss {
			${Models.MoodVersionsPlaylists.getAttributes().join('\n')}
			moodVersions {
				${Models.MoodVersionEntity.getAttributes().join('\n')}
			}
		}
		playlistTrackss {
			${Models.PlaylistTrackEntity.getAttributes().join('\n')}
			track {
				title
			}
		}
	`;

	public listExpands = `
		agencyOwnerName
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			tagss: {},
			agencyTagss: {},
			moodVersionss: {},
			playlistTrackss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'tagss',
							'agencyTagss',
							'moodVersionss',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(PlaylistEntity.prototype, 'created');
CRUD(modifiedAttr)(PlaylistEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
