import React, { useEffect, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { runInAction } from 'mobx';
import AlbumArtPlaceholder from 'assets/empty-album-art.svg';
import AudioControls from './AudioControls';
import { SERVER_URL } from '../../../Constants';
import { store } from '../../../Models/Store';
import Waveform from './Waveform';
import {
	Button, Colors, Display, Sizes, 
} from '../Button/Button';

const AudioPlayer = observer((): JSX.Element => {
	const { track } = store;

	const [trackVolume, setTrackVolume] = useState(0.5);
	const [isPlaying, setIsPlaying] = useState(false);

	const fades = useLocalStore(() => ({
		fadeInMinutes: 0,
		fadeInSeconds: 0,
		fadeOutMinutes: 0,
		fadeOutSeconds: 0,
		enabled: false,
	}));

	let title = 'No track selected';
	let audioId = '';

	if (track.title) {
		title = track.title;
		audioId = track.audioId;
	}

	const audioSrc = `${SERVER_URL}/api/files/${audioId}`;

	const fetchPermission = async (): Promise<boolean> => {
		store.getCanSetFades().then(res => {
			runInAction(() => {
				fades.enabled = res;
			});
		});

		return store.getCanUpload();
	};

	useEffect(() => {
		runInAction(() => {
			fades.fadeInMinutes = track.fadeIn ? Math.floor(track.fadeIn / 60) : 0;
			fades.fadeOutMinutes = track.fadeOut ? Math.floor(track.fadeOut / 60) : 0;

			// Account for wacky float logic
			fades.fadeInSeconds = track.fadeIn
				? track.fadeIn - Math.floor(track.fadeIn / 60) * 60
				: 0;
			fades.fadeOutSeconds = track.fadeOut
				? track.fadeOut - Math.floor(track.fadeOut / 60) * 60
				: 0;
		});
	}, [track, track.fadeIn, track.fadeOut]);

	useEffect(() => {
		if (store.pause) {
			setIsPlaying(false);
		} else {
			setIsPlaying(true);
		}
	}, [store.pause]);

	useEffect(() => {
		runInAction(() => {
			store.pause = !isPlaying;
		});
	}, [isPlaying]);

	useEffect(() => {
		async function fetch() {
			await fetchPermission();
		}

		fetch();
	}, []);

	const onVolumeChange = (value: number): void => {
		setTrackVolume(value || 0);
	};

	return (
		<div className="audio-player">
			<div className="details-controls-container">
				<div className="song-details-container">
					<div className="song-details">
						<div className="track-label art">
							<img
								src={
									!track.album?.artworkId
										? AlbumArtPlaceholder
										: `${SERVER_URL}/api/files/${track.album.artworkId}`
								}
								alt="album-art"
							/>

							<div className="text">
								<p className="title" unselectable="on">
									{title}
								</p>
								<p unselectable="on">
									{track.artistss.map(a => a.artists.name).join(', ')}
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="audio-controls-container">
					{!!track.audioId && fades.enabled && (
						<Button
							className="show-fade-button"
							sizes={Sizes.ExtraSmall}
							colors={Colors.Secondary}
							display={Display.Outline}
							onClick={() => {
								runInAction(() => {
									store.showFadesInAudioPlayer = !store.showFadesInAudioPlayer;
								});
							}}
						>
							{store.shouldShowFadesInAudioPlayer
								? 'Close Fades'
								: 'Edit Fades'}
						</Button>
					)}

					<AudioControls
						interact={!!track.audioId}
						isPlaying={isPlaying}
						onPlayPauseClick={setIsPlaying}
					/>

					<Waveform
						interact={!!track.audioId}
						startTime={track.fadeIn}
						endTime={track.fadeOut}
						playing={isPlaying}
						url={audioSrc}
						volume={trackVolume}
						waveform={track.waveform}
						duration={track.length}
					/>

					<div className="icon-loudspeaker icon-top" />

					<input
						type="range"
						value={trackVolume}
						step="0.01"
						min="0"
						max="1"
						className="volume"
						onChange={(e): void => onVolumeChange(Number(e.target.value))}
					/>
				</div>
			</div>
		</div>
	);
});

export default AudioPlayer;
