import { observer } from "mobx-react";
import { ProfileEntity } from "../../../../Models/Entities";
import { store } from "../../../../Models/Store";
import {
  Button,
  Colors,
  Display,
  Sizes,
} from "../../../Components/Button/Button";

interface ProfileDisplayProps {
  profile: ProfileEntity;
}

const ProfileDisplayTile = observer(
  (props: ProfileDisplayProps): JSX.Element => {
    const { profile } = props;

    // None chosen
    if (!profile) {
      return <></>;
    }

    const goToEditPage = (): void => {
      store.routerHistory.push(`/profile-management/edit/${profile.id}`);
    };

    return (
      <div className="profile-tile">
        <div className="profile-tile-name">{profile.name}</div>
        <Button
          display={Display.Outline}
          colors={Colors.Primary}
          sizes={Sizes.ExtraSmall}
          onClick={goToEditPage}
          className="open-profile-button"
        >
          Manage Profile
        </Button>
      </div>
    );
  }
);

export default ProfileDisplayTile;
