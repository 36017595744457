/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { action, observable } from 'mobx';
import { Tooltip } from '../Tooltip/Tooltip';
import If from '../If/If';
import { DisplayType } from '../Models/Enums';

// % protected region % [Add extra imports or definitoins here] off begin
// % protected region % [Add extra imports or definitoins here] end

export enum InputType {
	INPUT = 'input',
	TEXTAREA = 'textarea',
	CHECKBOX = 'checkbox',
	RADIO = 'radio',
	DATE = 'date',
	TIME = 'time',
	PASSWORD = 'password',
}

interface IInputWrapperProps {
	isInputGroup?: boolean;
	wrapperId?: string;
	id?: string;
	inputType?: InputType;
	inputName?: string;
	inputId?: string;
	label?: ILabelOptions | React.ReactNode;
	labelVisible?: boolean;
	className?: string;
	displayType?: DisplayType;
	isRequired?: boolean;
	staticInput?: boolean;
	tooltip?: string;
	subDescription?: string;
	errors?: string | string[];
	innerProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
	sideButton?: React.ReactNode;
	children: React.ReactNode;
	// % protected region % [Add extra inputwrapper props here] off begin
	// % protected region % [Add extra inputwrapper props here] end
}

export enum LabelPositions {
	BEFORE,
	AFTER,
}

export interface ILabelOptions {
	node?: React.ReactNode;
	text: string;
	position?: LabelPositions;
	// % protected region % [Add extra lable options here] off begin
	// % protected region % [Add extra lable options here] end
}
// % protected region % [Customize isLabelOptions here] off begin
function isLabelOptions(options: React.ReactNode | ILabelOptions): options is ILabelOptions {
	return options !== null
		&& options !== undefined
		&& typeof options === 'object'
		&& 'text' in options;
}
// % protected region % [Customize isLabelOptions here] end

// % protected region % [Customize Errors component here] off begin
function Errors({ errors: innerErrors }: {errors?: string | string[]}) {
	if (!innerErrors) {
		return null;
	}

	if (typeof innerErrors === 'string') {
		return (
			<div className="input-group__error-text">
				<p>{innerErrors}</p>
			</div>
		);
	}

	return (
		<div className="input-group__error-text">
			{innerErrors.map((error, idx) => {
				const key = `${error}${idx}`;
				return <p key={key}>{error}</p>;
			})}
		</div>
	);
}
// % protected region % [Customize Errors component here] end

@observer
class InputWrapper extends React.Component<IInputWrapperProps> {
	// % protected region % [Add extra options class properties here] off begin
	// % protected region % [Add extra options class properties here] end

	@observable
	private focused: boolean;

	@observable
	private isHovered: boolean;

	public render() {
		// % protected region % [Customize options render here] off begin
		const {
			isInputGroup,
			wrapperId,
			inputType,
			id,
			label,
			children,
			className,
			displayType,
			inputId,
			isRequired,
			staticInput,
			sideButton,
			tooltip,
			subDescription,
			errors,
			innerProps,
			labelVisible = true,
		} = this.props;

		const tooltipId = `${id}-tooltip`;
		const subDescriptionId = `${id}-sub-description`;

		let labelPosition: LabelPositions | undefined;
		let labelInnerNode: React.ReactNode;
		let labelText: string | undefined;
		if (isLabelOptions(label)) {
			labelPosition = label.position ?? LabelPositions.BEFORE;
			labelInnerNode = label.node ?? label.text;
			labelText = label.text;
		} else if (label !== undefined && label !== null) {
			labelPosition = LabelPositions.BEFORE;
			labelInnerNode = label;
			if (typeof label === 'string') {
				labelText = label;
			}
		}

		const requiredMark = (label && isRequired) ? <span className="required">*</span> : undefined;
		const labelNode = label ? <label htmlFor={inputId}>{labelInnerNode}{requiredMark}</label> : '';

		const classes = classNames(
			className,
			`input-group-${displayType ?? DisplayType.BLOCK}`,
			isRequired ? 'input-group--is-required' : null,
			tooltip ? 'input-group--tooltip' : null,
			!isInputGroup ? 'input-group' : null,
			inputType ? `input-group__${inputType}` : null,
			(errors && (typeof errors === 'string' || errors.some(e => !!e))) ? 'input-group--error' : null,
			staticInput ? 'input-group--static' : null,
		);

		const tooltipNode = tooltip ? <Tooltip id={tooltipId} content={tooltip} /> : '';
		const subDescriptionNode = (!tooltip && subDescription) ? <p id={subDescriptionId}>{subDescription}</p> : '';

		return (
			<div
				id={wrapperId}
				className={classes}
				onFocus={this.onFocus}
				onBlur={this.onBlue}
				onMouseEnter={this.handleHover}
				onMouseLeave={this.handleHover}
				aria-label={labelText}
				{...innerProps}
			>
				<If condition={labelVisible && labelPosition === LabelPositions.BEFORE}>
					{labelNode}
				</If>
				{
					sideButton
						? (
							<div className="side-button-container">
								{children}
								{sideButton}
							</div>
						)
						: children
				}
				<If condition={labelVisible && labelPosition === LabelPositions.AFTER}>
					{labelNode}
				</If>
				{tooltipNode}
				{subDescriptionNode}
				<Errors errors={errors} />
			</div>
		);
		// % protected region % [Customize options render here] end
	}

	@action
	private onFocus = () => {
		this.focused = true;
	};

	@action
	private onBlue = () => {
		this.focused = false;
	};

	@action
	private handleHover = () => {
		this.isHovered = !this.isHovered;
	};

	private setFocusClass = (classes?: string) => {
		if (this.focused) {
			return classNames('input-group--focus', classes);
		}
		return classes;
	};

	private setHoverClass = (classes?: string) => {
		if (this.isHovered) {
			return classNames('input-group--hover', classes);
		}
		return classes;
	};

	// % protected region % [Add extra methods here] off begin
	// % protected region % [Add extra methods here] end
}
export default InputWrapper;
