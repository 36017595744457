import React from 'react';
import { runInAction } from 'mobx';
import { InfiniteData } from 'react-query';
import { observer } from 'mobx-react';
import { Button, Colors, Display } from '../../Components/Button/Button';
import { PlaylistSearchResultObject, PlaylistSummary } from '../../../Util/PlaylistUtils';

interface IPlaylistSearchBeardProps {
	data: InfiniteData<PlaylistSearchResultObject> | undefined;
	openPlaylists: PlaylistSummary[];
	openPlaylist: (playlist: PlaylistSummary) => void;
	clearSearchTerm: () => void;
}

const PlaylistSearchBeard = observer((props: IPlaylistSearchBeardProps) => {
	const { 
		data, openPlaylists, openPlaylist, clearSearchTerm, 
	} = props;
	
	const pages = data?.pages;
	let listOfPlaylists = [] as PlaylistSummary[];

	pages?.forEach(list => {
		if (list.playlists) {
			listOfPlaylists = listOfPlaylists.concat(list.playlists);
		}
	});

	if (listOfPlaylists.length) {
		return (
			<>
				{listOfPlaylists.map(p => {
					if (p) {
						return (
							<div 
								className="result-item beard-item" 
								key={`beard-item-${p.id}`}
								onClick={() => runInAction(() => {
									if (!openPlaylists.map(playlist => playlist.id).includes(p.id)) {
										openPlaylist(p);
									}
									clearSearchTerm();
								})}
							>
								<p className="name">{p.name}</p>
								<p className="summary">{p.summary}</p>
							</div>
						);
					} 
					return <></>;
				})}
			</>
		);
	}
	return <></>;
});

export default PlaylistSearchBeard;
