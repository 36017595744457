import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import FadeControls from 'Views/Components/AudioPlayer/FadeControls';
import Axios, { AxiosResponse } from 'axios';
import { SERVER_URL } from 'Constants';
import LoadingContainer from 'Views/Components/LoadingContainer/LoadingContainer';
import { runInAction } from 'mobx';
import {
  GenreEntity,
  ITrackEntityAttributes,
  TrackEntity,
} from '../../../Models/Entities';
import { store } from '../../../Models/Store';
import TrackTile from '../Playlisting/TrackTile';
import { MultiCombobox } from '../../Components/Combobox/MultiCombobox';

interface BankTrackDetailsPanelProps {
  track: TrackEntity;
  archiveTrack?: () => void;
  showFadeSection?: boolean;
  closePanel?: () => void;
}

const BankTrackDetailsPanel = observer((props: BankTrackDetailsPanelProps) => {
  const { archiveTrack, closePanel, showFadeSection } = props;

  const [canSetFades, setCanSetFades] = useState(false);
  const [trackLoaded, setTrackLoaded] = useState(false);
  const [track, setTrack] = useState(props.track);
  const [primaryGenres, setPrimaryGenres] = useState([] as GenreEntity[]);

  // Use Effect to fetch tags for the track selected
  useEffect(() => {
    const fetchTrackDetails = async () => Axios.get(
        `${SERVER_URL}/api/playlist_search/get_track_tags/${props.track.id}`,
      );

    fetchTrackDetails()
      .then((res: AxiosResponse<ITrackEntityAttributes>) => {
        setTrack(new TrackEntity(res.data));
        setTrackLoaded(true);
      })
      .catch(() => {
        setTrack(props.track);
        setTrackLoaded(false);
      });
  }, [props.track]);

  useEffect(() => {
    // Check set fades permission;
    store.getCanSetFades().then(res => {
      setCanSetFades(res);
    });

    // Fetch the primary genre list
    const fetchPrimaryGenres = async () => Axios.get(`${SERVER_URL}/api/entity/GenreEntity`);
    fetchPrimaryGenres()
      .then(genreResponse => {
        setPrimaryGenres(genreResponse.data.data);
      })
      .catch(() => {
        // We show an empty list if failed to load genres
      });
  }, []);

  return (
    <div className="bank-track-details-container">
      <div className="bank-track-details-header">
        <TrackTile
          track={track}
          archiveTrack={archiveTrack}
          closePanel={closePanel}
          contextMenu
        />
      </div>

      {showFadeSection && canSetFades && (
        <div className="fades-panel">
          <div className="fade-row-header">
            <p>SET FADES</p>
            {!track.sorted && <div className="icon-warning icon-top" />}
          </div>

          <FadeControls
            track={track?.id === store.track?.id ? store.track : track}
            flexDirection="column"
          />
        </div>
      )}

      {trackLoaded ? (
        <div className="tags-panel">
          <p>Explicit</p>
          <div className="explicit-container">
            <label className="switch">
              <input
                type="checkbox"
                checked={track.isExplicit}
                onChange={() => {
                  runInAction(() => {
                    track.isExplicit = !(track.isExplicit ?? false);
                    // save isExplicit to db
                    Axios.post(
                      `${SERVER_URL}/api/entity/TrackEntity/updateTrackExplicit/${track.id}/${track.isExplicit}`,
                    ).catch(err => console.error(err));
                  });
                }}
              />
              <span className="slider round" />
            </label>
          </div>

          <p>PRIMARY GENRE</p>
          <div className="section-container">
            <MultiCombobox
              label=""
              labelVisible={false}
              modelProperty="genress"
              model={track}
              options={primaryGenres.map(g => ({
                display: g.name,
                value: g.id,
              }))}
              optionEqualFunc={(modelProperty, option): boolean => {
                return (
                  track.genress.some(x => x.genresId === option)
                  || modelProperty === option
                );
              }}
              isClearable
              onAfterChange={(data, event) => {
                // save track genres to db
                Axios.post(
                  `${SERVER_URL}/api/entity/TrackEntity/updateTrackGenres/${track.id}`,
                  event.value,
                ).catch(err => console.error(err));
              }}
            />
          </div>

          <p>SECONDARY GENRE</p>
          <div className="section-container">
            {track.tagss.map(
              t => t.tags.tagType.name === 'Genre' && (
                  <div
                    className="tags-badge"
                    key={`tags-genre-badge-key-${t.tags.name}`}
                  >
                    {t.tags.name}
                  </div>
                ),
            )}
          </div>

          <p>MOOD/FEEL</p>
          <div className="section-container">
            {track.tagss.map(
              t => t.tags.tagType.name === 'Mood / Feel' && (
                  <div
                    className="tags-badge"
                    key={`tags-mood-badge-key-${t.tags.name}`}
                  >
                    {t.tags.name}
                  </div>
                ),
            )}
          </div>

          <p>INSTRUMENT</p>
          <div className="section-container">
            {track.tagss.map(
              t => t.tags.tagType.name === 'Instrument' && (
                  <div
                    className="tags-badge"
                    key={`tags-instrument-badge-key-${t.tags.name}`}
                  >
                    {t.tags.name}
                  </div>
                ),
            )}
          </div>

          <p>VOCALS</p>
          <div className="section-container">
            {track.tagss.map(
              t => t.tags.tagType.name === 'Vocals' && (
                  <div
                    className="tags-badge"
                    key={`tags-vocal-badge-key-${t.tags.name}`}
                  >
                    {t.tags.name}
                  </div>
                ),
            )}
          </div>
        </div>
      ) : (
        <LoadingContainer name="Tags" />
      )}
    </div>
  );
});

export default BankTrackDetailsPanel;
