import React from 'react';
import { syncStatusType, syncStatusTypeOptions } from '../../../../../Models/Enums';
import {
	Button, Colors, Display, Sizes,
} from '../../../../Components/Button/Button';
import { store } from '../../../../../Models/Store';
import PublishModal from '../../../ProfileManagement/Sync/PublishModal/PublishModal';
import moment from 'moment';
import { formatDateTime } from '../../../../../Util/ScheduleUtils';

interface ZoneSyncSectionProps {
  zoneId: string,
  lastUpdated: Date | null,
  updateRequired: Date | null,
  syncStatus: syncStatusType
}

const ZoneSyncSection = ({
	zoneId, lastUpdated, updateRequired, syncStatus,
}: ZoneSyncSectionProps) => {
  // default as no sync required
	let syncStatusDescription = 'No sync required';
  // default as successful
	let syncStatusIndication: 'error' | 'warning' | 'successful' = 'successful';

	// check if the required sync time is in the past
	const requiredSyncingNow = moment().isAfter(moment(updateRequired).utc());

	if (!!updateRequired) {
		syncStatusIndication = 'warning';
		syncStatusDescription = requiredSyncingNow
			? 'Require syncing now'
			: 'Scheduled syncing';
	}

  // if sync status failed, override the indication and description
	if (syncStatus === syncStatusTypeOptions.FAILED) {
		syncStatusIndication = 'error';
		syncStatusDescription = 'Syncing failed';
	}
	return (
		<div className="publish-zone-container">
			<p className="sync-status-label">Sync Status</p>
			<div className="sync-status-label-container">
				{/* a circle indicating the sync status of the zone */}
				<div className={`sync-status ${syncStatusIndication}`} />
				{/* sync status description */}
				<p className="sync-status-content">{syncStatusDescription}</p>
			</div>
			{updateRequired && !requiredSyncingNow
			&& <p className="update-required-label">{`on ${formatDateTime(updateRequired)}`}</p>}
			{!!lastUpdated
				&& 				(
					<>
						<p className="sync-status-content">{'Last sync'}</p>
						<p className="update-required-label">{`on ${formatDateTime(lastUpdated)}`}</p>
					</>
				)}

			{/* // TODO: check with client which format date time is correct */}
			{/* moment(zone.lastUpdated).utc(true).local().format('DD/MM/YYYY hh:mm A') */}

			<Button
				className="publish-button"
				display={Display.Text}
				sizes={Sizes.Small}
				colors={Colors.Primary}
				buttonProps={{
					onClick: () => {
						store.modal.show(
							'Publish',
							<PublishModal
								zoneId={zoneId}
								canPublish
								fetchScheduledDateTime={() => undefined}
							/>,
							{ className: 'slideout-panel-right' },
						);
					},
				}}
			>
				Publish
			</Button>
		</div>
	);
};

export default ZoneSyncSection;
