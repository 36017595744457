/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AgencyPlaylisterTagEntity from 'Models/Security/Acl/AgencyPlaylisterTagEntity';
import ApplicationUserTagEntity from 'Models/Security/Acl/ApplicationUserTagEntity';
import MustardAdminTagEntity from 'Models/Security/Acl/MustardAdminTagEntity';
import AgencyAdminTagEntity from 'Models/Security/Acl/AgencyAdminTagEntity';
import VisitorsTagEntity from 'Models/Security/Acl/VisitorsTagEntity';
import MustardManagementTagEntity from 'Models/Security/Acl/MustardManagementTagEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ITagEntityAttributes extends IModelAttributes {
	name: string;

	playlistTagss: Array<
		| Models.TagsPlaylistTags
		| Models.ITagsPlaylistTagsAttributes
	>;
	tagTypeId: string;
	tagType: Models.TagTypeEntity | Models.ITagTypeEntityAttributes;
	trackTagss: Array<
		| Models.TagsTrackTags
		| Models.ITagsTrackTagsAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('TagEntity', 'Tag')
// % protected region % [Customise your entity metadata here] end
export default class TagEntity extends Model
	implements ITagEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AgencyPlaylisterTagEntity(),
		new ApplicationUserTagEntity(),
		new MustardAdminTagEntity(),
		new AgencyAdminTagEntity(),
		new VisitorsTagEntity(),
		new MustardManagementTagEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@observable
	@attribute<TagEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Playlist Tags'] off begin
		name: 'Playlist Tags',
		displayType: 'reference-multicombobox',
		order: 20,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.TagsPlaylistTags,
		optionEqualFunc: makeJoinEqualsFunc('playlistTagsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'tagEntity',
			oppositeEntityName: 'playlistEntity',
			relationName: 'tags',
			relationOppositeName: 'playlistTags',
			entity: () => Models.TagEntity,
			joinEntity: () => Models.TagsPlaylistTags,
			oppositeEntity: () => Models.PlaylistEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Playlist Tags'] end
	})
	public playlistTagss: Models.TagsPlaylistTags[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Tag Type'] off begin
		name: 'Tag Type',
		displayType: 'reference-combobox',
		order: 30,
		referenceTypeFunc: () => Models.TagTypeEntity,
		// % protected region % [Modify props to the crud options here for reference 'Tag Type'] end
	})
	public tagTypeId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public tagType: Models.TagTypeEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Track Tags'] off begin
		name: 'Track Tags',
		displayType: 'reference-multicombobox',
		order: 40,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.TagsTrackTags,
		optionEqualFunc: makeJoinEqualsFunc('trackTagsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'tagEntity',
			oppositeEntityName: 'trackEntity',
			relationName: 'tags',
			relationOppositeName: 'trackTags',
			entity: () => Models.TagEntity,
			joinEntity: () => Models.TagsTrackTags,
			oppositeEntity: () => Models.TrackEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Track Tags'] end
	})
	public trackTagss: Models.TagsTrackTags[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ITagEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ITagEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.playlistTagss !== undefined && Array.isArray(attributes.playlistTagss)) {
				for (const model of attributes.playlistTagss) {
					if (model instanceof Models.TagsPlaylistTags) {
						this.playlistTagss.push(model);
					} else {
						this.playlistTagss.push(new Models.TagsPlaylistTags(model));
					}
				}
			}
			if (attributes.tagTypeId !== undefined) {
				this.tagTypeId = attributes.tagTypeId;
			}
			if (attributes.tagType !== undefined) {
				if (attributes.tagType === null) {
					this.tagType = attributes.tagType;
				} else if (attributes.tagType instanceof Models.TagTypeEntity) {
					this.tagType = attributes.tagType;
					this.tagTypeId = attributes.tagType.id;
				} else {
					this.tagType = new Models.TagTypeEntity(attributes.tagType);
					this.tagTypeId = this.tagType.id;
				}
			}
			if (attributes.trackTagss !== undefined && Array.isArray(attributes.trackTagss)) {
				for (const model of attributes.trackTagss) {
					if (model instanceof Models.TagsTrackTags) {
						this.trackTagss.push(model);
					} else {
						this.trackTagss.push(new Models.TagsTrackTags(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		playlistTagss {
			${Models.TagsPlaylistTags.getAttributes().join('\n')}
			playlistTags {
				${Models.PlaylistEntity.getAttributes().join('\n')}
			}
		}
		trackTagss {
			${Models.TagsTrackTags.getAttributes().join('\n')}
			trackTags {
				${Models.TrackEntity.getAttributes().join('\n')}
				${Models.TrackEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
		tagType {
			${Models.TagTypeEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			playlistTagss: {},
			trackTagss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'playlistTagss',
							'trackTagss',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(TagEntity.prototype, 'created');
CRUD(modifiedAttr)(TagEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
