/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AgencyAdminVolumeTimeslotEntity from 'Models/Security/Acl/AgencyAdminVolumeTimeslotEntity';
import AgencyPlaylisterVolumeTimeslotEntity from 'Models/Security/Acl/AgencyPlaylisterVolumeTimeslotEntity';
import ApplicationUserVolumeTimeslotEntity from 'Models/Security/Acl/ApplicationUserVolumeTimeslotEntity';
import MustardAdminVolumeTimeslotEntity from 'Models/Security/Acl/MustardAdminVolumeTimeslotEntity';
import MustardManagementVolumeTimeslotEntity from 'Models/Security/Acl/MustardManagementVolumeTimeslotEntity';
import VisitorsVolumeTimeslotEntity from 'Models/Security/Acl/VisitorsVolumeTimeslotEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IVolumeTimeslotEntityAttributes extends IModelAttributes {
	dayOfWeek: Enums.timeslotDayOfWeek;
	start: Date;
	end: Date;
	volume: number;

	scheduleId: string;
	schedule: Models.ScheduleEntity | Models.IScheduleEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('VolumeTimeslotEntity', 'Volume Timeslot')
// % protected region % [Customise your entity metadata here] end
export default class VolumeTimeslotEntity extends Model
	implements IVolumeTimeslotEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AgencyAdminVolumeTimeslotEntity(),
		new AgencyPlaylisterVolumeTimeslotEntity(),
		new ApplicationUserVolumeTimeslotEntity(),
		new MustardAdminVolumeTimeslotEntity(),
		new MustardManagementVolumeTimeslotEntity(),
		new VisitorsVolumeTimeslotEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Day Of Week'] off begin
	@observable
	@attribute<VolumeTimeslotEntity, Enums.timeslotDayOfWeek>()
	@CRUD({
		name: 'Day Of Week',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.timeslotDayOfWeekOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.timeslotDayOfWeekOptions),
		displayFunction: (attr: Enums.timeslotDayOfWeek) => Enums.timeslotDayOfWeekOptions[attr],
	})
	public dayOfWeek: Enums.timeslotDayOfWeek;
	// % protected region % [Modify props to the crud options here for attribute 'Day Of Week'] end

	// % protected region % [Modify props to the crud options here for attribute 'Start'] off begin
	@observable
	@attribute<VolumeTimeslotEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseTime(a) })
	@CRUD({
		name: 'Start',
		displayType: 'timepicker',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'time' }),
		displayFunction: (attr: Date) => attr ? moment(attr).format('HH:mm:ss') : 'None',
	})
	public start: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Start'] end

	// % protected region % [Modify props to the crud options here for attribute 'End'] off begin
	@observable
	@attribute<VolumeTimeslotEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseTime(a) })
	@CRUD({
		name: 'End',
		displayType: 'timepicker',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'time' }),
		displayFunction: (attr: Date) => attr ? moment(attr).format('HH:mm:ss') : 'None',
	})
	public end: Date;
	// % protected region % [Modify props to the crud options here for attribute 'End'] end

	// % protected region % [Modify props to the crud options here for attribute 'Volume'] off begin
	/**
	 * Decimal value between 0 and 1 to set volume on schedule
	 */
	@Validators.Min(0)
	@Validators.Max(1)
	@Validators.Numeric()
	@observable
	@attribute<VolumeTimeslotEntity, number>()
	@CRUD({
		name: 'Volume',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public volume: number;
	// % protected region % [Modify props to the crud options here for attribute 'Volume'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Schedule'] off begin
		name: 'Schedule',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.ScheduleEntity,
		// % protected region % [Modify props to the crud options here for reference 'Schedule'] end
	})
	public scheduleId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public schedule: Models.ScheduleEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IVolumeTimeslotEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IVolumeTimeslotEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.dayOfWeek !== undefined) {
				this.dayOfWeek = attributes.dayOfWeek;
			}
			if (attributes.start !== undefined) {
				if (attributes.start === null) {
					this.start = attributes.start;
				} else {
					this.start = moment(attributes.start, 'HH:mm:ss').toDate();
				}
			}
			if (attributes.end !== undefined) {
				if (attributes.end === null) {
					this.end = attributes.end;
				} else {
					this.end = moment(attributes.end, 'HH:mm:ss').toDate();
				}
			}
			if (attributes.volume !== undefined) {
				this.volume = attributes.volume;
			}
			if (attributes.scheduleId !== undefined) {
				this.scheduleId = attributes.scheduleId;
			}
			if (attributes.schedule !== undefined) {
				if (attributes.schedule === null) {
					this.schedule = attributes.schedule;
				} else if (attributes.schedule instanceof Models.ScheduleEntity) {
					this.schedule = attributes.schedule;
					this.scheduleId = attributes.schedule.id;
				} else {
					this.schedule = new Models.ScheduleEntity(attributes.schedule);
					this.scheduleId = this.schedule.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		schedule {
			${Models.ScheduleEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(VolumeTimeslotEntity.prototype, 'created');
CRUD(modifiedAttr)(VolumeTimeslotEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
