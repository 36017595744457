/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import AgencyAdminTrackAgencyTagsReferenceManyToMany from '../Security/Acl/AgencyAdminTrackAgencyTagsReferenceManyToMany';
import VisitorsTrackAgencyTagsReferenceManyToMany from '../Security/Acl/VisitorsTrackAgencyTagsReferenceManyToMany';
import ApplicationUserTrackAgencyTagsReferenceManyToMany from '../Security/Acl/ApplicationUserTrackAgencyTagsReferenceManyToMany';
import AgencyPlaylisterTrackAgencyTagsReferenceManyToMany from '../Security/Acl/AgencyPlaylisterTrackAgencyTagsReferenceManyToMany';
import MustardAdminTrackAgencyTagsReferenceManyToMany from '../Security/Acl/MustardAdminTrackAgencyTagsReferenceManyToMany';
import MustardManagementTrackAgencyTagsReferenceManyToMany from '../Security/Acl/MustardManagementTrackAgencyTagsReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IAgencyTagsTrackAgencyTagsAttributes extends IModelAttributes {
	agencyTagsId: string;
	trackAgencyTagsId: string;

	agencyTags: Models.AgencyTagEntity | Models.IAgencyTagEntityAttributes;
	trackAgencyTags: Models.TrackEntity | Models.ITrackEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('AgencyTagsTrackAgencyTags')
export default class AgencyTagsTrackAgencyTags
	extends Model
	implements IAgencyTagsTrackAgencyTagsAttributes {
	public static acls: IAcl[] = [
		new AgencyAdminTrackAgencyTagsReferenceManyToMany(),
		new VisitorsTrackAgencyTagsReferenceManyToMany(),
		new ApplicationUserTrackAgencyTagsReferenceManyToMany(),
		new AgencyPlaylisterTrackAgencyTagsReferenceManyToMany(),
		new MustardAdminTrackAgencyTagsReferenceManyToMany(),
		new MustardManagementTrackAgencyTagsReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public agencyTagsId: string;

	@observable
	@attribute()
	public trackAgencyTagsId: string;

	@observable
	@attribute({ isReference: true })
	public agencyTags: Models.AgencyTagEntity;

	@observable
	@attribute({ isReference: true })
	public trackAgencyTags: Models.TrackEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IAgencyTagsTrackAgencyTagsAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.agencyTagsId) {
				this.agencyTagsId = attributes.agencyTagsId;
			}
			if (attributes.trackAgencyTagsId) {
				this.trackAgencyTagsId = attributes.trackAgencyTagsId;
			}

			if (attributes.agencyTags) {
				if (attributes.agencyTags instanceof Models.AgencyTagEntity) {
					this.agencyTags = attributes.agencyTags;
					this.agencyTagsId = attributes.agencyTags.id;
				} else {
					this.agencyTags = new Models.AgencyTagEntity(attributes.agencyTags);
					this.agencyTagsId = this.agencyTags.id;
				}
			} else if (attributes.agencyTagsId !== undefined) {
				this.agencyTagsId = attributes.agencyTagsId;
			}

			if (attributes.trackAgencyTags) {
				if (attributes.trackAgencyTags instanceof Models.TrackEntity) {
					this.trackAgencyTags = attributes.trackAgencyTags;
					this.trackAgencyTagsId = attributes.trackAgencyTags.id;
				} else {
					this.trackAgencyTags = new Models.TrackEntity(attributes.trackAgencyTags);
					this.trackAgencyTagsId = this.trackAgencyTags.id;
				}
			} else if (attributes.trackAgencyTagsId !== undefined) {
				this.trackAgencyTagsId = attributes.trackAgencyTagsId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
