import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import classNames from 'classnames';
import { RouteComponentProps } from 'react-router';
import {
	Button, Colors, Display, Sizes,
} from '../../../Components/Button/Button';
import { store } from '../../../../Models/Store';
import {
	ApplicationUserEntity,
	ClientEntity,
	ContactEntity,
	VenueEntity,
	ZoneEntity,
} from '../../../../Models/Entities';
import ZoneTile from '../Zones/ZoneTile/ZoneTile';
import ZoneModal from '../Zones/ZoneModal';
import { SERVER_URL } from '../../../../Constants';
import EntityCRUD from '../../../Components/CRUD/EntityCRUD';
import { IHasCondition, IWhereCondition } from '../../../Components/ModelCollection/ModelQuery';
import UserModal from '../../Users/UserModal';
import { ICollectionItemActionProps } from '../../../Components/Collection/Collection';

interface IVenueTileProps extends RouteComponentProps {
	client: ClientEntity;
	venue: VenueEntity;
	openEditVenueModal: (venue: VenueEntity) => void;
	refetchClient?: () => void;
	refetchVenue?: () => void;
}

const VenueTile = observer((props: IVenueTileProps) => {
	const {
		client, venue, openEditVenueModal, refetchClient, refetchVenue,
	} = props;

	const collectionRef = useRef<EntityCRUD<ApplicationUserEntity> | null>(null);

	const openEditZoneModal = (zone?: ZoneEntity) => {
		store.modal.show(
			'Edit Zone',
			<ZoneModal
				key="zone-modal-key"
				zone={zone}
				agencyId={venue.agencyOwnerId}
				venueId={venue.id}
				agency={client.agency}
				venue={venue}
				refetchVenue={refetchVenue ?? (() => { })}
			/>,
			{ className: 'slideout-panel-right', shouldCloseOnOverlayClick: false },
		);
	};

	const hasConditions = () => {
		const conditions = [] as IHasCondition<ApplicationUserEntity>[][];
		conditions.push(
			[
				{
					path: 'managesVenues',
					conditions: [
						[{
							path: 'managesVenueId',
							comparison: 'equal',
							value: venue.id,
						}],
					] as IWhereCondition<ApplicationUserEntity>[][],
				},
				...venue.zoness.map(z => ({
					path: 'managesZones',
					conditions: [
						[{
							path: 'managesZoneId',
							comparison: 'equal',
							value: z.id,
						}],
					] as IWhereCondition<ApplicationUserEntity>[][],
				})),
			] as IHasCondition<ApplicationUserEntity>[],
		);

		return conditions;
	};

	const getUrlLink = (url: string, labelText: string) => {
		return (
			<a
				className="venue-url-link"
				rel="external noreferrer"
				target="_blank"
				href={`${!url.includes('https://') ? 'https://' : ''}${url}`}
			>
				{labelText}
			</a>
		);
	};

	return (
		<div className="venue-container" key={`venue-${venue.id}`}>
			<div className="venue-info">
				<div className="venue-contact-info">
					<img className="venue-photo" src={`${SERVER_URL}/api/files/${venue.logoId}`} alt={venue.name} />
					<div className="venue-header">
						<Button
							className="see-all"
							display={Display.Text}
							colors={Colors.Primary}
							onClick={() => store.routerHistory.push(`/clients/view/${venue.client.id}`)}
						>
							{venue.client.name}
						</Button>
						<h4>{venue.name}</h4>
						<h5>{venue.venueType?.name}</h5>
						<p>{venue.renderAddress()}</p>
					</div>
				</div>
				<Button
					colors={Colors.Primary}
					display={Display.Text}
					sizes={Sizes.Small}
					onClick={() => openEditVenueModal(venue)}
					disabled={store.userGroups.some(ug => ug.name === 'AgencyPlaylister')}
				>
					Edit
				</Button>
			</div>
			<h5>Contacts</h5>
			<div className="venue-contacts">
				{venue.contactss?.sort((c: ContactEntity, x: ContactEntity) => (c.mainContact ? -1 : 1))
					.map((contact: ContactEntity, contactIndex: number) => (
						<div
							className="venue-contact-container"
							key={`venue-contact-${contact?.id || `missing-id-${contactIndex}`}`}
						>
							<p className="detail-value">{contact.name}</p>
							<p className="detail-value">{contact.phoneNumber}</p>
							<p className="detail-value">{contact.emailAddress}</p>
						</div>
					))}
			</div>
			<div className="users">
				<EntityCRUD
					ref={collectionRef}
					perPage={8}
					modelType={ApplicationUserEntity}
					history={props.history}
					location={props.location}
					match={props.match}
					collectionViewAction={() => undefined}
					collectionDeleteAction={() => undefined}
					collectionUpdateAction={() => {
						// Override default entity edit page to application-user edit
						// This sets Application User ManagesVenue/ManagesZone collections
						return {
							action: (item: ApplicationUserEntity) => {
								props.history.push({ pathname: `/application-user/${item.id}` });
							},
							label: 'Edit',
							showIcon: true,
							icon: 'edit',
							iconPos: 'icon-top',
						} as ICollectionItemActionProps<ApplicationUserEntity>;
					}}
					entityCollectionProps={{
						hasConditions: hasConditions(),
						has: hasConditions(),
					}}
					disableBulkDelete
					disableBulkExport
					collectionTitle="Management Control"
					collectionProps={{ disableSearch: true, disableFilter: true, smallTitle: true }}
					collectionCreateAction={() => (
						<Button
							key="create"
							className={classNames(Display.Solid)}
							icon={{ icon: 'create', iconPos: 'icon-left' }}
							buttonProps={{
								onClick: () => {
									store.modal.show(
										'User',
										<UserModal
											venue={venue}
											refetchClient={() => {
												if (refetchVenue) {
													refetchVenue();
												}
												if (refetchClient) {
													refetchClient();
												}
												collectionRef.current?.collectionRefetch();
											}}
										/>,
										{ className: 'slideout-panel-right' },
									);
								},
							}}
						>
							Add a user
						</Button>
					)}
				/>
			</div>
			<div className="venue-links">
				<h5>Links</h5>
				{venue.websiteURL && getUrlLink(venue.websiteURL, 'Website')}
				{venue.facebookURL && getUrlLink(venue.facebookURL, 'Facebook')}
				{venue.instagramURL && getUrlLink(venue.instagramURL, 'Instagram')}
				{venue.spotifyRadioURL && getUrlLink(venue.spotifyRadioURL, 'Venue Radio')}
			</div>
			<div className="zones-container-header">
				<h4>Zones</h4>
				<div className="zone-progress-bar-wrapper">
					<p>
						{venue.zoness.length}
						/
						{client?.agency?.zoneLicences || 'N/A'}
					</p>
					<div className="zone-progress-bar">
						<div
							className="zone-progress"
							style={{width: `${((venue.zoness.length / 
							(client?.agency?.zoneLicences ?? 1)) * 100) || 0}%`}}
						/>
					</div>
				</div>
			</div>
			<div className="zones-container">
				{venue.zoness
					.sort((a: ZoneEntity, b: ZoneEntity) => (moment(a.created).isBefore(moment(b.created)) ? -1 : 1))
					.map(zone => (
						<ZoneTile
							zone={zone}
							key={`zone-${zone.id}`}
							openEditZoneModal={() => openEditZoneModal(zone)}
						/>
					))}
			</div>
			<Button
				className="add-zone-button"
				type="button"
				colors={Colors.Primary}
				display={Display.Solid}
				sizes={Sizes.Medium}
				icon={{ icon: 'plus', iconPos: 'icon-left' }}
				onClick={() => openEditZoneModal()}
			>
				Add zone
			</Button>
		</div>
	);
});

export default VenueTile;
