import React, {
	useEffect, useRef, MutableRefObject,
} from 'react';
import WaveSurfer from 'wavesurfer.js';
import { observer, useLocalStore } from 'mobx-react';
import { runInAction } from 'mobx';
// eslint-disable-next-line import/no-unresolved
import { WaveSurferParams } from 'wavesurfer.js/types/params';
import { store } from 'Models/Store';
import FadeControls from './FadeControls';

const formWaveSurferOptions = (ref: HTMLDivElement | null): WaveSurferParams => ({
	container: ref ?? '',
	waveColor: '#505050',
	progressColor: '#f1f1f1',
	barWidth: 3,
	barRadius: 1,
	backend: 'MediaElement',
	responsive: true,
	interact: false,
	height: 30,
	// If true, normalize by the maximum peak instead of 1.0.
	normalize: true,
	// Use the PeakCache to improve rendering speed of large waveforms.
	partialRender: true,
});

interface WaveFormProps {
	playing: boolean;
	url: string;
	volume: number;
	waveform: string;
	startTime: number;
	endTime: number;
	interact: boolean;
	duration: number;
}

const WaveForm = observer((props: WaveFormProps) => {
	const {
		url, playing, volume, waveform, startTime, endTime, interact, duration,
	} = props;

	const { track } = store;

	const waveformRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
	const wavesurfer: MutableRefObject<WaveSurfer | null> = useRef(null);

	const secondsToMinutes = (seconds: number): string => {
		const format = (val: number): string => `0${Math.floor(val)}`.slice(-2);
		const minutes = (seconds % 3600) / 60;

		return [minutes, seconds % 60].map(format).join(':');
	};

	const timers = useLocalStore(() => ({
		current: '00:00',
		duration: '00:00',
		hasEnded: true,
	}));

	runInAction(() => {
		store.getCurrentTime = () => wavesurfer.current?.getCurrentTime() ?? 0;

		store.setCurrentTime = (time: number) => {
			if (wavesurfer.current) {
				runInAction(() => {
					store.pause = false;
				});

				if (track.id) {
					wavesurfer.current.play(time, time !== endTime && endTime ? endTime : duration);
				}
			}
		};
	});

	const playTrack = () => {
		if (!wavesurfer.current?.backend) {
			// If wavesurfer has no backend yet, return early to avoid an error
			return;
		}

		if (!playing) {
			wavesurfer.current.pause();
		} else {
			const currentTime = wavesurfer.current.getCurrentTime();
			const currentTimeRounded = parseFloat(currentTime.toFixed(1));

			if (track.id) {
				if (currentTimeRounded > (startTime || 0) && currentTimeRounded < (endTime || duration)) {
					// If time is between the fade times, play until the fade out (or duration if no fade out exists)
					wavesurfer.current.play(currentTimeRounded, endTime || duration);
				} else if (currentTimeRounded >= endTime && currentTimeRounded < duration) {
					// If time is after the fade out, play until the duration
					wavesurfer.current.play(currentTime, duration);
				} else if (currentTimeRounded < startTime && currentTimeRounded >= 0) {
					// If time is before the fade in, play until the fade out
					if (timers.hasEnded) {
						wavesurfer.current.play(startTime || 0, endTime || duration);
					} else {
						wavesurfer.current.play(currentTime, endTime || duration);
					}
				} else {
					wavesurfer.current.play(startTime || 0, endTime || duration);
				}
			}

			runInAction(() => { timers.hasEnded = false; });

			wavesurfer.current.setVolume(volume);
		}
	};

	// create new WaveSurfer instance
	// On component mount and when url changes
	useEffect(() => {
		const options = formWaveSurferOptions(waveformRef.current);

		if (interact) {
			options.interact = true;
		}

		wavesurfer.current = WaveSurfer.create(options);

		if (waveform) {
			wavesurfer.current.load(url, waveform.split(',').map(Number));
		} else {
			wavesurfer.current.load(url,
				new Array(900).fill(1).concat([10]));
		}

		if (duration) {
			runInAction(() => {
				timers.duration = secondsToMinutes(duration);
			});
		}

		if (track.id) {
			wavesurfer.current.play(startTime || 0, endTime || duration);
		}

		wavesurfer.current.setVolume(volume);

		runInAction(() => {
			timers.hasEnded = false;
			store.pause = false;
		});

		// Removes events, elements and disconnects Web Audio nodes
		// when component unmounts
		return (): void => wavesurfer.current?.destroy();
	}, [url]);

	useEffect(() => {
		if (playing) {
			playTrack();
		}
	}, [track.fadeIn, track.fadeOut]);

	useEffect(() => {
		playTrack();
	}, [playing]);

	useEffect(() => {
		if (wavesurfer.current) {
			wavesurfer.current.setVolume(volume);
		}
	}, [volume]);

	useEffect(() => {
		if (wavesurfer.current) {
			wavesurfer.current.setVolume(volume);
		}
	}, []);

	wavesurfer.current?.on('audioprocess', () => {
		runInAction(() => {
			if (wavesurfer.current) {
				timers.current = secondsToMinutes(wavesurfer.current.getCurrentTime());
			}
		});
	});

	wavesurfer.current?.on('seek', () => {
		if (wavesurfer.current) {
			const currentTime = wavesurfer.current.getCurrentTime();

			if (endTime && currentTime < endTime) {
				wavesurfer.current.setPlayEnd(endTime);
			} else {
				wavesurfer.current.setPlayEnd(duration);
			}
		}

		runInAction(() => {
			if (wavesurfer.current) {
				timers.hasEnded = false;
				timers.current = secondsToMinutes(wavesurfer.current.getCurrentTime());
			}
		});
	});

	wavesurfer.current?.on('pause', () => {
		if (!store.pause) {
			runInAction(() => {
				store.pause = true;
			});
		}
	});

	useEffect(() => {
		if (wavesurfer.current && playing) {
			runInAction(() => {
				store.pause = false;
			});
		}
	}, [wavesurfer.current?.getCurrentTime()]);

	return (
		<>
			{store.shouldShowFadesInAudioPlayer && (
				<div className="fade-modal">
					<FadeControls />
				</div>
			)}
			<span className="track-length">{timers.current}</span>
			<div className="waveform-container" ref={waveformRef} />
			<span className="track-length">{timers.duration}</span>
		</>
	);
});

export default WaveForm;
