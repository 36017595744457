import { RefObject, useEffect, useRef } from 'react';

type scrollPositions = {
	[key: string]: {x: number; y: number};
};
const scrollPositionsById: scrollPositions = {};

const useContainerScroll = (id: string) => {
	const ref = useRef() as RefObject<HTMLDivElement>;

	const handleScroll = () => {
		const { current } = ref;
		if (current && (current.scrollTop !== 0 || current.scrollLeft !== 0)) {
			scrollPositionsById[id] = {
				x: current.scrollLeft,
				y: current.scrollTop,
			};
		}
	};

	// add event listener to window when component mounts
	useEffect(() => {
		const { current } = ref;
		if (current) {
			current.addEventListener('scroll', handleScroll);
			return () => current.removeEventListener('scroll', handleScroll);
		}
		return () => undefined;
	}, []);

	// restore scroll on initial load
	useEffect(() => {
		const { current } = ref;
		if (current) {
			current.scrollLeft = scrollPositionsById[id] && scrollPositionsById[id].x;
			current.scrollTop = scrollPositionsById[id] && scrollPositionsById[id].y;
		}
	}, []);

	return ref;
};

export default useContainerScroll;
