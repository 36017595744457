/* eslint-disable jsx-a11y/no-static-element-interactions */
// NOTE: this is a refactor of PlaylistTab, used in the Upload Page

import React, {
 RefObject, useRef, MouseEvent, KeyboardEvent,
} from 'react';
import { observer, useLocalStore, useObserver } from 'mobx-react';
import { runInAction } from 'mobx';
import {
	Draggable,
	DraggableProvided,
	DraggableStateSnapshot,
	Droppable,
} from 'react-beautiful-dnd';
import { useInfiniteQuery } from 'react-query';
import { SERVER_URL } from '../../../Constants';
import Axios from 'axios';
import useIntersectionObserver from '../../../Util/useIntersectionObserver';
import { TrackEntity } from '../../../Models/Entities';
import { TextField } from '../../Components/TextBox/TextBox';
import { ContentsSearchResultObject } from '../../../Util/PlaylistUtils';
import {
	Button, Colors, Display, Sizes,
} from '../../Components/Button/Button';

import TrackTile from '../Playlisting/TrackTile';
import { contextMenu } from 'react-contexify';
import { ContextMenu } from '../../Components/ContextMenu/ContextMenu';
import useAsync from '../../../Hooks/useAsync';
import AlbumArtPlaceholder from '../../../assets/empty-album-art.svg';

// This is the same as Enum, but better
export const UploadPlaylistSortingOptions = {
	similarity: 'Similarity',
	genreAtoZ: 'Genre A-Z',
	genreZtoA: 'Genre Z-A',
	titleAtoZ: 'Title A-Z',
	titleZtoA: 'Title Z-A',
	sorted: 'Sorted',
	unsorted: 'Unsorted',
	artistAtoZ: 'Artist A-Z',
	artistZtoA: 'Artist Z-A',
	albumAtoZ: 'Album A-Z',
	albumZtoA: 'Album Z-A',
	bpmAsc: 'BPM Ascending',
	bpmDesc: 'BPM Descending',
} as const;
interface TrackListProps {
  bankId: string;
  header?: JSX.Element;
  customTrackClickEvent: (
    track: TrackEntity,
    e: MouseEvent<HTMLInputElement>
  ) => void;
  multiSelect?: (
    event: React.MouseEvent<HTMLInputElement>,
    index: number,
    start: boolean,
    track: string,
    trackList: string[],
    collectionId: string
  ) => void;
  multiSelectStore?: {
    start: number;
    end: number;
    selected: string[];
    numSelected: number;
    collectionId: string;
  };
  includeSelected?: boolean;
  order?: boolean;
}

export const TrackList = observer((props: TrackListProps) => {
	const {
		bankId,
		header,
		multiSelect,
		multiSelectStore,
		includeSelected,
		customTrackClickEvent,
	} = props;

	const tabStore = useLocalStore(() => ({
		term: '',
		panelEntity: '',
		trackList: [] as TrackEntity[],
	}));

	const sortStore = useLocalStore(() => ({
		path: 'Artist A-Z',
	}));

	// Defines page size for playlist query and multiselect index
	const PAGE_SIZE = 50;

	const onKeyDown = (
		e: MouseEvent | KeyboardEvent,
		snapshot: DraggableStateSnapshot,
	) => {
		if (e.defaultPrevented || snapshot.isDragging) return;
		e.preventDefault();
	};

	const {
		data: playlistData,
		fetchNextPage,
		hasNextPage,
		isLoading,
		isFetchingNextPage,
	} = useInfiniteQuery(
		['playlist', bankId, sortStore.path, tabStore.term],
		async ({ pageParam = 0 }): Promise<ContentsSearchResultObject> => {
			const queryString = `${SERVER_URL}/api/playlist_search/collection_contents/${bankId}`
			+ `?PageNo=${pageParam}`
			+ `&PageSize=${PAGE_SIZE}`
			+ '&entityType=bank'
			+ `&orderBy=${sortStore.path}`
			+ `&searchTerm=${tabStore.term}`;

			const { data } = await Axios.get(queryString);
			return data;
		},
		{
			getNextPageParam: (lastPage: any) => lastPage.nextPageNo === -1 ? undefined : lastPage.nextPageNo,
		},
	);

  // triggers fetching more content when reached
	const loadMoreButtonRef = useRef<HTMLButtonElement>() as RefObject<HTMLButtonElement>;

  // tracks the position of the scroll container to see if the loadMoreButtonRef has been reached
	useIntersectionObserver({
		root: null,
		target: loadMoreButtonRef,
		onIntersect: fetchNextPage,
		enabled: hasNextPage,
	});

	// This list is needed for the multi-select shift click
	const trackIds = useAsync(async (): Promise<string[]> => {
		// make request for the list of IDs
		const queryString = `${SERVER_URL}/api/playlist_search/collection_list_ids/${bankId}`
		+ '?&entityType=bank'
		+ `&searchTerm=${tabStore.term}`
		+ `&orderBy=${sortStore.path}`;

		const { data } = await Axios.get(queryString);
		return data as string[];
	}, [bankId, tabStore.term, sortStore.path]);

	const ContextMenuButton = (label: string) => {
		return {
			buttonClass: sortStore.path === label ? 'btn--solid' : '',
			label: label,
			onClick: () => {
				runInAction(() => {
					sortStore.path = label;
				});
			},
		};
	};

	return useObserver(() => (
		<div className="playlistContainer">
			<div className="playlistHeader">
				{header}
				<div className="search">
					<div className="search-filter-container tab">
						<TextField
							model={tabStore}
							modelProperty="term"
							className="search-term"
							placeholder="Search Tracks"
						/>
						<>
							<Button
								className="search-filter"
								data-testid="filter-button-search-panel"
								display={Display.Solid}
								sizes={Sizes.Medium}
								colors={Colors.Grey}
								icon={{ icon: 'order', iconPos: 'icon-left' }}
								onClick={e => contextMenu.show({
									event: e,
									id: 'context-menu-bank-tracks-order',
								})}
							/>
							<ContextMenu
								menuId="context-menu-bank-tracks-order"
								actions={Object.values(UploadPlaylistSortingOptions).map(value => ContextMenuButton(value))}
							/>
						</>
					</div>
				</div>
			</div>

			<Droppable
				droppableId={`source-${bankId}`}
				isDropDisabled
        // mode="virtual"
				renderClone={provided => (
					<div
						className="track-wrapper-clone"
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
					>
						<div className="select-count">{multiSelectStore?.numSelected}</div>
						<img
							src={AlbumArtPlaceholder}
							alt="album-art"
							className="album-art"
						/>
					</div>
				)}
			>
				{provided => (
					<div
						className="infiniteListContainer"
						ref={provided.innerRef}
						{...provided.droppableProps}
					>
						{playlistData?.pages.map((page, index) => (
							page.bank.map((track: TrackEntity, pagedIndex: number) => (
								<Draggable
									draggableId={`draggable${track.id}`}
									index={(index * PAGE_SIZE) + pagedIndex}
									key={`draggable${track.id}`}
									isDragDisabled={
										!multiSelectStore?.selected.includes(track.id)
									}
								>
									{(
										draggableProvided: DraggableProvided,
										snapshot: DraggableStateSnapshot,
									) => (
										<div
											className="track-wrapper"
											ref={draggableProvided.innerRef}
											{...draggableProvided.draggableProps}
											{...draggableProvided.dragHandleProps}
											onClick={(e: React.MouseEvent<HTMLInputElement>) => {
												customTrackClickEvent(track, e);

												if (multiSelect) {
													e.preventDefault();
													const isStart = e.ctrlKey || !e.shiftKey;
													multiSelect(
														e,
														(index * PAGE_SIZE) + pagedIndex,
														isStart,
														track.id,
														trackIds.data ?? [],
														bankId,
													);
												}
											}}
											onKeyDown={(e: React.KeyboardEvent) => onKeyDown(e, snapshot)}
											style={{ margin: '0 5px' }}
										>
											<div
												className={`result-item track art ${
													multiSelectStore?.selected.includes(track.id)
														? 'selected'
														: ''
												}`}
											>
												<TrackTile
													showNotSorted
													track={new TrackEntity(track)}
													includeSelected={includeSelected}
												/>
											</div>
										</div>
									)}
								</Draggable>
						))))}

						<button
								className="infiniteListEndButton"
								ref={loadMoreButtonRef}
								onClick={() => fetchNextPage()}
								disabled={!hasNextPage || isFetchingNextPage}
						>
								{isLoading || isFetchingNextPage || hasNextPage ? 'Loading...' : 'Nothing more to load'}
						</button>
					</div>
				)}
			</Droppable>
		</div>
	));
});
