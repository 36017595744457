// @ts-check

import React, {
  RefObject,
  useRef,
  useState,
  MouseEvent,
  KeyboardEvent,
  useEffect,
} from 'react';
import { observer, useLocalStore, useObserver } from 'mobx-react';
import { action, runInAction } from 'mobx';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
} from 'react-beautiful-dnd';
import { useInfiniteQuery } from 'react-query';
import { SERVER_URL } from '../../../../../Constants';
import Axios from 'axios';
import useIntersectionObserver from '../../../../../Util/useIntersectionObserver';
import {
  ITrackEntityAttributes,
  TagsTrackTags,
  TrackEntity,
} from '../../../../../Models/Entities';
import { TextField } from '../../../../Components/TextBox/TextBox';
import {
  CheckedTrackEntity,
  CollectionType,
  ContentsSearchResultObject,
  DefaultTrackFilterStore,
  MoodPlaylistTrackEntity,
} from '../../../../../Util/PlaylistUtils';
import {
  Button,
  Colors,
  Display,
  Sizes,
} from '../../../../Components/Button/Button';
import { store } from '../../../../../Models/Store';
import FilterModal, {
  FilterForm,
  FilterFormContainerDict,
} from '../FilterModal';
import TrackTile from '../../TrackTile';
import AlbumArtPlaceholder from '../../../../../assets/empty-album-art.svg';
import useAsync from '../../../../../Hooks/useAsync';

interface PlaylistTabProps {
  collectionId: string;
  entityType: CollectionType;
  customTrackClickEvent: (
    track: TrackEntity,
    e: MouseEvent<HTMLInputElement>
  ) => void;
  multiSelect: (
    event: React.MouseEvent<HTMLInputElement>,
    index: number,
    start: boolean,
    track: string,
    trackList: string[],
    collectionId: string
  ) => void;
  multiSelectStore: {
    start: number;
    end: number;
    selected: string[];
    numSelected: number;
    collectionId: string;
  };
  showCount: (shouldShowCount: number, tab: boolean, id: string) => void;
  filterCountStore: {
    showCount: number;
    showTabCount: { [key: string]: number };
  };
  defaultPrimaryGenre?: string
}

const PlaylistTab = observer((props: PlaylistTabProps) => {
  const {
    collectionId,
    entityType,
    multiSelect,
    multiSelectStore,
    showCount,
    filterCountStore,
    customTrackClickEvent,
    defaultPrimaryGenre,
  } = props;

  const searchStore = useLocalStore(() => ({
    term: '',
  }));

  const trackFilterStore: FilterFormContainerDict = useLocalStore(() => ({ }));

  // Defines page size for collection query and multiselect index
  const PAGE_SIZE = 40;

  useEffect(() => {
    if (!!trackFilterStore[collectionId]) {
      return;
    }

    runInAction(() => { trackFilterStore[collectionId] = DefaultTrackFilterStore; });
  }, [collectionId, trackFilterStore]);

  const onKeyDown = (
    e: MouseEvent | KeyboardEvent,
    snapshot: DraggableStateSnapshot,
    ) => {
      if (e.defaultPrevented || snapshot.isDragging) {
        return;
      }
      e.preventDefault();
    };

  const [filterApplied, setFilterApplied] = useState<boolean>(true);

  const applyTrackFilterToQuery = (queryString: string): string => {
    function isValid(value: number | undefined): boolean {
      return value !== undefined && value !== -1;
    }

    let newQueryString = queryString;

    const trackFilter = trackFilterStore[collectionId];
    Object.keys(trackFilter).forEach(key => {
      if (key === 'bpm') {
        if (isValid(trackFilter.bpm.min) && isValid(trackFilter.bpm.max)) {
          newQueryString += `&bpmMin=${trackFilter.bpm.min}&bpmMax=${trackFilter.bpm.max}`;
        }
      } else if (key === 'year') {
        if (isValid(trackFilter.year.start) && isValid(trackFilter.year.end)) {
          newQueryString += `&yearStart=${trackFilter.year.start}&yearEnd=${trackFilter.year.end}`;
        }
      } else if (!!(trackFilter as any)[key]) {
        if (Array.isArray((trackFilter as any)[key])) {
          newQueryString += `&${key}=${JSON.stringify(
            (trackFilter as any)[key],
          )}`;
        } else {
          newQueryString += `&${key}=${(trackFilter as any)[key]}`;
        }
      }
    });

    return newQueryString;
  };

  const {
 data: tracksData, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading,
} = useInfiniteQuery(
    ['collection', collectionId, entityType, searchStore.term],
    async ({ pageParam = 0 }): Promise<ContentsSearchResultObject> => {
      let queryString = `${SERVER_URL}/api/playlist_search/collection_contents/${collectionId}`
      + `?PageNo=${pageParam}`
      + `&PageSize=${PAGE_SIZE}`
      + `&entityType=${entityType.slice(0, -1)}`
      + `&searchTerm=${searchStore.term}`
      + '&orderBy=Similarity';

        if (trackFilterStore) {
          queryString = applyTrackFilterToQuery(queryString);
        }

      const res = await Axios.get(queryString);
      return res.data;
    },
    {
      getNextPageParam: (lastPage: any) => lastPage.nextPageNo === -1 ? undefined : lastPage.nextPageNo,
    },
  );

  // triggers fetching more content when reached
  const loadMoreButtonRef = useRef<HTMLButtonElement>() as RefObject<HTMLButtonElement>;

  // tracks the position of the scroll container to see if the loadMoreButtonRef has been reached
  useIntersectionObserver({
    root: null,
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  const applyFilter = action((filter: FilterForm) => {
    Object.keys(filter).forEach(key => {
      (trackFilterStore as any)[collectionId][key] = (filter as any)[key];
    });
    refetch();
    setFilterApplied(prev => !prev);
  });

  // This list is needed for the multi-select shift click
  const trackIds = useAsync(async () => {
      // make request for the list of IDs
      let queryString = `${SERVER_URL}/api/playlist_search/collection_list_ids/${collectionId}`
        + `?entityType=${entityType.slice(0, -1)}`
        + `&searchTerm=${searchStore.term}`
        + '&orderBy=Similarity';

      if (trackFilterStore) {
        queryString = applyTrackFilterToQuery(queryString);
      }

      const res = await Axios.get(queryString);
      return res.data as string[];
  }, [
    collectionId,
    searchStore.term,
    filterApplied,
  ]);

  const parseData = (page: any) => {
    switch (entityType) {
      case 'playlists':
        return page.playlist.map((a: MoodPlaylistTrackEntity) => {
          const checkedTrack = new CheckedTrackEntity(a.track);
          checkedTrack.isIncluded = a.isIncluded;
          return checkedTrack;
        });
      case 'albums':
        return page.album.map(
          (a: ITrackEntityAttributes) => new CheckedTrackEntity(a),
        );
      case 'artists':
        return page.artist.map(
          (a: ITrackEntityAttributes) => new CheckedTrackEntity(a),
        );
      case 'primaryGenres':
        return page.primaryGenre.map(
          (a: TagsTrackTags) => new CheckedTrackEntity(a),
        );
      case 'genres':
        return page.genre.map(
          (a: TagsTrackTags) => new CheckedTrackEntity(a.trackTags),
        );
      default:
        return page.bank.map(
          (a: ITrackEntityAttributes) => new TrackEntity(a),
        );
    }
  };

  return useObserver(() => (
    <div className="playlist-tab">
      <div className="search">
        <div className="search-filter-container tab">
          <TextField
            model={searchStore}
            modelProperty="term"
            className="search-term"
            placeholder="Search Tracks"
          />
            <Button
              className={`search-filter${
                filterCountStore.showTabCount[collectionId] > 0
                  ? ' has-filter-count'
                  : ''
              }`}
              data-testid="filter-button-tab"
              display={Display.Solid}
              sizes={Sizes.Medium}
              colors={Colors.Grey}
              icon={{ icon: 'filter', iconPos: 'icon-left' }}
              onClick={() => {
                  store.modal.show(
                    'Search Filter',
                    <FilterModal
                      applyFilter={applyFilter}
                      filterStore={trackFilterStore[collectionId]}
                      defaultGenre={defaultPrimaryGenre}
                      tab
                      showCount={showCount}
                      filterCountStore={filterCountStore}
                      id={collectionId}
                    />,
                    { className: 'slideout-panel-right' },
                  );
              }}
            >
              {filterCountStore?.showTabCount[collectionId] > 0 && (
                  <div className="filter-count">
                    {filterCountStore.showTabCount[collectionId]}
                  </div>
                )}
            </Button>
        </div>
        {entityType === 'playlists'
        || entityType === 'albums' ? (
          <div className="playlist-summary">
            <p>{tracksData?.pages[0].summary ?? 'Summary Unavailable'}</p>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="results">
        <Droppable
          droppableId={`source-${collectionId}`}
          isDropDisabled
          // mode="virtual"
          renderClone={provided => (
            <div
              className="track-wrapper-clone"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div className="select-count">
                {multiSelectStore.numSelected}
              </div>

              <img
                src={AlbumArtPlaceholder}
                alt="album-art"
                className="album-art"
              />
            </div>
          )}
        >
          {provided => (
            <div
            className="result-tracks"
            ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {tracksData?.pages.map((page, index) => parseData(page).map((track: TrackEntity, pagedIndex: number) => (
                <Draggable
                  draggableId={`tab-${track.id}`}
                  index={(index * PAGE_SIZE) + pagedIndex}
                  key={`track-${track.id}`}
                  isDragDisabled={!multiSelectStore.selected.includes(track.id)}
                >
                  {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <div
                      className="track-wrapper"
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                        customTrackClickEvent(track, e);

                          e.preventDefault();
                          const isStart = e.ctrlKey || !e.shiftKey;
                          multiSelect(
                            e,
                            (index * PAGE_SIZE) + pagedIndex,
                            isStart,
                            track.id,
                            trackIds.data ?? [],
                            collectionId,
                          );
                      }}
                      onKeyDown={(e: React.KeyboardEvent) => onKeyDown(e, snapshot)}
                      style={{ margin: '0 5px' }}
                    >
                      <div
                        className={`result-item track art ${
                          multiSelectStore.selected.includes(track.id) ? 'selected' : ''
                        }`}
                      >
                        <TrackTile track={new TrackEntity(track)} />
                      </div>
                    </div>
                  )}
                </Draggable>
              )))}

              <button
                className="infiniteListEndButton"
                ref={loadMoreButtonRef}
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage || isFetchingNextPage}
              >
                {isLoading || isFetchingNextPage || hasNextPage ? 'Loading...' : 'Nothing more to load'}
              </button>
            </div>
          )}
        </Droppable>
      </div>
    </div>
  ));
});

export default PlaylistTab;
