/* eslint-disable jsx-a11y/label-has-associated-control */
import { observer, useLocalStore } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { action, runInAction } from 'mobx';
import { store } from '../../../../Models/Store';
import { TextField } from '../../../Components/TextBox/TextBox';
import {
  Button,
  Colors,
  Display,
  Sizes,
} from '../../../Components/Button/Button';
import { MultiCombobox } from '../../../Components/Combobox/MultiCombobox';
import { GenreEntity } from 'Models/Entities';
import Axios from 'axios';
import { SERVER_URL } from 'Constants';
import { DefaultTrackFilterStore } from 'Util/PlaylistUtils';

export interface FilterFormContainerDict {
  [key: string]: FilterForm;
}

export interface FilterForm {
  primaryGenres: string[];
  genres: string[];

  bpm: {
    min?: number;
    max?: number;
  };
  year: {
    start?: number;
    end?: number;
  };
  moodFeel: string[];
  instruments: string[];
  lyricThemes: string[];
  vocals: string[];
  type: string[];
}

const MOODFEEL = [
  'Anthemic',
  'Atmospheric',
  'Bright',
  'Building',
  'Catchy',
  'Cinematic',
  'Confident',
  'Cool',
  'Dark',
  'Dramatic',
  'Dreamy',
  'Driving',
  'Emotive',
  'Energetic',
  'Epic',
  'Gritty',
  'Happy',
  'Hopeful',
  'Intense',
  'Light',
  'Minimal',
  'Moody',
  'Mysterious',
  'Party',
  'Percussive',
  'Playful',
  'Positive',
  'Powerful',
  'Quirky',
  'Reflective',
  'Retro',
  'Rhythmic',
  'Romantic',
  'Sad',
  'Sexy',
  'Swagger',
  'Tension',
  'Upbeat',
  'Uplifting',
];

const INSTRUMENTS = [
  'Acoustic guitar',
  'Bass',
  'Brass',
  'Drums',
  'Electric guitar',
  'Handclaps',
  'Horns',
  'Orchestral',
  'Organ',
  'Percussion',
  'Piano',
  'Strings',
  'Synth',
  'Ukulele',
];

const LYRICTHEMES = [
  'Adventure',
  'Beautiful',
  'Believe',
  'Better',
  'Brand new day',
  'Breakup',
  'Can do',
  'Carry on',
  'Change',
  'Children',
  'Christmas',
  'Death',
  'Desire',
  'Destiny',
  'Discovery',
  'Dream',
  'Eating',
  'Empowerment',
  'Fame',
  'Family',
  'Fashion',
  'Feeling free',
  'Food',
  'Freedom',
  'Friendship',
  'Good times',
  'Happiness',
  'Heartbreak',
  'Holiday',
  'Home',
  'Hope',
  'Individuality',
  'Life',
  'Love',
  'Magic',
  'Nature',
  'New beginning',
  'Party',
  'Power',
  'Searching',
  'Seasons/weather',
  'Sports',
  'Survive',
  'Teamwork',
  'Temptation',
  'Together',
  'Winning',
  'Work',
  'Youth',
];

const TYPES = [
  'Cover',
  'Demo',
  'Easy-clear',
  'Focus track',
  'Mainstream',
  'One stop',
  'Recognizable',
  'Rerecord',
  'Samples',
  'Score',
  'Sound design',
  'Soundtrack',
  'Sting',
];

interface FilterModalProps {
  applyFilter: (filter: any) => void;
  filterStore: FilterForm;
  defaultGenre?: string;
  showCount?: (shouldShowCount: number, tab: boolean, id: string) => void;
  filterCountStore?: {
    showCount: number;
    showTabCount: { [key: string]: number };
  };
  tab: boolean;
  id: string;
}

const FilterModal = observer((props: FilterModalProps) => {
  const {
 applyFilter, filterStore, defaultGenre, showCount, tab, id,
} = props;

  const errors = useLocalStore(() => ({
    bpmMin: '',
    bpmMax: '',
    yearStart: '',
    yearEnd: '',
  }));

  const [primaryGenres, setPrimaryGenres] = useState<GenreEntity[]>([]);
  useEffect(() => {
    // Fetch the primary genre list
    const fetchPrimaryGenres = async () => Axios.get(`${SERVER_URL}/api/entity/GenreEntity`);
    fetchPrimaryGenres()
      .then(genreResponse => {
        setPrimaryGenres(genreResponse.data.data);
      })
      .catch(() => {
        // We show an empty list if failed to load genres
      });
  }, []);

  const isNumber = (val: string) => !Number.isNaN(parseInt(val, 10));
  const isNullOrEmpty = (val: string) => val === null || val === undefined || val === '' || val === 'undefined';

  const validateFilter = action(() => {
    if (!isNullOrEmpty(`${filterStore.bpm.min}`)) {
      if (!isNumber(`${filterStore.bpm.min}`)) {
        errors.bpmMin = 'Must be a number';
      } else {
        errors.bpmMin = '';
      }
    } else {
      errors.bpmMin = '';
    }

    if (!isNullOrEmpty(`${filterStore.bpm.max}`)) {
      if (!isNumber(`${filterStore.bpm.max}`)) {
        errors.bpmMax = 'Must be a number';
      } else {
        errors.bpmMax = '';
      }
    } else {
      errors.bpmMax = '';
    }

    if (filterStore.bpm.min && !filterStore.bpm.max) {
      errors.bpmMax = 'If bpm min is provided, bpm max must also be provided';
    }

    if (filterStore.bpm.max && !filterStore.bpm.min) {
      errors.bpmMin = 'If bpm max is provided, bpm min must also be provided';
    }

    if (!isNullOrEmpty(`${filterStore.year.start}`)) {
      if (!isNumber(`${filterStore.year.start}`)) {
        errors.yearStart = 'Must be a number';
      } else {
        errors.yearStart = '';
      }
    } else {
      errors.yearStart = '';
    }

    if (!isNullOrEmpty(`${filterStore.year.end}`)) {
      if (!isNumber(`${filterStore.year.end}`)) {
        errors.yearEnd = 'Must be a number';
      } else {
        errors.yearEnd = '';
      }
    } else {
      errors.yearEnd = '';
    }

    if (filterStore.year.start && !filterStore.year.end) {
      errors.yearEnd = 'If year start is provided, year end must also be provided';
    }

    if (filterStore.year.end && !filterStore.year.start) {
      errors.yearStart = 'If year end is provided, year start must also be provided';
    }
  });

  const getFilterCount = () => {
    let count = 0;

    if (filterStore.primaryGenres.length > 0) {
      count += 1;
    }
    if (filterStore.vocals.length > 0) {
      count += 1;
    }
    if (filterStore.instruments.length > 0) {
      count += 1;
    }
    if (filterStore.year.start && filterStore.year.end) {
      count += 1;
    }
    if (filterStore.bpm.min && filterStore.bpm.max) {
      count += 1;
    }
    if (filterStore.lyricThemes.length > 0) {
      count += 1;
    }
    if (filterStore.moodFeel.length > 0) {
      count += 1;
    }
    if (filterStore.type.length > 0) {
      count += 1;
    }

    return count;
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    let invalid = false;
    validateFilter();
    Object.keys(errors).forEach(key => {
      if ((errors as any)[key] !== '') invalid = true;
    });

    if (invalid) return;

    if (showCount) {
      showCount(getFilterCount(), tab, id);
    }

    applyFilter(filterStore);
    store.modal.hide();
  };

  useEffect(() => {
    if (
      !!defaultGenre && !filterStore?.primaryGenres.includes(defaultGenre)
    ) {
      runInAction(() => {
        filterStore.primaryGenres = [...filterStore.primaryGenres, defaultGenre];
      });
    }
  }, [filterStore, filterStore?.primaryGenres, defaultGenre]);

  const VocalButton = (vocalType: string) => {
    function toTitleCase(str: string) {
      return str
        .toLowerCase()
        .split(' ')
        .map((word: string) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
    }

    return (
      <Button
        className="vocal-option"
        sizes={Sizes.Small}
        display={
          filterStore.vocals.includes(vocalType)
            ? Display.Outline
            : Display.Solid
        }
        colors={
          filterStore.vocals.includes(vocalType) ? Colors.Primary : Colors.Grey
        }
        onClick={action(() => {
          if (filterStore.vocals.includes(vocalType)) {
            filterStore.vocals = filterStore.vocals.filter(
              vocal => vocal !== vocalType,
            );
          } else {
            filterStore.vocals = [...filterStore.vocals, vocalType];
          }
        })}
      >
        {toTitleCase(vocalType)}
      </Button>
    );
  };

  return (
    <div className="filter-form">
      <h4>Filter Search</h4>
      <form onSubmit={onSubmit}>
        <MultiCombobox
          model={filterStore}
          modelProperty="primaryGenres"
          label="Primary Genre"
          options={primaryGenres.map(g => ({
            display: g.name,
            value: g.name,
          }))}
          onAfterChange={() => {
            if (!!defaultGenre && defaultGenre !== '') {
              if (!filterStore.primaryGenres.includes(defaultGenre)) {
                runInAction(() => {
                  filterStore.primaryGenres = [
                    ...filterStore.primaryGenres,
                    defaultGenre,
                  ];
                });
              }
            }
          }}
        />
        <div className="double-input">
          <TextField
            model={filterStore.bpm}
            modelProperty="min"
            label="Min BPM"
            placeholder="100"
            errors={errors.bpmMin}
          />
          <TextField
            model={filterStore.bpm}
            modelProperty="max"
            label="Max BPM"
            placeholder="160"
            errors={errors.bpmMax}
          />
        </div>
        <div className="double-input">
          <TextField
            model={filterStore.year}
            modelProperty="start"
            label="Year Range Start"
            placeholder="1956"
            errors={errors.yearStart}
          />
          <TextField
            model={filterStore.year}
            modelProperty="end"
            label="Year Range End"
            placeholder="1987"
            errors={errors.yearEnd}
          />
        </div>
        <MultiCombobox
          model={filterStore}
          modelProperty="moodFeel"
          label="Mood/Feel"
          options={MOODFEEL.map(moodFeel => ({
            display: moodFeel,
            value: moodFeel,
          }))}
        />
        <MultiCombobox
          model={filterStore}
          modelProperty="instruments"
          label="Instruments"
          options={INSTRUMENTS.map(instrument => ({
            display: instrument,
            value: instrument,
          }))}
        />
        <MultiCombobox
          model={filterStore}
          modelProperty="lyricThemes"
          label="Lyric Themes"
          options={LYRICTHEMES.map(lyricTheme => ({
            display: lyricTheme,
            value: lyricTheme,
          }))}
        />
        <label htmlFor="vocal-options" className="vocal-options-label">
          Vocals
        </label>
        <div className="vocal-options" id="vocal-options">
          {VocalButton('Female vocal')}
          {VocalButton('Male vocal')}
          {VocalButton('Instrumental')}
        </div>
        <MultiCombobox
          model={filterStore}
          modelProperty="type"
          label="Type"
          options={TYPES.map(type => ({ display: type, value: type }))}
        />

        {/*	Form Controls */}
        <div className="form-controls">
          <Button
            className="cancel"
            display={Display.Outline}
            colors={Colors.White}
            onClick={() => store.modal.hide()}
          >
            Close
          </Button>
          <Button
            className="cancel"
            display={Display.Solid}
            colors={Colors.White}
            onClick={() => runInAction(() => {
                Object.keys(filterStore).forEach(key => {
                  (filterStore as any)[key] = (DefaultTrackFilterStore as any)[key];
                });

                if (showCount) {
                  showCount(getFilterCount(), tab, id);
                }

                // Close modal after clearing filter
                store.modal.hide();
              })}
          >
            Clear Filter
          </Button>
          <Button
            type="submit"
            className="submit"
            display={Display.Solid}
            colors={Colors.Primary}
          >
            Apply Filter
          </Button>
        </div>
      </form>
    </div>
  );
});

export default FilterModal;
