/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AgencyPlaylisterBankEntity from 'Models/Security/Acl/AgencyPlaylisterBankEntity';
import ApplicationUserBankEntity from 'Models/Security/Acl/ApplicationUserBankEntity';
import MustardAdminBankEntity from 'Models/Security/Acl/MustardAdminBankEntity';
import MustardManagementBankEntity from 'Models/Security/Acl/MustardManagementBankEntity';
import AgencyAdminBankEntity from 'Models/Security/Acl/AgencyAdminBankEntity';
import VisitorsBankEntity from 'Models/Security/Acl/VisitorsBankEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IBankEntityAttributes extends IModelAttributes {
	name: string;
	ownerId: string;

	bankTrackss: Array<
		| Models.BanksBankTracks
		| Models.IBanksBankTracksAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] on begin
	ownerName: string;
	totalTrackCount: number;
	hasErrors: boolean;
	isProcessing: boolean;
	requiresSorting: boolean;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('BankEntity', 'Bank')
// % protected region % [Customise your entity metadata here] end
export default class BankEntity extends Model
	implements IBankEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AgencyPlaylisterBankEntity(),
		new ApplicationUserBankEntity(),
		new MustardAdminBankEntity(),
		new MustardManagementBankEntity(),
		new AgencyAdminBankEntity(),
		new VisitorsBankEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@observable
	@attribute<BankEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Owner Id'] off begin
	@Validators.Uuid()
	@observable
	@attribute<BankEntity, string>()
	@CRUD({
		name: 'Owner Id',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public ownerId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Owner Id'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Bank Tracks'] off begin
		name: 'Bank Tracks',
		displayType: 'reference-multicombobox',
		order: 30,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.BanksBankTracks,
		optionEqualFunc: makeJoinEqualsFunc('bankTracksId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'bankEntity',
			oppositeEntityName: 'trackEntity',
			relationName: 'banks',
			relationOppositeName: 'bankTracks',
			entity: () => Models.BankEntity,
			joinEntity: () => Models.BanksBankTracks,
			oppositeEntity: () => Models.TrackEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Bank Tracks'] end
	})
	public bankTrackss: Models.BanksBankTracks[] = [];

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Owner Name',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public ownerName: string;


	// TODO: Do we want to have this displayed in CRUD?
	@observable
	@attribute()
	@CRUD({
		name: 'Total Track Count',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: false,
		isReadonly: true
	})
	public totalTrackCount: number;

	@observable
	@attribute()
	@CRUD({
		name: 'Upload Errors',
		displayType: 'checkbox',
		order: 60,
		headerColumn: true,
		searchable: false,
		isReadonly: true
	})
	public hasErrors: boolean;

	@observable
	@attribute()
	@CRUD({
		name: 'Is Processing',
		displayType: 'checkbox',
		order: 60,
		headerColumn: true,
		searchable: false,
		isReadonly: true
	})
	public isProcessing: boolean;

	@observable
	@attribute()
	@CRUD({
		name: 'Requires Sorting',
		displayType: 'checkbox',
		order: 60,
		headerColumn: true,
		searchable: false,
		isReadonly: true
	})
	public requiresSorting: boolean;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IBankEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IBankEntityAttributes>) {
		// % protected region % [Override assign attributes here] on begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.ownerId !== undefined) {
				this.ownerId = attributes.ownerId;
			}
			if (attributes.ownerName !== undefined) {
				this.ownerName = attributes.ownerName;
			}
			if (attributes.hasErrors !== undefined) {
				this.hasErrors = attributes.hasErrors;
			}
			if (attributes.isProcessing !== undefined) {
				this.isProcessing = attributes.isProcessing;
			}
			if (attributes.totalTrackCount !== undefined) {
				this.totalTrackCount = attributes.totalTrackCount;
			}
			if (attributes.requiresSorting !== undefined) {
				this.requiresSorting = attributes.requiresSorting;
			}
			if (attributes.bankTrackss !== undefined && Array.isArray(attributes.bankTrackss)) {
				for (const model of attributes.bankTrackss) {
					if (model instanceof Models.BanksBankTracks) {
						this.bankTrackss.push(model);
					} else {
						this.bankTrackss.push(new Models.BanksBankTracks(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		bankTrackss {
			${Models.BanksBankTracks.getAttributes().join('\n')}
			bankTracks {
				${Models.TrackEntity.getAttributes().join('\n')}
				${Models.TrackEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			bankTrackss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'bankTrackss',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(BankEntity.prototype, 'created');
CRUD(modifiedAttr)(BankEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
