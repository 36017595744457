/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @ts-nocheck
import React, { RefObject, useRef } from 'react';
import { observer } from 'mobx-react';
import { useInfiniteQuery } from 'react-query';
import Axios from 'axios';
import { runInAction } from 'mobx';
import { TextField } from '../../../Components/TextBox/TextBox';
import { SERVER_URL } from '../../../../Constants';
import useIntersectionObserver from '../../../../Util/useIntersectionObserver';
import InfiniteList from './InfiniteList';
import {
  CollectionType,
  MultiSelectStore,
  SearchResultObject,
} from '../../../../Util/PlaylistUtils';
import FilterModal, { FilterForm } from './FilterModal';
import {
  Button,
  Colors,
  Display,
  Sizes,
} from '../../../Components/Button/Button';
import { store } from '../../../../Models/Store';

interface IInfiniteSearchPanelProps {
  openTab: (tab: CustomTabObject) => void;
  searchStore: {
    term: string;
  };
  updatePanel: (show: string) => void;
  panelEntity: CollectionType;
  isExpanded: boolean;
  multiSelect: (
    event: React.MouseEvent<HTMLInputElement>,
    index: number,
    start: boolean,
    track: string,
    trackList: string[],
    collectionId: string
  ) => void;
  multiSelectStore: MultiSelectStore;
  clearMultiSelect: () => void;
  applyFilter: (filter: FilterForm) => void;
  filterStore: {
    filters: FilterForm;
    shouldUpdate: number; // not being used
  };
  showCount?: (shouldShowCount: number, tab: boolean, id: string) => void;
  filterCountStore?: {
    showCount: number;
    showTabCount: { [key: string]: number };
  };
  getFilterCount: (filterStore: FilterForm) => number;
}

export const InfiniteSearchPanel = observer(
  (props: IInfiniteSearchPanelProps) => {
    const {
      openTab,
      searchStore,
      updatePanel,
      panelEntity,
      isExpanded,
      multiSelect,
      multiSelectStore,
      clearMultiSelect,
      filterStore,
      applyFilter,
      showCount,
      filterCountStore,
    } = props;

    const {
      data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading,
    } = useInfiniteQuery(
      ['searchPlaylist', encodeURIComponent(searchStore.term), panelEntity, JSON.stringify(filterStore.filters)],
      async ({ pageParam = 0 }):Promise<SearchResultObject> => {
        try {
        const {
        bpm, year, primaryGenres, genres, moodFeel, instruments, lyricThemes, vocals, type,
        } = filterStore.filters;

        const queryString = `${SERVER_URL}/api/playlist_search`
        + `?PageNo=${pageParam}`
        + `&PageSize=${20}`
        + `&searchTerm=${encodeURIComponent(searchStore.term)}`
        + `&collect=${panelEntity}`
        + `&bpmMin=${bpm.min ?? ''}`
        + `&bpmMax=${bpm.max ?? ''}`
        + `&yearStart=${year.start ?? ''}`
        + `&yearEnd=${year.end ?? ''}`
        + `&primaryGenres=${JSON.stringify(primaryGenres)}`
        + `&genres=${JSON.stringify(genres)}`
        + `&moodFeel=${JSON.stringify(moodFeel)}`
        + `&instruments=${JSON.stringify(instruments)}`
        + `&lyricThemes=${JSON.stringify(lyricThemes)}`
        + `&vocals=${JSON.stringify(vocals)}`
        + `&type=${JSON.stringify(type)}`;

          const res = await Axios.get(queryString);
          return res.data;
        } catch (err) {
          console.error('nope');
        }
      },
      {
        getNextPageParam: (lastPage: any) => lastPage.nextPageNo === -1 ? undefined : lastPage.nextPageNo,
      },
    );

    // triggers fetching more content when reached
    const loadMoreButtonRef = useRef<HTMLButtonElement>() as RefObject<HTMLButtonElement>;

    // tracks the position of the scroll container to see if the loadMoreButtonRef has been reached
    useIntersectionObserver({
      root: null,
      target: loadMoreButtonRef,
      onIntersect: fetchNextPage,
      enabled: hasNextPage,
    });

    return (
      <div className={`search-panel-container${isExpanded ? ' expanded' : ''}`}>
        <div className="search">
          <h4>Browse Music</h4>
          <div className="search-filter-container">
            <TextField
              model={searchStore}
              modelProperty="term"
              className="search-term search-panel"
              placeholder="Search"
              isDisabled
            />
            <Button
              className={`search-filter${
                filterCountStore && filterCountStore.showCount > 0
                  ? ' has-filter-count'
                  : ''
              }`}
              data-testid="filter-button-infinite-search-panel"
              display={Display.Solid}
              sizes={Sizes.Medium}
              colors={Colors.Grey}
              icon={{ icon: 'filter', iconPos: 'icon-left' }}
              onClick={() => {
                runInAction(() => updatePanel('tracks'));
                store.modal.show(
                  'Search Filter',
                  <FilterModal
                    applyFilter={applyFilter}
                    filterStore={filterStore.filters}
                    filterCountStore={filterCountStore}
                    showCount={showCount}
                    tab={false}
                    id={'search'}
                  />,
                  { className: 'slideout-panel-right' },
                );
              }}
            >
              {filterCountStore && filterCountStore.showCount > 0 && (
                <div className="filter-count">{filterCountStore.showCount}</div>
              )}
            </Button>
          </div>
        </div>
        <InfiniteList
          data={data}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          loadMoreButtonRef={loadMoreButtonRef}
          updatePanel={updatePanel}
          searchStore={searchStore}
          isLoading={isLoading}
          openTab={openTab}
          panelEntity={panelEntity}
          multiSelect={multiSelect}
          multiSelectStore={multiSelectStore}
          clearMultiSelect={clearMultiSelect}
        />
      </div>
    );
  },
);

export default InfiniteSearchPanel;
